import styled from "@emotion/styled";

export const CheckboxGroup = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	> * {
		flex: 0 0 150px;
		display: inline-flex;
	}
`;

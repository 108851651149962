import styled from "@emotion/styled";
import EnrichmentTheme from "src/theme";

export const Form = styled.div<{theme: typeof EnrichmentTheme}>`
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
	height: 100%;
`;

export const SubHeader = styled.div<{theme: typeof EnrichmentTheme}>`
	margin: ${({theme}) => theme.spacing.xs} ${({theme}) => theme.spacing.xxs};
	font-size: ${({theme}) => theme.typography.size.sm};
`;

export const Footer = styled.div<{theme: typeof EnrichmentTheme}>`
	margin: ${({theme}) => theme.spacing.xxl} ${({theme}) => theme.spacing.xxs} 0
		${({theme}) => theme.spacing.xxs};
`;

export const AutoSizeRow = styled.div`
	flex: 1 1 auto;
	overflow: auto;
	width: 100%;
`;

export const ImportPreview = styled(AutoSizeRow)<{
	theme: typeof EnrichmentTheme;
}>`
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
`;

export const Row = styled.div<{theme: typeof EnrichmentTheme}>`
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	& > * {
		flex: 0 0 auto;
	}
	& > *:not(:last-child) {
		margin-right: ${({theme}) => theme.spacing.xxs};
	}
`;

export const Nav = styled(Row)<{theme: typeof EnrichmentTheme}>`
	width: 100%;
	border-bottom: 2px solid ${({theme}) => theme.colors.primary.dark};
	padding: ${({theme}) => theme.spacing.xxs} 0;
	margin-bottom: ${({theme}) => theme.spacing.xs};
`;

export const Pagination = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	& > select {
		height: 28px;
		flex: 1 1 auto;
		margin-left: 6px;
		margin-right: 6px;
	}
`;

export const Help = styled.div<{theme: typeof EnrichmentTheme}>`
	text-align: right;
	font-size: ${({theme}) => theme.typography.size.xs};
	margin: ${({theme}) => theme.spacing.xxs} 0;
`;

export const LinksTable = styled.table<{theme: typeof EnrichmentTheme}>`
	border-collapse: collapse;
	width: 100%;
	table-layout: auto;
	th {
		background-color: ${({theme}) => theme.colors.primary.lighter};
		font-size: ${({theme}) => theme.typography.size.sm};
		padding: ${({theme}) => theme.typography.size.xxxs};
	}
	td {
		font-size: ${({theme}) => theme.typography.size.xs};
		padding: ${({theme}) => theme.typography.size.xxxs};
		white-space: nowrap;
	}
	th,
	td {
		border: 1px solid ${({theme}) => theme.colors.primary.medium};
	}

	tr.disabled {
		opacity: 0.6;
	}
`;

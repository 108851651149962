import {useState, useEffect} from "react";
import {TopNavigationSchemaIdConstants} from "src/components/TopNavigation/constants";

const {
	PAGE_WINDOW,
	NAVIGATOR,
	IMPORT_EXPORT_LINKS,
	AUTO_LINKS,
	TOOLTIP_MANAGER,
} = TopNavigationSchemaIdConstants;

const useWindowMenu = () => {
	const [activeEditor, setActiveEditor] = useState<string | undefined>(
		undefined
	);
	const [visibleAreas, setVisibleAreas] = useState<{
		[key: string]: boolean;
	}>({
		[PAGE_WINDOW]: true,
		[NAVIGATOR]: true,
	});
	const [windowKeepHighlight, setKeepHighlight] = useState<Array<string>>([]);

	const switchVisibleAreas = (area: string): void => {
		setVisibleAreas({
			...visibleAreas,
			[area]: !visibleAreas[area],
		});
	};
	const switchActionArea = (): void => {
		switchVisibleAreas(PAGE_WINDOW);
	};
	const switchNavigator = (): void => {
		switchVisibleAreas(NAVIGATOR);
	};

	const switchActiveEditor = (editor: string): void => {
		setActiveEditor(editor === activeEditor ? undefined : editor);
	};
	const switchImportExportLinks = (): void => {
		switchActiveEditor(IMPORT_EXPORT_LINKS);
	};
	const switchAutoLinks = (): void => {
		switchActiveEditor(AUTO_LINKS);
	};
	const switchTooltipManager = (): void => {
		switchActiveEditor(TOOLTIP_MANAGER);
	};

	useEffect(() => {
		const keepHighlight = [];
		if (activeEditor) {
			keepHighlight.push(activeEditor);
		}
		Object.entries(visibleAreas).forEach(([visibleArea, isVisible]) => {
			if (isVisible) {
				keepHighlight.push(visibleArea);
			}
		});
		setKeepHighlight(keepHighlight);
	}, [activeEditor, visibleAreas]);

	return {
		visibleAreas,
		switchActionArea,
		switchNavigator,
		activeEditor,
		setActiveEditor,
		switchImportExportLinks,
		switchAutoLinks,
		switchTooltipManager,
		windowKeepHighlight,
	};
};

export {useWindowMenu};

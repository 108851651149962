import styled from "@emotion/styled";

export const Footer = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
	> * {
		margin-left: 10px;
	}
`;

export const Form = styled.div`
	width: 100%;
	height: 200px;
	> * {
		margin-bottom: 10px;
	}
`;

export const Select = styled.select`
	width: 100%;
	height: 28px;
	box-sizing: border-box;
`;

export const Input = styled.input`
	width: 100%;
	height: 28px;
	box-sizing: border-box;
`;

export const Label = styled.div`
	font-weight: bold;
`;

import React from "react";
import {Formik} from "formik";

import withAutoCommit from "src/utilities/form/withAutoCommit";
import {Form, FormOwnProps, FormValues} from "./Form";

import {object as YupObject, number as YupNumber} from "yup";

const AutoCommitForm = withAutoCommit<FormOwnProps, FormValues>(Form);

const LinkPropertiesFormSchema = YupObject<FormValues>().shape({
	width: YupNumber().min(0),
	height: YupNumber().min(0),
	x: YupNumber().min(0),
	y: YupNumber().min(0),
});

export interface LinkPropertiesFormProps extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: FormValues;
	/** Form submission handler */
	onSubmit: (values: FormValues) => void;
}

export const LinkPropertiesForm: React.FC<LinkPropertiesFormProps> = (
	props: LinkPropertiesFormProps
) => {
	const {initialValues, onSubmit} = props;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={LinkPropertiesFormSchema}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <AutoCommitForm {...props} {...formProps} />}
		</Formik>
	);
};

export default LinkPropertiesForm;

import styled from "@emotion/styled";
import EnrichmentTheme from "../../theme";

export const FieldWrapper = styled.div<{theme: typeof EnrichmentTheme}>`
	padding: ${({theme}) => theme.spacing.xxxs};
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	flex: 1 1 auto;
`;

export const Field = styled.div<{theme: typeof EnrichmentTheme}>`
	flex: 1 1 auto;
	&&& > *,
	input[type="text"] {
		width: 100%;
		box-sizing: border-box;
	}
	&&& > input[type="checkbox"] {
		width: auto;
		padding: 0;
		margin: 0;
		vertical-align: middle;
	}

	label > span {
		font-size: ${({theme}) => theme.typography.size.sm};
	}
`;
export const FieldActions = styled.div<{theme: typeof EnrichmentTheme}>`
	margin-left: ${({theme}) => theme.spacing.xxs};
`;

export const Group = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	&&& > * {
		flex: 1 1 auto;
	}
`;

export const GroupWithHeader = styled.div<{theme: typeof EnrichmentTheme}>`
	width: 100%;
	border-bottom: 1px solid ${({theme}) => theme.colors.secondary.lighter};
	margin-top: ${({theme}) => theme.spacing.xxs};
	padding-bottom: ${({theme}) => theme.spacing.xxs};
`;

export const Label = styled.label<{theme: typeof EnrichmentTheme}>`
	flex: 0 0 124px;
	width: 124px;
	text-align: right;
	margin-right: ${({theme}) => theme.spacing.xxs};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: ${({theme}) => theme.typography.size.sm};
`;

export const LabelH1 = styled.label<{theme: typeof EnrichmentTheme}>`
	text-align: right;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: ${({theme}) => theme.typography.size.md};
	line-height: ${({theme}) => theme.typography.size.xxl};
	font-weight: bold;
`;

export const Footer = styled.div<{theme: typeof EnrichmentTheme}>`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	padding-top: ${({theme}) => theme.spacing.sm};
`;

export const Loading = styled.div<{theme: typeof EnrichmentTheme}>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: ${({theme}) => theme.colors.primary.lighter};
	opacity: 0.9;
	z-index: 10;
`;

import {useState} from "react";
const useApplicationState = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingStatus, setLoadingStatus] = useState<string | undefined>(
		"Loading core"
	);
	const [error, setError] = useState<string | undefined>(undefined);
	return {
		isLoading,
		loadingStatus,
		error,
		setError,
		setIsLoading,
		setLoadingStatus,
	};
};
export {useApplicationState};

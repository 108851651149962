import React, {useState, useEffect} from "react";

// Components
import {LinkPropertiesForm} from "src/components/linkPropertiesForm/LinkPropertiesForm";

// Utils
import {SelectOption, getSelectorForCollectionByKey} from "src/utilities/form";

// Models
import {LinkTooltip} from "src/models/LinkTooltip";
import {Media, MediaFile} from "src/models/Medias";
import {PublicationSetting} from "src/models/PublicationSettings";
import {BaseLink} from "src/models/Links";
import {EditorSettings, EditorSetting} from "src/models/EditorSettings";

export interface LinkPropertiesProps {
	initialValues: BaseLink;
	editorSettings: EditorSettings;
	mediaMap: Media | undefined;
	tooltips: LinkTooltip[] | undefined;
	onSubmit: (values: BaseLink) => void;
}

export const LinkProperties: React.FC<LinkPropertiesProps> = (props) => {
	const {initialValues, editorSettings, mediaMap, tooltips, onSubmit} = props;
	const [tooltipSuggestedText, setTooltipSuggestedText] = useState<
		SelectOption<number>[]
	>([]);
	const [externalSource, setExternalSource] = useState<
		SelectOption<string | null>[]
	>([]);
	const [effect, setEffect] = useState<SelectOption<number>[]>([]);
	const [stretch, setStretch] = useState<SelectOption<number>[]>([]);
	const [horizontalAlignment, setHorizontalAlignment] = useState<
		SelectOption<number>[]
	>([]);
	const [verticalAlignment, setVerticalAlignment] = useState<
		SelectOption<number>[]
	>([]);

	useEffect(() => {
		setEffect(
			getSelectorForCollectionByKey<PublicationSetting<number>, number>({
				collection: editorSettings.effect,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
		setHorizontalAlignment(
			getSelectorForCollectionByKey<PublicationSetting<number>, number>({
				collection: editorSettings.horizontalAlignment,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
		setVerticalAlignment(
			getSelectorForCollectionByKey<PublicationSetting<number>, number>({
				collection: editorSettings.verticalAlignment,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
		setStretch(
			getSelectorForCollectionByKey<EditorSetting<number>, number>({
				collection: editorSettings.stretch,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
	}, [editorSettings]);

	useEffect(() => {
		if (!tooltips) {
			return;
		}
		setTooltipSuggestedText(
			getSelectorForCollectionByKey<LinkTooltip, number>({
				collection: tooltips,
				valueKey: "id",
				labelKey: "tooltip",
				noneValue: 0,
			})
		);
	}, [tooltips]);

	useEffect(() => {
		if (!mediaMap) {
			return;
		}
		setExternalSource(
			getSelectorForCollectionByKey<MediaFile, string | null>({
				collection: mediaMap.images,
				valueKey: "path",
				labelKey: "name",
				noneValue: null,
			})
		);
	}, [mediaMap]);

	return (
		<LinkPropertiesForm
			initialValues={initialValues}
			options={{
				tooltipSuggestedText,
				effect,
				externalSource,
				stretch,
				horizontalAlignment,
				verticalAlignment,
			}}
			onSubmit={onSubmit}
		/>
	);
};

export default LinkProperties;

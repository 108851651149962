import styled from "@emotion/styled";
import EnrichmentTheme from "../../theme";

export const Container = styled.div`
	.hidden {
		visibility: hidden;
		opacity: 0;
		transition: opacity 1s, visibility 1.2s;
		z-index: 0;
	}
`;

export const Loading = styled.div<{theme: typeof EnrichmentTheme}>`
	width: 100%;
	height: 100vh;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: visible;
	opacity: 1;
	transition: opacity 1s, visibility 1.2s;
	background: ${({theme}) => theme.colors.secondary.darker};
	z-index: 1;
`;

export const BrandingContainer = styled.div<{theme: typeof EnrichmentTheme}>`
	border: 1px solid ${({theme}) => theme.colors.secondary.lighter};
	font-weight: bold;
	padding: ${({theme}) => theme.spacing.md};
	border-radius: ${({theme}) => theme.spacing.xxs};
	background: ${({theme}) => theme.colors.secondary.lighter};
`;

export const Brand = styled.div<{theme: typeof EnrichmentTheme}>`
	font-size: ${({theme}) => theme.typography.size.lg};
	font-weight: bold;
	margin-bottom: ${({theme}) => theme.spacing.lg};
`;

export const Copyright = styled.div<{theme: typeof EnrichmentTheme}>`
	font-size: ${({theme}) => theme.typography.size.sm};
	color: ${({theme}) => theme.colors.secondary.medium};
	margin-bottom: ${({theme}) => theme.spacing.xs};
`;

export const Status = styled.div<{theme: typeof EnrichmentTheme}>`
	font-size: ${({theme}) => theme.typography.size.xs};
	color: ${({theme}) => theme.colors.secondary.medium};
`;

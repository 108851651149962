import React, {useState, ChangeEvent, useEffect} from "react";

// Components
import {Button} from "@zmags/zmags-ui-library";
import {Form, Footer, Input, Label} from "./TooltipManager.styles";

export interface CreateTooltipFormProps {
	onSave: (tooltip: string) => void;
	onCancel: () => void;
}

export const CreateTooltipForm: React.FC<CreateTooltipFormProps> = (props) => {
	const {onSave, onCancel} = props;
	const [tooltip, setTooltip] = useState<string>("");

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const {value} = event.currentTarget;
		setTooltip(value);
	}

	async function handleSave() {
		if (tooltip) {
			onSave(tooltip);
		}
	}

	function handleCancel() {
		onCancel();
	}

	useEffect(() => {
		setTooltip("");
	}, []);

	return (
		<div>
			<Form>
				<Label>Create new tooltip</Label>
				<Input
					autoFocus={true}
					type="text"
					value={tooltip}
					onChange={handleChange}
				/>
			</Form>
			<Footer>
				<Button label="Cancel" size="small" onClick={handleCancel} />
				<Button
					label="Save"
					size="small"
					disabled={!tooltip}
					onClick={handleSave}
				/>
			</Footer>
		</div>
	);
};

export default CreateTooltipForm;

export interface SelectOption<V> {
	value: V;
	label: string;
}

export const getSelectorForCollection = <T, V>({
	collection,
	getValue,
	getLabel,
	noneValue,
}: {
	/** The collection of initial data */
	collection: T[];
	/** Function to get value for the selector from an item of the collection */
	getValue: ({item, idx}: {item: T; idx: number}) => V;
	/** Function to get label for the selector from an item of the collection */
	getLabel: ({item, idx}: {item: T; idx: number}) => string;
	/** The value for the selector with option 'None' */
	noneValue?: V;
}): SelectOption<V>[] => {
	const options: SelectOption<V>[] = collection.map((item: T, idx: number) => ({
		value: getValue({item, idx}),
		label: getLabel({item, idx}),
	}));
	if (noneValue !== undefined) {
		options.unshift({
			value: noneValue,
			label: "None",
		});
	}
	return options;
};

export const getSelectorForCollectionByKey = <T, V>({
	/** The collection of initial data */
	collection,
	/** The key of the field from the collection used as value for the selector */
	valueKey,
	/** The key of the field from the collection used as label for the selector */
	labelKey,
	/** The value for the selector with option 'None' */
	noneValue,
}: {
	collection: T[];
	valueKey: keyof T;
	labelKey: keyof T;
	noneValue?: V;
}): SelectOption<V>[] =>
	getSelectorForCollection<T, V>({
		collection,
		getValue: ({item}): any => item[valueKey],
		getLabel: ({item}): string => String(item[labelKey]),
		noneValue,
	});

export interface FormState<Values> {
	/** Form values */
	values: Values;
}

export const isEqual = <V, T extends any>(
	props: FormState<V>,
	name: keyof V,
	expected: T
): boolean => (props.values[name] as T) === expected;

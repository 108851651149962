import React, {useCallback, useEffect, useState} from "react";
import {Navigation, Icon} from "@zmags/zmags-ui-library";
import {ItemType, NavigationProps} from "./types";
import schema from "./schema";

const {SubMenu, Item} = Navigation;

export const TopNavigation: React.FunctionComponent<NavigationProps> = (
	props
) => {
	const {menuEventHandlers, keepHighlight, active} = props;
	const [activeItems, updateActiveItems] = useState<Array<string>>([]);
	const [highlightItems, updateHighlightItems] = useState<Array<string>>([]);

	/**
	 * Here we need to toggle elements under the "Window" parent item*
	 * 1) HighlightItems contains all children of "Window".
	 * 2) Toggle items under "Window"
	 * 3) For all items trigger callback function wit clicked id
	 *
	 * @param id
	 * @param event
	 */
	const handleNavigationItemClick = useCallback(
		(id, event) => {
			if (highlightItems.indexOf(id) !== -1) {
				const index = activeItems.indexOf(id);
				if (index === -1) {
					const a = activeItems.concat(id);
					updateActiveItems(a);
				} else {
					const temp = [...activeItems];
					temp.splice(index, 1);
					updateActiveItems(temp);
				}
			}
			if (menuEventHandlers && menuEventHandlers[id]) {
				const callback = menuEventHandlers[id] || undefined;
				if (callback) {
					return callback(id, event);
				}
			}
		},
		[menuEventHandlers, activeItems, highlightItems]
	);

	useEffect(() => {
		if (keepHighlight) {
			keepHighlight.forEach((parentItemId) => {
				const parentItem = schema.find(({id}) => id === parentItemId);
				if (parentItem && parentItem.items) {
					parentItem.items.forEach(({id}) => {
						highlightItems.push(id);
						updateHighlightItems(highlightItems);
					});
				}
			});
		}
	}, [keepHighlight, highlightItems]);

	useEffect(() => {
		updateActiveItems(active || []);
	}, [active]);

	// Simple recursion to generate the Menu
	const buildMenuItem = (topLevelItem: Array<ItemType>) =>
		topLevelItem.map((item: ItemType) => {
			if (item.hasOwnProperty("items")) {
				return (
					<SubMenu
						id={item.id}
						title={item.title}
						icon={<Icon name={item.icon || ""} />}
						key={item.id}
					>
						{buildMenuItem(item.items || [])}
					</SubMenu>
				);
			} else {
				return (
					<Item
						id={item.id}
						icon={<Icon name={item.icon || ""} />}
						key={item.id}
					>
						{item.title}
					</Item>
				);
			}
		});

	return (
		<Navigation
			size="small"
			float="left"
			handleClick={handleNavigationItemClick}
			activeItems={activeItems}
		>
			{buildMenuItem(schema)}
		</Navigation>
	);
};

import React, {useState} from "react";

// Components
import {ToolPanel} from "src/components/toolPanel/ToolPanel";
import {ViewTooltipForm} from "./ViewTooltipForm";
import {CreateTooltipForm} from "./CreateTooltipForm";
import {EditTooltipForm} from "./EditTooltipForm";

// Models
import {LinkTooltip} from "src/models/LinkTooltip";

export interface TooltipManagerProps {
	tooltips: LinkTooltip[] | undefined;
	editTooltip: (tooltip: LinkTooltip) => void;
	createTooltip: (tooltip: string) => void;
	deleteTooltip: (tooltip: LinkTooltip) => void;
}

export const TooltipManager: React.FC<TooltipManagerProps> = (props) => {
	const {tooltips, editTooltip, createTooltip, deleteTooltip} = props;
	const [customTooltip, setCustomTooltip] = useState<LinkTooltip | undefined>();
	const [mode, setMode] = useState<"edit" | "view" | "add">("view");

	function handleCreateNewTooltip() {
		setMode("add");
	}

	function handleEditCustomTooltip(tooltip: LinkTooltip) {
		setCustomTooltip(tooltip);
		if (tooltip) {
			setMode("edit");
		}
	}

	function handleCancel() {
		setMode("view");
	}

	async function handleDeleteTooltip(deletedTooltip: LinkTooltip) {
		await deleteTooltip(deletedTooltip);
		setMode("view");
	}

	async function handleSaveTooltip(changedTooltip: LinkTooltip) {
		await editTooltip(changedTooltip);
		setMode("view");
	}

	async function handleSaveNewTooltip(tooltip: string) {
		await createTooltip(tooltip);
		setMode("view");
	}

	if (!tooltips) {
		return null;
	}

	return (
		<ToolPanel style={{width: "220px"}} title="Manage tooltips">
			{mode === "view" && (
				<ViewTooltipForm
					tooltips={tooltips}
					onCreateNew={handleCreateNewTooltip}
					onEdit={handleEditCustomTooltip}
				/>
			)}
			{mode === "edit" && !!customTooltip && (
				<EditTooltipForm
					tooltip={customTooltip}
					onDelete={handleDeleteTooltip}
					onSave={handleSaveTooltip}
					onCancel={handleCancel}
				/>
			)}
			{mode === "add" && (
				<CreateTooltipForm
					onSave={handleSaveNewTooltip}
					onCancel={handleCancel}
				/>
			)}
		</ToolPanel>
	);
};

export default TooltipManager;

import React, {useCallback, useEffect, useState} from "react";
import {Navigation, Icon} from "@zmags/zmags-ui-library";
import {ItemType, ToolsProps} from "./types";
import schema from "./schema";
import {ColorPicker} from "@zmags/zmags-ui-library";

import {ToolsSchemaIdConstants} from "./constants";
const {COLOR_PICKER, SAVE_AND_PREVIEW, SELECTION} = ToolsSchemaIdConstants;

/** Recursive style for the search with a short circuit if found.*/
const findById = (
	array: Array<ItemType | undefined>,
	id: string
): ItemType | undefined =>
	array.reduce((a, item) => {
		if (a) {
			return a;
		}
		if (item && item.id === id) {
			return item;
		}
		if (item && item.items) {
			return findById(item.items, id);
		}
		return undefined;
	}, undefined);

const {SubMenu, Item} = Navigation;
const shouldItemBeUpdated = (
	clickedItem: ItemType | undefined,
	doNotHighlight: Array<string>
) =>
	clickedItem &&
	!clickedItem.hasOwnProperty("items") &&
	doNotHighlight.indexOf(clickedItem.id) === -1;

export const Tools: React.FunctionComponent<ToolsProps> = (props) => {
	const doNotHighlight = [COLOR_PICKER, SAVE_AND_PREVIEW];
	const {menuEventHandlers, color, transformMode, onColorChanged} = props;
	const [activeItem, updateActiveItem] = useState<string>("");

	const handleClick = useCallback(
		(id, event) => {
			const clickedItem: ItemType | undefined = findById(schema, id);

			/** For toolbox case we need to react only on "last child" items */
			if (shouldItemBeUpdated(clickedItem, doNotHighlight)) {
				updateActiveItem(id);
			}

			/** Call the callback function */
			if (menuEventHandlers && menuEventHandlers[id]) {
				const callback = menuEventHandlers[id] || undefined;
				if (callback) {
					return callback(id, event);
				}
			}
		},
		[menuEventHandlers, doNotHighlight]
	);

	// Simple recursion to generate the Menu
	const buildMenuItem = (topLevelItem: Array<ItemType>) =>
		topLevelItem.map((item: ItemType) => {
			if (item.id === COLOR_PICKER) {
				return (
					<Item id={item.id} key={item.id}>
						<div style={{zIndex: 9}}>
							<ColorPicker
								disableAlpha={true}
								onChange={onColorChanged}
								color={color}
							/>
						</div>
					</Item>
				);
			}
			if (item.hasOwnProperty("items")) {
				return (
					<SubMenu
						id={item.id}
						title={item.title}
						icon={<Icon name={item.icon || ""} />}
						key={item.id}
					>
						{buildMenuItem(item.items || [])}
					</SubMenu>
				);
			} else {
				return (
					<Item
						id={item.id}
						icon={<Icon name={item.icon || ""} />}
						key={item.id}
					>
						{item.title}
					</Item>
				);
			}
		});

	useEffect(() => {
		transformMode ? updateActiveItem(SELECTION) : updateActiveItem("");
	}, [transformMode]);

	return (
		<Navigation
			size="middle"
			type="vertical"
			mode="toolbox"
			handleClick={handleClick}
			activeItems={[activeItem]}
		>
			{buildMenuItem(schema)}
		</Navigation>
	);
};

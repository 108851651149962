import {useState} from "react";
import {useToasts} from "react-toast-notifications";
import api from "src/api/endpoints";

const useExportLinks = (
	publicationId: number,
	obfuscatedPublicationID: string,
	beforeExport?: () => void
) => {
	const [inProgress, setInProgress] = useState<boolean>(false);

	const {addToast} = useToasts();

	const exportLinks = async () => {
		try {
			setInProgress(true);
			if (beforeExport) {
				await beforeExport();
			}
			const {errors, filename, successes} = await api.generateCSVFile(
				publicationId
			);
			if (errors) {
				addToast(`Export of ${errors} link${errors > 1 ? "s" : ""} fail`, {
					autoDismiss: true,
					appearance: "warning",
				});
			}
			if (!successes) {
				addToast("Non of the links in the publication could be exported", {
					autoDismiss: true,
					appearance: "warning",
				});
			} else {
				if (filename) {
					api.downloadCSVFile(obfuscatedPublicationID, filename);
				}
			}
		} catch (error) {
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		} finally {
			setInProgress(false);
		}
	};

	return {inProgress, exportLinks};
};

export {useExportLinks};

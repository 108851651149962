import React, {useCallback} from "react";

// Components
import ProductWidgetForm from "src/components/ProductWidgetForm/ProductWidgetForm";
import BuyTheLookWidgetForm from "src/components/BuyTheLookWidgetForm/BuyTheLookWidgetForm";
import VideoWidgetForm from "src/components/videoWidgetForm/VideoWidgetForm";
import HtmlWidgetForm from "src/components/htmlWidgetForm/HtmlWidgetForm";
import {ToolPanel} from "src/components/toolPanel/ToolPanel";

// Models
import {Media} from "src/models/Medias";

// Constants & Utilities
import {getWidgetSubNode, getWidgetSubNodeTitle} from "../../utilities";
import {
	VIDEO_WIDGET,
	HTML5_WIDGET,
	BUY_THE_LOOK_WIDGET,
	PRODUCT_WIDGET,
	VIDEO_EMBED_WIDGET_TYPE,
} from "../../constants";
import {
	BuyTheLookWidgetProperties,
	HtmlWidgetProperties,
	ProductDetailsWidgetProperties,
	VideoWidgetProperties,
	Widget,
	WidgetNodes,
} from "../../models/Widgets";

interface LinkPanelProps {
	widget: Widget;
	mediaMap: Media | undefined;
	onChange: (id: string | number, type: WidgetNodes, widget: Widget) => void;
	onPanelFocus: () => void;
}

export const WidgetPanel: React.FC<LinkPanelProps> = (props) => {
	const {widget, mediaMap, onChange, onPanelFocus} = props;

	const widgetType = getWidgetSubNode(widget);
	const title = getWidgetSubNodeTitle(widget);

	const handleSubmit = useCallback(
		(values) => {
			if (widgetType) {
				onChange(values.id, widgetType, values);
			}
		},
		[widgetType, onChange]
	);

	return (
		<div onClick={onPanelFocus}>
			<ToolPanel
				style={{maxWidth: "100%", minWidth: "300px"}}
				title={`${title} Properties`}
			>
				{widgetType === HTML5_WIDGET && (
					<HtmlWidgetForm
						initialValues={widget as HtmlWidgetProperties}
						onSubmit={handleSubmit}
					/>
				)}
				{widgetType === VIDEO_WIDGET && (
					<VideoWidgetForm
						initialValues={widget as VideoWidgetProperties}
						options={{
							platform: VIDEO_EMBED_WIDGET_TYPE,
						}}
						onSubmit={handleSubmit}
					/>
				)}
				{widgetType === PRODUCT_WIDGET && (
					<ProductWidgetForm
						initialValues={widget as ProductDetailsWidgetProperties}
						onSubmit={handleSubmit}
					/>
				)}
				{widgetType === BUY_THE_LOOK_WIDGET && (
					<BuyTheLookWidgetForm
						initialValues={widget as BuyTheLookWidgetProperties}
						images={mediaMap?.images}
						onSubmit={handleSubmit}
					/>
				)}
			</ToolPanel>
		</div>
	);
};

export default WidgetPanel;

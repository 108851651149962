import React from "react";
import {Formik} from "formik";
import withAutoCommit from "src/utilities/form/withAutoCommit";
import {Form, HtmlWidgetValues} from "./Form";

const AutoCommitForm = withAutoCommit<any, HtmlWidgetValues>(Form);

export interface HtmlWidgetProperties {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: HtmlWidgetValues;
	/** Updatable form state. Use when need to change form state from outside.
	 * If values are empty, only initial state wiil be applied
	 */
	values?: HtmlWidgetValues;
	/** Form submission handler */
	onSubmit: (values: HtmlWidgetValues) => void;
}

export const HtmlWidgetForm: React.FC<HtmlWidgetProperties> = (
	props: HtmlWidgetProperties
) => {
	const {initialValues, onSubmit} = props;
	return (
		<Formik
			key={initialValues.id}
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <AutoCommitForm {...props} {...formProps} />}
		</Formik>
	);
};

export default HtmlWidgetForm;

import styled from "@emotion/styled";

export const LockTop = styled.div`
	height: 8px;
	width: 12px;
	border-right: 1px solid gray;
	border-top: 1px solid gray;
	margin-left: 3px;
`;

export const LockBottom = styled.div`
	height: 8px;
	width: 12px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	margin-left: 3px;
`;

import React, {useCallback, ChangeEvent} from "react";

// Components
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";
import {NumericInputComponent} from "@zmags/zmags-ui-library";
import * as StyledForm from "src/utilities/form/StyledForm";
import * as Styled from "./VideoWidgetForm.styles";

// Models
import {VideoWidgetProperties} from "src/models/Widgets";
import {RectType} from "src/models/Shapes";

// Utils
import {SelectOption} from "src/utilities/form";

export interface VideoWidgetValues extends VideoWidgetProperties {}

export interface FormOptions {
	platform: SelectOption<number>[];
}

export interface FormOwnProps {
	options: FormOptions;
}

export type FormProps = FormOwnProps & FormikProps<VideoWidgetValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, values, setFieldValue} = props;

	const handleNumberChange = (name: string): ((value: number) => void) => (
		value
	) => setFieldValue(name, value);

	const handleVideoTypeChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) =>
			setFieldValue(
				"type",
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			),
		[setFieldValue]
	);

	return (
		<FormikForm>
			{/** Platform */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="type">Platform</StyledForm.Label>
				<StyledForm.Field>
					<Field
						as="select"
						options={options.platform}
						disabled={false}
						value={values.type}
						onChange={handleVideoTypeChange}
					>
						{options.platform.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="target" />
			</StyledForm.FieldWrapper>

			{/** Video ID */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="videoID">Video ID</StyledForm.Label>
				<StyledForm.Field>
					<Field name="videoID" type="text" />
				</StyledForm.Field>
				<ErrorMessage name="videoID" />
			</StyledForm.FieldWrapper>

			<Styled.CheckboxGroup>
				{/** Enable controls */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enableControls">
						Enable controls
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							id="enableControls"
							name="enableControls"
							type="checkbox"
							disabled={false}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enableControls" />
				</StyledForm.FieldWrapper>
				{/** Autoplay */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="autoplay">Autoplay</StyledForm.Label>
					<StyledForm.Field>
						<Field
							id="autoplay"
							name="autoplay"
							type="checkbox"
							disabled={false}
						/>
					</StyledForm.Field>
					<ErrorMessage name="autoplay" />
				</StyledForm.FieldWrapper>
				{/** Loop */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="loop">Loop</StyledForm.Label>
					<StyledForm.Field>
						<Field id="loop" name="loop" type="checkbox" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="loop" />
				</StyledForm.FieldWrapper>
			</Styled.CheckboxGroup>

			<StyledForm.Group>
				{/** Width */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.width">
						Width
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.width"
							value={(values.enrichmentShape as RectType)?.width}
							as={NumericInputComponent}
							min={0}
							disabled={false}
							onChange={handleNumberChange("enrichmentShape.width")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.width" />
				</StyledForm.FieldWrapper>
				{/** Top */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.y">Top</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.y"
							value={(values.enrichmentShape as RectType)?.y}
							as={NumericInputComponent}
							min={0}
							disabled={false}
							onChange={handleNumberChange("enrichmentShape.y")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.y" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>

			<StyledForm.Group>
				{/** Height */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.height">
						Height
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.height"
							value={(values.enrichmentShape as RectType)?.height}
							as={NumericInputComponent}
							min={0}
							disabled={false}
							onChange={handleNumberChange("enrichmentShape.height")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.height" />
				</StyledForm.FieldWrapper>
				{/** Left */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.x">Left</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.x"
							value={(values.enrichmentShape as RectType)?.x}
							as={NumericInputComponent}
							min={0}
							disabled={false}
							onChange={handleNumberChange("enrichmentShape.x")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.x" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
		</FormikForm>
	);
};

export default Form;

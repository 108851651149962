import envalid from "../../react-app-envalid";

const env: NodeJS.ProcessEnv = process.env;
const REACT_APP_PUB_HELP = envalid.getRequired(env, "REACT_APP_PUB_HELP");

const useHelpMenu = () => {
	const openHelp = () => {
		window.open(REACT_APP_PUB_HELP, "_blank");
	};
	return {
		helpUrl: REACT_APP_PUB_HELP,
		openHelp,
	};
};

export {useHelpMenu};

import {navigationKey} from "./types";
export const TopNavigationSchemaIdConstants: Record<navigationKey, string> = {
	FILE: "file",
	EDIT: "edit",
	SELECT: "select",
	WINDOW: "window",
	HELP: "help",
	SAVE: "save",
	PREVIEW: "preview",
	DELETE: "delete",
	DELETE_ALL_LINKS: "delete_all_links",
	DELETE_ALL_WIDGETS: "delete_all_widgets",
	DELETE_ALL: "delete_all",
	EXIT: "exit",
	COPY: "copy",
	PASTE: "paste",
	GROUP: "group",
	SELECT_ALL: "select_all",
	DESELECT: "deselect",
	PAGE_WINDOW: "page_window",
	NAVIGATOR: "navigator",
	IMPORT_EXPORT_LINKS: "import_export_links",
	AUTO_LINKS: "auto_links",
	TOOLTIP_MANAGER: "tooltip_manager",
} as const;

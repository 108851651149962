import React, {FunctionComponent} from "react";
import {Formik} from "formik";

import withAutoCommit from "src/utilities/form/withAutoCommit";
import {Form, FormOwnProps, FormValues} from "./Form";

const AutoCommitForm = withAutoCommit<FormOwnProps, FormValues>(Form);

export interface InternalLinkPropertiesFormProps extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: FormValues;
	/** Form submission handler */
	onSubmit: (values: FormValues) => void;
}

export const InternalLinkPropertiesForm: FunctionComponent<InternalLinkPropertiesFormProps> = (
	props: InternalLinkPropertiesFormProps
) => {
	const {initialValues, onSubmit} = props;

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <AutoCommitForm {...props} {...formProps} />}
		</Formik>
	);
};

export default InternalLinkPropertiesForm;

import {EditorSettings} from "src/models/EditorSettings";

const useEditorSettings = (): EditorSettings => {
	const editorSettings = {
		target: [
			{
				value: "_blank",
				displayName: "New window/tab",
			},
			{
				value: "_self",
				displayName: "Current window/tab",
			},
			{
				value: "_parent",
				displayName: "Parent frame",
			},
			{
				value: "_ZMAGS_LIGHTBOX",
				displayName: "LightBox",
			},
			{
				value: "-1",
				displayName: "Custom",
			},
		],
		tooltipType: [
			{
				value: 0,
				displayName: "Text",
			},
		],
		effect: [
			{
				value: 0,
				displayName: "None",
			},
			{
				value: 1,
				displayName: "Fade InOut",
			},
			{
				value: 2,
				displayName: "Pulsating",
			},
			{
				value: 3,
				displayName: "Shadow Frame",
			},
			{
				value: 4,
				displayName: "Frame",
			},
		],
		stretch: [
			{
				value: 0,
				displayName: "None",
			},
			{
				value: 1,
				displayName: "Horizontal",
			},
			{
				value: 2,
				displayName: "Vertical",
			},
			{
				value: 3,
				displayName: "Both",
			},
		],
		horizontalAlignment: [
			{
				value: 0,
				displayName: "None",
			},
			{
				value: 1,
				displayName: "Left",
			},
			{
				value: 2,
				displayName: "Center",
			},
			{
				value: 3,
				displayName: "Right",
			},
		],
		verticalAlignment: [
			{
				value: 0,
				displayName: "None",
			},
			{
				value: 1,
				displayName: "Top",
			},
			{
				value: 2,
				displayName: "Middle",
			},
			{
				value: 3,
				displayName: "Bottom",
			},
		],
	};
	return editorSettings;
};

export {useEditorSettings};

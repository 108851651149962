import {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import api from "src/api/endpoints";

import {Link, ExternalLink, InternalLink, ProductLink} from "src/models/Links";
import {
	ProductDetailsWidgetProperties,
	BuyTheLookWidgetProperties,
	HtmlWidgetProperties,
	VideoWidgetProperties,
	Widget,
} from "src/models/Widgets";
import {ImportedLink} from "src/models/ImportedLinks";
import {PublicationSettings, Page} from "src/models/PublicationSettings";
import {PaginationPage} from "src/hooks/usePagination";
import {
	VIDEO_EMBED_WIDGET_TYPE,
	EXTERNAL_LINK_NODE,
	INTERNAL_LINK_NODE,
	PRODUCT_LINK_NODE,
	PRODUCT_WIDGET,
	BUY_THE_LOOK_WIDGET,
	HTML5_WIDGET,
	VIDEO_WIDGET,
} from "src/constants";
import {StateArray} from "src/models/Common";

const exportLinkFields = new Map<keyof ImportedLink, string>();
exportLinkFields.set("pageNumber", "Page");
exportLinkFields.set("size", "Size");
exportLinkFields.set("type", "Type");
exportLinkFields.set("url", "Target");
exportLinkFields.set("externalSource", "External Source");
exportLinkFields.set("mediaSource", "Media Source");

const formatSize = (width?: number, height?: number) =>
	width && height ? `${width}x${height}` : "";

const convertImportedExternalLinkForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {url, width, height, firstPage, lastPage, top, left} = obj;
	const link: ExternalLink = {
		...obj,
		_type_: EXTERNAL_LINK_NODE,
		id,
		pageNumber: firstPage,
		fromPageNumber: firstPage,
		toPageNumber: lastPage,
		color: parseInt((obj.color ?? "#fff").replace(/^#/, ""), 16),
		x: parseInt(left),
		y: parseInt(top),
		height: parseInt(height),
		width: parseInt(width),
	};
	return {
		id,
		pageNumber: firstPage,
		size: formatSize(width, height),
		type: "External",
		url: url,
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: link,
	};
};

const convertImportedInternalLinkForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {width, height, destinationPage, firstPage, lastPage, top, left} = obj;
	const link: InternalLink = {
		...obj,
		_type_: INTERNAL_LINK_NODE,
		id,
		pageNumber: firstPage,
		fromPageNumber: firstPage,
		toPageNumber: lastPage,
		targetPageNumber: destinationPage,
		color: parseInt((obj.color ?? "#fff").replace(/^#/, ""), 16),
		x: parseInt(left),
		y: parseInt(top),
		height: parseInt(height),
		width: parseInt(width),
	};
	return {
		id,
		pageNumber: firstPage,
		size: formatSize(width, height),
		type: "Internal",
		url: `Page ${destinationPage}`,
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: link,
	};
};

const convertImportedProductLinkForm = (obj: any, id: string): ImportedLink => {
	const {
		width,
		height,
		productName,
		productID,
		productPrice = 0,
		firstPage,
		lastPage,
		top,
		left,
	} = obj;
	const link: ProductLink = {
		...obj,
		_type_: PRODUCT_LINK_NODE,
		id,
		pageNumber: firstPage,
		fromPageNumber: firstPage,
		toPageNumber: lastPage,
		color: parseInt((obj.color ?? "#fff").replace(/^#/, ""), 16),
		x: parseInt(left),
		y: parseInt(top),
		height: parseInt(height),
		width: parseInt(width),
	};
	return {
		id,
		pageNumber: firstPage,
		size: formatSize(width, height),
		type: "Product",
		url: (productName ? `${productName} - ${productPrice}` : productID) || "",
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: link,
	};
};

const convertImportedLinkForm = (
	obj: any,
	id: string
): ImportedLink | undefined => {
	switch (obj.type) {
		case 0:
			return convertImportedExternalLinkForm(obj, id);
		case 1:
			return convertImportedInternalLinkForm(obj, id);
		case 2:
			return convertImportedProductLinkForm(obj, id);
		default:
			return undefined;
	}
};

const convertImportedProductDetailsWidgetForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {
		productID,
		productName,
		productPrice,
		fromPageNumber,
		enrichmentShape = {},
	} = obj;
	const {width, height} = enrichmentShape;
	const widget: ProductDetailsWidgetProperties = {
		...obj,
		_type_: PRODUCT_WIDGET,
		id,
	};
	return {
		id,
		pageNumber: fromPageNumber,
		size: formatSize(width, height),
		type: "Product Details Widget",
		url: (productName ? `${productName} - ${productPrice}` : productID) || "",
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: widget,
	};
};

const convertImportedBuyTheLookWidgetForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {productGrouping = {}, fromPageNumber, enrichmentShape = {}} = obj;
	const {width, height} = enrichmentShape;
	const {products} = productGrouping;
	const widget: BuyTheLookWidgetProperties = {
		...obj,
		_type_: BUY_THE_LOOK_WIDGET,
		id,
	};
	return {
		id,
		pageNumber: fromPageNumber,
		size: formatSize(width, height),
		type: "BTL Widget",
		url: products ? `[${products.join(", ")}]` : "",
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: widget,
	};
};

const convertImportedIframeWidgetForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {link, fromPageNumber, enrichmentShape = {}} = obj;
	const {width, height} = enrichmentShape;
	const widget: HtmlWidgetProperties = {
		...obj,
		_type_: HTML5_WIDGET,
		id,
	};
	return {
		id,
		pageNumber: fromPageNumber,
		size: formatSize(width, height),
		type: "Iframe Widget",
		url: link,
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: widget,
	};
};

const convertImportedVideoEmbedWidgetForm = (
	obj: any,
	id: string
): ImportedLink => {
	const {videoID, type, fromPageNumber, enrichmentShape = {}} = obj;
	const {width, height} = enrichmentShape;
	const platform = VIDEO_EMBED_WIDGET_TYPE.find(({value}) => value === type);
	const platformLabel = platform ? platform.label : "Unknown";
	const widget: VideoWidgetProperties = {
		...obj,
		_type_: VIDEO_WIDGET,
		id,
	};
	return {
		id,
		pageNumber: fromPageNumber,
		size: formatSize(width, height),
		type: "Video Embed Widget",
		url: `${platformLabel} - ${videoID}`,
		externalSource: "",
		mediaSource: "",
		wasCreated: false,
		obj: widget,
	};
};

const objToImportedLink = (
	link: any,
	idx: number
): ImportedLink | undefined => {
	const id = idx.toString() + Date.now();
	// eslint-disable-next-line no-underscore-dangle
	switch (link._type_) {
		case "ImportedLinkAMFExternalForm":
			return convertImportedLinkForm(link, id);
		case "BuyTheLookWidgetAMFExternalForm":
			return convertImportedBuyTheLookWidgetForm(link, id);
		case "ProductDetailsWidgetAMFExternalForm":
			return convertImportedProductDetailsWidgetForm(link, id);
		case "IframeWidgetAMFExternalForm":
			return convertImportedIframeWidgetForm(link, id);
		case "VideoEmbedWidgetAMFExternalForm":
			return convertImportedVideoEmbedWidgetForm(link, id);
		default:
			return undefined;
	}
};

const useImportLinks = (
	publicationId: number | undefined,
	publicationSettings: PublicationSettings | undefined,
	linksWereCreated: StateArray,
	widgetsWereCreated: StateArray,
	createShapes: (shapes: Array<Link | Widget>) => void,
	createShapesAndSave: (shapes: Array<Link | Widget>) => void
) => {
	const [importInProgress, setInProgress] = useState<boolean>(false);

	const [importedLinks, setImportedLinks] = useState<
		ImportedLink[] | undefined
	>(undefined);

	const [importedLinksPages, setPages] = useState<PaginationPage[]>([]);

	const {addToast} = useToasts();

	const importLinks = async (file: File): Promise<void> => {
		if (!publicationId) {
			return;
		}
		try {
			setInProgress(true);
			const {name} = file;
			await api.uploadCSVFile(name, file);
			const newLinks = await api.getCSVList(publicationId, name);
			if (!newLinks || !newLinks.length) {
				addToast("Non of the links were imported", {
					autoDismiss: true,
					appearance: "error",
				});
				return;
			}

			let newImportedLinks: ImportedLink[] | undefined;
			if (newLinks?.length) {
				newImportedLinks = newLinks.reduce(
					(res: ImportedLink[], obj, idx: number): ImportedLink[] => {
						const newImportedLink: ImportedLink | undefined = objToImportedLink(
							obj,
							idx
						);
						if (newImportedLink) {
							res.push(newImportedLink);
						}
						return res;
					},
					[]
				);
			}
			setImportedLinks(newImportedLinks);
		} catch (error) {
			setImportedLinks(undefined);
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		} finally {
			setInProgress(false);
		}
	};

	const cancelImport = (): void => {
		if (
			window.confirm(
				"Are you sure you want to upload a new file, this will remove the current data?"
			)
		) {
			setImportedLinks(undefined);
		}
	};

	const placeAll = (): void => {
		if (!importedLinks) {
			return;
		}
		const shapes = importedLinks.reduce(
			(res: Array<Link | Widget>, importedLink: ImportedLink) => {
				if (!importedLink.wasCreated) {
					res.push(importedLink.obj);
				}
				return res;
			},
			[]
		);
		if (!shapes.length) {
			return;
		}

		createShapesAndSave(shapes);
		setImportedLinks(undefined);
	};

	const placeOnPage = (pageNumber?: number): void => {
		if (!importedLinks) {
			return;
		}
		const shapes = importedLinks.reduce(
			(res: Array<Link | Widget>, importedLink: ImportedLink) => {
				if (
					importedLink.pageNumber === pageNumber &&
					!importedLink.wasCreated
				) {
					res.push(importedLink.obj);
				}
				return res;
			},
			[]
		);
		if (shapes.length) {
			createShapes(shapes);
		}
	};

	useEffect(() => {
		const pagesNumbers = importedLinks
			? importedLinks.map(
					(importedLink: ImportedLink) => importedLink.pageNumber
			  )
			: [];
		const newPages = publicationSettings
			? publicationSettings.pages
					.map((page: Page, idx: number) => ({
						id: page.pageID,
						pageNumber: idx + 1,
						pageIdx: idx,
						title: `Page ${idx + 1}`,
					}))
					.filter((page) => pagesNumbers.includes(page.pageNumber))
			: [];
		setPages(newPages);
	}, [importedLinks, publicationSettings]);

	useEffect(() => {
		if (!importedLinks) {
			return;
		}
		const newImportedLinks = importedLinks.map(
			(importedLink: ImportedLink): ImportedLink => {
				const wasCreated =
					linksWereCreated.has(importedLink.id) ||
					widgetsWereCreated.has(importedLink.id);
				return {
					...importedLink,
					wasCreated,
				};
			}
		);
		setImportedLinks(newImportedLinks);
	}, [linksWereCreated, widgetsWereCreated]);

	return {
		importInProgress,
		importedLinks,
		importedLinksPages,
		importLinks,
		cancelImport,
		placeAll,
		placeOnPage,
		exportLinkFields,
	};
};

export {useImportLinks};

import React, {useEffect, useState, ChangeEvent} from "react";

// Components
import {Button} from "@zmags/zmags-ui-library";
import {Footer, Form, Select} from "./TooltipManager.styles";

// Models
import {LinkTooltip} from "src/models/LinkTooltip";

export interface ViewTooltipFormProps {
	tooltips: LinkTooltip[];
	onCreateNew: () => void;
	onEdit: (tooltip: LinkTooltip) => void;
}

export const ViewTooltipForm: React.FC<ViewTooltipFormProps> = (props) => {
	const {tooltips, onCreateNew, onEdit} = props;
	const [tooltip, setTooltip] = useState<LinkTooltip>();

	function handleLinkChange(event: ChangeEvent<HTMLSelectElement>): void {
		const idx = event.currentTarget.selectedIndex;
		setTooltip(tooltips[idx]);
	}

	useEffect(() => {
		if (tooltip?.type === "custom") {
			onEdit(tooltip);
		}
	}, [tooltip, onEdit]);

	useEffect(() => {
		const [defaultTooltip] = tooltips;
		setTooltip(defaultTooltip);
	}, [tooltips]);

	return (
		<div>
			<Form>
				<Select value={tooltip?.id} onChange={handleLinkChange}>
					{tooltips.map((option) => (
						<option key={option.id} value={option.id}>
							{option.tooltip}
						</option>
					))}
				</Select>
			</Form>
			<Footer>
				<Button label="Create new" size="small" onClick={onCreateNew} />
			</Footer>
		</div>
	);
};

export default ViewTooltipForm;

import React from "react";
import {useEffect, useState, ReactElement} from "react";
import {Label, Text, Tag, Image} from "react-konva";

import {StateArray} from "../models/Common";
import {getLinkSubNode, getWidgetSubNode, intToRGBA} from "../utilities";
import {transformToPoints} from "../utilities/shapes";
import {LINK, WIDGET, PRODUCT_WIDGET, SHAPE_TYPE_RECTANGLE} from "../constants";
import {Widget} from "../models/Widgets";
import {Link} from "../models/Links";
import {Shape, CoordinatesType, RectType} from "../models/Shapes";

const useShapes = ({
	pageWidgets,
	pageLinks,
	widgetsWereSelected,
	linksWereSelected,
}: {
	pageWidgets: Array<Widget> | null;
	pageLinks: Array<Link> | null;
	widgetsWereSelected: StateArray;
	linksWereSelected: StateArray;
}) => {
	const [widgetShapes, updateWidgetShapes] = useState<Array<Shape>>([]);
	const [linkShapes, updateLinkShapes] = useState<Array<Shape>>([]);
	const [icon, setIcon] = useState<HTMLImageElement | undefined>(undefined);
	useEffect(() => {
		const image = new window.Image();
		image.width = 40;
		image.height = 40;
		image.addEventListener("load", () => {
			setIcon(image);
		});

		image.src = "https://image.flaticon.com/icons/png/512/32/32360.png";
	}, []);

	/** Build Link shapes */
	useEffect(() => {
		if (pageLinks) {
			const linkShapesArray: Array<Shape> = [];
			const defaultLinkShapeProps = {
				fill: "#5b51ee",
				opacity: 1,
				strokeWidth: 0,
			};
			if (!pageLinks.length) {
				return updateLinkShapes([]);
			}
			pageLinks.forEach((linkNode: Link) => {
				const {id, x, y, width, height, rotation} = linkNode;
				const points = transformToPoints({
					x,
					y,
					width,
					height,
					rotation,
					_type_: SHAPE_TYPE_RECTANGLE,
				});
				const bcOpacity =
					linkNode.alpha !== undefined && linkNode.alpha !== null
						? linkNode.alpha / 100
						: defaultLinkShapeProps.opacity;
				const shape = {
					...defaultLinkShapeProps,
					fill: intToRGBA(linkNode.color || 0, bcOpacity),
					isShapeSelected: linksWereSelected.has(id),
					id: id as string,
					points,
					type: {node: LINK, subNode: getLinkSubNode(linkNode)},
				};
				linkShapesArray.push(shape);
				updateLinkShapes(linkShapesArray);
			});
		}
	}, [pageLinks, linksWereSelected]);

	/** Build Widgets shapes */
	useEffect(() => {
		if (pageWidgets) {
			const defaultWidgetShapeProps = ({isActive}: {isActive: boolean}) => ({
				fill: isActive ? "#6fee51" : "#dedede",
				stroke: isActive ? "#6fee51" : "#969595",
				opacity: 0.5,
				strokeWidth: 1,
			});
			const widgetShapesArray: Array<Shape> = [];

			pageWidgets.forEach((widgetNode: Widget) => {
				const isActive =
					("shapeEnabled" in widgetNode && widgetNode.shapeEnabled) ||
					!("shapeEnabled" in widgetNode);
				const {id, enrichmentShape} = widgetNode;
				const groupElements: Array<ReactElement> = [];
				const isPolygon = enrichmentShape.hasOwnProperty("coordinates");
				const subNode = getWidgetSubNode(widgetNode);
				let points = [];
				if (isPolygon) {
					points = (enrichmentShape as CoordinatesType).coordinates.reduce(
						(a: Array<number>, b: {x: number; y: number}) =>
							a.concat([b.x, b.y]),
						[]
					);
				} else {
					const {x, y, width, height, rotation} = enrichmentShape as RectType;
					points = transformToPoints({
						x,
						y,
						width,
						height,
						rotation,
						_type_: SHAPE_TYPE_RECTANGLE,
					});
				}

				/** need to add label or image to the shape*/
				if (
					"widgetButton" in widgetNode &&
					widgetNode.widgetButton.displayComponent
				) {
					if (subNode === PRODUCT_WIDGET) {
						/** add circle image*/
						groupElements.push(
							<Image
								key={1}
								width={40}
								height={40}
								image={icon}
								offsetX={0}
								offsetY={0}
								x={widgetNode.widgetButton.shape.x}
								y={widgetNode.widgetButton.shape.y}
							/>
						);
					} else {
						/** add label*/
						groupElements.push(
							<Label
								key={1}
								x={widgetNode.widgetButton.shape.x}
								y={widgetNode.widgetButton.shape.y}
								offsetX={0}
								offsetY={0}
							>
								<Tag
									fill="black"
									stroke="black"
									shadowColor={"black"}
									shadowBlur={10}
									shadowOpacity={0.2}
									lineJoin={"round"}
								/>
								<Text
									fill="white"
									fontSize={18}
									padding={5}
									text={widgetNode.widgetButton.text}
								/>
							</Label>
						);
					}
				}
				const shape: Shape = {
					...defaultWidgetShapeProps({
						isActive,
					}),
					id: id as string,
					isShapeSelected: widgetsWereSelected.has(id),
					points,
					groupElements,
					type: {node: WIDGET, subNode},
				};
				widgetShapesArray.push(shape);
			});
			updateWidgetShapes(widgetShapesArray);
		}
	}, [pageWidgets, widgetsWereSelected, icon]);

	return {widgetShapes, linkShapes};
};

export {useShapes};

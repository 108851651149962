import React, {useState, useCallback, useEffect, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

// Components
import {
	Slider,
	ColorPicker,
	NumericInputComponent,
	RadioGroup,
	Button,
} from "@zmags/zmags-ui-library";
import {LinkTarget} from "src/components/linkTarget/LinkTarget";
import * as StyledForm from "src/utilities/form/StyledForm";

// Types
import {AutoLinks} from "src/models/AutoLinks";

// Utils
import {SelectOption} from "src/utilities/form";
import {hexToInt} from "src/utilities";

export interface FormValues extends AutoLinks {}

export interface FormOptions {
	pages: SelectOption<number>[];
	target: SelectOption<string>[];
	effect: SelectOption<number>[];
}

export interface FormOwnProps {
	/** Select options */
	options: FormOptions;
	/** Current Page */
	currentPage: number;
}

export type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, currentPage, values, setFieldValue, submitForm} = props;
	const [thisPageOnly, setThisPageOnly] = useState<boolean>(false);
	const maxPage = options.pages.length;

	// Update select field value
	const handleSelectChange = (
		name: string
	): ((event: ChangeEvent<HTMLSelectElement>) => void) => (event) =>
		setFieldValue(
			name,
			Number(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			)
		);

	// Update number field value since onchange receive number, not event
	const handleNumberChange = (name: string): ((value: number) => void) => (
		value
	) => setFieldValue(name, value);

	const handleThisPageOnlyChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const {checked} = event.target;
			setThisPageOnly(checked);
		},
		[]
	);

	const handleProductSourceChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const {value} = event.target;
			setFieldValue("productSource", Number(value));
		},
		[setFieldValue]
	);

	const handleChangeColor = useCallback(
		(color: string) => {
			setFieldValue("colour", hexToInt(color));
		},
		[setFieldValue]
	);

	const setTarget = useCallback(
		(value: string) => {
			setFieldValue("linkTarget", value);
		},
		[setFieldValue]
	);

	const setLightBox = useCallback(
		(value: boolean) => {
			setFieldValue("isLightbox", value);
		},
		[setFieldValue]
	);

	const setLightBoxWidth = useCallback(
		(value: number) => {
			setFieldValue("lightBoxWidth", value);
		},
		[setFieldValue]
	);

	const setLightBoxHeight = useCallback(
		(value: number) => {
			setFieldValue("lightBoxHeight", value);
		},
		[setFieldValue]
	);

	useEffect(() => {
		if (thisPageOnly) {
			setFieldValue("fromPage", currentPage);
			setFieldValue("toPage", currentPage);
		}
	}, [thisPageOnly, currentPage, setFieldValue]);

	return (
		<FormikForm>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>External Links</StyledForm.LabelH1>
				{/* Search for */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="specific">Search for</StyledForm.Label>
					<StyledForm.Field>
						<Field name="specific" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="specific" />
				</StyledForm.FieldWrapper>
				{/** Specific URL */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="specificURL">
						Specific URL
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="specificURL" type="url" placeholder="" />
					</StyledForm.Field>
					<ErrorMessage name="specificURL" />
				</StyledForm.FieldWrapper>
				<LinkTarget
					target={options.target}
					values={{
						target: values.linkTarget,
						lightBox: values.isLightbox,
						lightBoxWidth: values.lightBoxWidth,
						lightBoxHeight: values.lightBoxHeight,
					}}
					setTarget={setTarget}
					setLightBox={setLightBox}
					setLightBoxWidth={setLightBoxWidth}
					setLightBoxHeight={setLightBoxHeight}
				/>
				<StyledForm.Group>
					{/** Include PDF Links */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="pdfLinks">
							Include PDF Links
						</StyledForm.Label>
						<StyledForm.Field>
							<Field name="pdfLinks" type="checkbox" disabled={false} />
						</StyledForm.Field>
						<ErrorMessage name="pdfLinks" />
					</StyledForm.FieldWrapper>
					{/** Search for web links */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="webLinks">
							Search for web links
						</StyledForm.Label>
						<StyledForm.Field>
							<Field name="webLinks" type="checkbox" disabled={false} />
						</StyledForm.Field>
						<ErrorMessage name="webLinks" />
					</StyledForm.FieldWrapper>
					{/** Search for e-mails */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="emailLinks">
							Search for e-mails
						</StyledForm.Label>
						<StyledForm.Field>
							<Field name="emailLinks" type="checkbox" disabled={false} />
						</StyledForm.Field>
						<ErrorMessage name="emailLinks" />
					</StyledForm.FieldWrapper>
				</StyledForm.Group>
			</StyledForm.GroupWithHeader>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>External Links (Advanced)</StyledForm.LabelH1>
				{/* Regular expression */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="regex">
						Regular expression
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="regex" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="regex" />
				</StyledForm.FieldWrapper>
				{/** Link */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="regexLink">Link</StyledForm.Label>
					<StyledForm.Field>
						<Field name="regexLink" type="text" placeholder="" />
					</StyledForm.Field>
					<ErrorMessage name="regexLink" />
				</StyledForm.FieldWrapper>
			</StyledForm.GroupWithHeader>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>Internal Links</StyledForm.LabelH1>
				{/* Regular expression */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="internalRegex">
						Regular expression
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="internalRegex" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="internalRegex" />
				</StyledForm.FieldWrapper>
				{/** Target page */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="internalRegexPage">
						Target page
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as="select"
							options={options.pages}
							disabled={false}
							value={values.internalRegexPage}
							onChange={handleSelectChange("internalRegexPage")}
						>
							{options.pages.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
					<ErrorMessage name="internalRegexPage" />
				</StyledForm.FieldWrapper>
			</StyledForm.GroupWithHeader>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>Product Links</StyledForm.LabelH1>
				{/* Regular expression */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="productRegex">
						Regular expression
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="productRegex" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="productRegex" />
				</StyledForm.FieldWrapper>
				{/* Product source */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Product source</StyledForm.Label>
					<StyledForm.Field>
						<RadioGroup
							name="productSource"
							value={values.productSource?.toString()}
							onChange={handleProductSourceChange}
						>
							<RadioGroup.Radio
								id="2"
								key="2"
								value="2"
								label="External"
								disabled={false}
							/>
							<RadioGroup.Radio
								id="1"
								key="1"
								value="1"
								label="Product database"
								disabled={false}
							/>
						</RadioGroup>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
				{/** Create links for missing products */}
				{values.productSource === 1 && (
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="allowInvalidProductSKU">
							Create links for missing products
						</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="allowInvalidProductSKU"
								type="checkbox"
								disabled={false}
							/>
						</StyledForm.Field>
						<ErrorMessage name="allowInvalidProductSKU" />
					</StyledForm.FieldWrapper>
				)}
			</StyledForm.GroupWithHeader>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>Appearance</StyledForm.LabelH1>
				{/* External tooltip */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipExternal">
						External tooltip
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="tooltipExternal" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="tooltipExternal" />
				</StyledForm.FieldWrapper>
				{/* Internal tooltip */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipInternal">
						Internal tooltip
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="tooltipInternal" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="tooltipInternal" />
				</StyledForm.FieldWrapper>
				{/* E-mail tooltip */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipMail">
						E-mail tooltip
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="tooltipMail" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="tooltipMail" />
				</StyledForm.FieldWrapper>
				{/* Product link tooltip */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipProduct">
						Product link tooltip
					</StyledForm.Label>
					<StyledForm.Field>
						<Field name="tooltipProduct" type="text" disabled={false} />
					</StyledForm.Field>
					<ErrorMessage name="tooltipProduct" />
				</StyledForm.FieldWrapper>
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="effect">Effect</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="effect"
							as="select"
							disabled={false}
							onChange={handleSelectChange("effect")}
						>
							{options.effect.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
					<ErrorMessage name="effect" />
				</StyledForm.FieldWrapper>
				{/** Opacity */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="transparency">Opacity</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="transparency"
							as={Slider}
							disabled={false}
							min={0}
							max={100}
							step={1}
						/>
					</StyledForm.Field>
					<ErrorMessage name="transparency" />
				</StyledForm.FieldWrapper>
				{/** Link color */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="colour"> Link color</StyledForm.Label>
					<StyledForm.Field>
						<ColorPicker
							color={`#${values.colour}`}
							disableAlpha={true}
							presetColors={[]}
							onChange={handleChangeColor}
						/>
					</StyledForm.Field>
					<ErrorMessage name="colour" />
				</StyledForm.FieldWrapper>
			</StyledForm.GroupWithHeader>
			<StyledForm.GroupWithHeader>
				<StyledForm.LabelH1>Page range (default: All pages)</StyledForm.LabelH1>
				{/** This page only */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label>This page only</StyledForm.Label>
					<StyledForm.Field>
						<input
							type="checkbox"
							checked={thisPageOnly}
							onChange={handleThisPageOnlyChange}
						/>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
				{/** Pages */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Pages</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as={NumericInputComponent}
							disabled={thisPageOnly}
							min={1}
							max={maxPage - 1}
							value={values.fromPage}
							name="fromPage"
							onChange={handleNumberChange("fromPage")}
						/>
						<ErrorMessage name="fromPage" />
					</StyledForm.Field>
					<span>-</span>
					<StyledForm.Field>
						<Field
							as={NumericInputComponent}
							disabled={thisPageOnly}
							min={1}
							max={maxPage}
							value={values.toPage}
							name="toPage"
							onChange={handleNumberChange("toPage")}
						/>
						<ErrorMessage name="toPage" />
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
			</StyledForm.GroupWithHeader>
			<StyledForm.Footer>
				<Button type="primary" label="Import links" onClick={submitForm} />
			</StyledForm.Footer>
		</FormikForm>
	);
};

export default Form;

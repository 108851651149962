import React, {useCallback, useEffect, useState, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

// Components
import {NumericInputComponent} from "@zmags/zmags-ui-library";
import * as StyledForm from "src/utilities/form/StyledForm";

// Types
import {ExternalLink, Target} from "src/models/Links";
import {SelectOption} from "src/utilities/form";

// Utils
import {isEqual} from "src/utilities/form";

export interface FormValues extends ExternalLink {}

export interface FormOptions {
	target: SelectOption<string>[];
}

export interface FormOwnProps {
	options: FormOptions;
}

type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, values, setFieldValue} = props;
	const [targetAttr, setTargetAttr] = useState<string | undefined>(
		values.target
	);

	// Update number field value since onchange receive number, not event
	const handleNumberChange = (name: string): ((value: number) => void) => (
		value
	) => setFieldValue(name, value);

	const handleTargetAttrChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) =>
			setTargetAttr(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			),
		[]
	);

	// Update lightBox based on current target selected
	useEffect(() => {
		if (!isEqual<FormValues, Target>(props, "target", Target.LightBox)) {
			setFieldValue("lightBoxWidth", 0);
			setFieldValue("lightBoxHeight", 0);
		}
	}, [values.target, setFieldValue]);

	// Update target based on selected
	useEffect(() => {
		setFieldValue(
			"target",
			targetAttr !== Target.Custom ? targetAttr : "_blank"
		);
		setFieldValue("lightBox", targetAttr === Target.LightBox);
	}, [targetAttr, setFieldValue]);

	return (
		<FormikForm>
			{/** URL */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="url">URL</StyledForm.Label>
				<StyledForm.Field>
					<Field name="url" type="url" placeholder="//" />
				</StyledForm.Field>
				<ErrorMessage name="url" />
			</StyledForm.FieldWrapper>
			{/** Open link in */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Open link in</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as="select"
							id="targetAttr"
							options={options.target}
							disabled={false}
							value={targetAttr}
							onChange={handleTargetAttrChange}
						>
							{options.target.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
					<ErrorMessage name="target" />
					{/* Target */}
					<StyledForm.Field>
						<Field
							name="target"
							type="text"
							disabled={targetAttr !== Target.Custom}
						/>
					</StyledForm.Field>
					<ErrorMessage name="target" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
			{/** LightBox options */}
			<StyledForm.Group>
				{/* LightBox width */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxWidth">
						LightBox width
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxWidth"
							as={NumericInputComponent}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.LightBox)
							}
							onChange={handleNumberChange("lightBoxWidth")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxWidth" />
				</StyledForm.FieldWrapper>
				{/* LightBox height */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxHeight">
						LightBox height
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxHeight"
							as={NumericInputComponent}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.LightBox)
							}
							onChange={handleNumberChange("lightBoxHeight")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxHeight" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
		</FormikForm>
	);
};

export default Form;

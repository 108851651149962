import {useState} from "react";
import {useToasts} from "react-toast-notifications";

// API
import api from "src/api/endpoints";

// Models
import {AutoLinks} from "src/models/AutoLinks";
import {PublicationSettings} from "src/models/PublicationSettings";

import {AUTO_LINK_PRESET} from "src/constants";

const useAutoLinks = (
	publicationId: number,
	publicationSettings: PublicationSettings
) => {
	const {addToast} = useToasts();
	const [autoLinks] = useState<AutoLinks>({
		...AUTO_LINK_PRESET,
		publicationID: publicationSettings.obfuscatedPublicationID,
		toPage: publicationSettings.pages.length,
	});
	const [loading, setLoading] = useState<boolean>(false);

	const importAutoLinks = async (values: AutoLinks) => {
		// pending import
		if (loading) {
			return;
		}
		try {
			setLoading(true);
			const numberOfLinks = await api.importAutoLinks(publicationId, values);
			if (!numberOfLinks) {
				addToast("No links were found", {
					autoDismiss: true,
					appearance: "warning",
				});
				return;
			}
			addToast(`${numberOfLinks} links found. Restarting application...`, {
				autoDismiss: true,
				appearance: "info",
			});
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		} finally {
			setLoading(false);
		}
	};

	return {autoLinks, loading, importAutoLinks};
};

export {useAutoLinks};

import React, {useCallback, useState, SyntheticEvent, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

// Components
import {
	NumericInputComponent,
	Button,
	Slider,
	Icon,
} from "@zmags/zmags-ui-library";
import * as StyledForm from "src/utilities/form/StyledForm";
import * as Styled from "./LinkPropertiesForm.styles";

// Types
import {
	BaseLink,
	Stretch,
	HorizontalAlignment,
	VerticalAlignment,
} from "src/models/Links";
import {SelectOption} from "src/utilities/form";

// Utils
import {isEqual} from "src/utilities/form";
import {MIN_WIDGET_SIZE} from "src/constants";

export interface FormValues extends BaseLink {}

export interface FormOptions {
	tooltipSuggestedText: SelectOption<number>[];
	effect: SelectOption<number>[];
	externalSource: SelectOption<string | null>[];
	stretch: SelectOption<number>[];
	horizontalAlignment: SelectOption<number>[];
	verticalAlignment: SelectOption<number>[];
}

export interface FormOwnProps {
	options: FormOptions;
}

export type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, values, setFieldValue} = props;
	const [spanPages, setSpanPages] = useState<boolean>(false);

	// Update number field value since onchange receive number, not event
	const handleNumberChange = (name: string): ((value: number) => void) => (
		value
	) => setFieldValue(name, value);

	// Update select field value
	const handleNumberSelectChange = (
		name: string
	): ((event: ChangeEvent<HTMLSelectElement>) => void) => (event) =>
		setFieldValue(
			name,
			Number(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			)
		);

	const handleStringSelectChange = (
		name: string
	): ((event: ChangeEvent<HTMLSelectElement>) => void) => (event) =>
		setFieldValue(
			name,
			event.currentTarget.options[event.currentTarget.selectedIndex].value
		);

	const handleSelectHorizontalAlignment = handleNumberSelectChange(
		"horizontalAlignment"
	);

	const handleSelectVerticalAlignment = handleNumberSelectChange(
		"verticalAlignment"
	);

	// Update stretch options
	const handleStretchChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			const value = Number(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			);
			const option = options.stretch.find(
				(o: SelectOption<number>) => o.value === value
			);
			setFieldValue("stretch", option ? option.value : Stretch.None);
			if (!option) {
				return;
			}
			// Update horizontal alignment to default if option is not allowed
			if (
				option.value === Stretch.Horizontal ||
				option.value === Stretch.Both
			) {
				setFieldValue("horizontalAlignment", HorizontalAlignment.None);
			}

			// Update vertical alignment to default if option is not allowed
			if (option.value === Stretch.Vertical || option.value === Stretch.Both) {
				setFieldValue("verticalAlignment", VerticalAlignment.None);
			}
		},
		[options.stretch, setFieldValue]
	);

	const handleConstrainProportionChange = useCallback(
		(e?: SyntheticEvent) => {
			if (e) {
				e.preventDefault();
			}
			setFieldValue("constrainProportion", !values.constrainProportion);
		},
		[values.constrainProportion, setFieldValue]
	);

	const handleSpanPagesChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const {checked} = event.target;
			setSpanPages(checked);
		},
		[]
	);

	return (
		<FormikForm>
			<StyledForm.Group>
				<StyledForm.Group style={{width: "auto"}}>
					<div>
						{/* Width */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="width">Width</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="width"
									as={NumericInputComponent}
									min={MIN_WIDGET_SIZE}
									debounceDelay={1000}
									onChange={handleNumberChange("width")}
								/>
							</StyledForm.Field>
							<ErrorMessage name="width" />
						</StyledForm.FieldWrapper>
						{/* Height */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="height">Height</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="height"
									as={NumericInputComponent}
									min={MIN_WIDGET_SIZE}
									debounceDelay={1000}
									onChange={handleNumberChange("height")}
								/>
							</StyledForm.Field>
							<ErrorMessage name="height" />
						</StyledForm.FieldWrapper>
					</div>
					{/* Constrain Proportion */}
					<div>
						{values.constrainProportion && <Styled.LockTop />}
						<Button
							icon={<Icon name={"lock"} />}
							onClick={handleConstrainProportionChange}
							size="small"
							shape="sharp"
							type="default"
						/>
						{values.constrainProportion && <Styled.LockBottom />}
					</div>
				</StyledForm.Group>
				<div>
					{/* Top */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="y">Top</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="y"
								as={NumericInputComponent}
								min={0}
								onChange={handleNumberChange("y")}
							/>
						</StyledForm.Field>
						<ErrorMessage name="y" />
					</StyledForm.FieldWrapper>
					{/* Left */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="x">Left</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="x"
								as={NumericInputComponent}
								min={0}
								onChange={handleNumberChange("x")}
							/>
						</StyledForm.Field>
						<ErrorMessage name="x" />
					</StyledForm.FieldWrapper>
				</div>
			</StyledForm.Group>
			{/** External source */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="externalSource">
					External source
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="externalSource"
						as="select"
						disabled={false}
						onChange={handleStringSelectChange("externalSource")}
					>
						{options.externalSource.map((option) => (
							<option key={option.value} value={option.value || ""}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="externalSource" />
			</StyledForm.FieldWrapper>
			{/** Opacity */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="alpha">Opacity</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="alpha"
						as={Slider}
						disabled={false}
						min={0}
						max={100}
						step={1}
					/>
				</StyledForm.Field>
				<ErrorMessage name="alpha" />
			</StyledForm.FieldWrapper>
			{/** Span pages */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label>Span pages</StyledForm.Label>
				<StyledForm.Field>
					<Field
						type="checkbox"
						disabled={false}
						checked={spanPages}
						onChange={handleSpanPagesChange}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Span pages options */}
			{!!spanPages && (
				<div>
					{/** Page range */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label>Page range</StyledForm.Label>
						<StyledForm.Field>
							<Field
								as={NumericInputComponent}
								disabled={true}
								min={0}
								max={100}
								step={1}
								value={values.fromPageNumber}
							/>
						</StyledForm.Field>
						<span>-</span>
						<StyledForm.Field>
							<Field
								as={NumericInputComponent}
								disabled={true}
								min={0}
								max={100}
								step={1}
								value={values.toPageNumber}
							/>
						</StyledForm.Field>
					</StyledForm.FieldWrapper>
					{/** Stretch */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="stretch">Stretch</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="stretch"
								as="select"
								disabled={false}
								onChange={handleStretchChange}
							>
								{options.stretch.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="stretch" />
					</StyledForm.FieldWrapper>
					{/** Horizontal Alignment */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="horizontalAlignment">
							Horizontal Alignment
						</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="horizontalAlignment"
								as="select"
								disabled={
									isEqual<FormValues, Stretch>(
										props,
										"stretch",
										Stretch.Horizontal
									) ||
									isEqual<FormValues, Stretch>(props, "stretch", Stretch.Both)
								}
								onChange={handleSelectHorizontalAlignment}
							>
								{options.horizontalAlignment.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="horizontalAlignment" />
					</StyledForm.FieldWrapper>
					{/** Vertical Alignment */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="verticalAlignment">
							Vertical Alignment
						</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="verticalAlignment"
								as="select"
								disabled={
									isEqual<FormValues, Stretch>(
										props,
										"stretch",
										Stretch.Vertical
									) ||
									isEqual<FormValues, Stretch>(props, "stretch", Stretch.Both)
								}
								onChange={handleSelectVerticalAlignment}
							>
								{options.verticalAlignment.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="verticalAlignment" />
					</StyledForm.FieldWrapper>
				</div>
			)}
		</FormikForm>
	);
};

export default Form;

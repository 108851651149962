import {
	STATUS_EMPTY_NODES,
	STATUS_FULFILLED,
	STATUS_PARTIAL_REJECTED,
	STATUS_REJECTED,
} from "../constants";
import {Link, LinkNodes} from "../models/Links";
import {
	BUY_THE_LOOK_WIDGET,
	EXTERNAL_LINK_NODE,
	HTML5_WIDGET,
	INTERNAL_LINK_NODE,
	PRODUCT_LINK_NODE,
	PRODUCT_WIDGET,
	VIDEO_WIDGET,
} from "../constants";
import {Widget} from "../models/Widgets";

export const processingNodesRequest = async ({
	widgets,
	links,
}: {
	widgets?: {length: number; nodesToProcess: any};
	links?: {length: number; nodesToProcess: any};
}) => {
	const processedWidgets = await widgets?.nodesToProcess;
	const processedLinks = await links?.nodesToProcess;
	const rejectedWidgets = processedWidgets
		? processedWidgets.filter(
				(widget: {status: string}) => widget.status === "rejected"
		  )
		: undefined;
	const rejectedLinks = processedLinks
		? processedLinks.filter(
				(link: {status: string}) => link.status === "rejected"
		  )
		: undefined;
	let status = STATUS_FULFILLED;

	/** It means we have nothing to process */
	if (
		(widgets?.length === 0 && links?.length === 0) ||
		(widgets?.length === 0 && !links) ||
		(links?.length === 0 && !widgets)
	) {
		status = STATUS_EMPTY_NODES;
	} else if (
		/** It means no one node were processed*/
		(rejectedWidgets &&
			rejectedWidgets?.length === widgets?.length &&
			widgets?.length !== 0) ||
		(rejectedLinks &&
			rejectedLinks.length === links?.length &&
			links?.length !== 0)
	) {
		status = STATUS_REJECTED;
	} else if (
		/** It means we have unprocessed nodes, but some were processed*/
		(rejectedWidgets && rejectedWidgets?.length > 0) ||
		(rejectedLinks && rejectedLinks.length > 0)
	) {
		status = STATUS_PARTIAL_REJECTED;
	}
	return {
		status,
		rejectedWidgets: rejectedWidgets?.length ?? 0,
		rejectedLinks: rejectedLinks?.length ?? 0,
		totalLinks: links?.length ?? 0,
		totalWidgets: widgets?.length ?? 0,
	};
};

/**
 * This function define type of Link node
 *
 * @param linkNode
 */
export const getLinkSubNode = (linkNode: Link): LinkNodes => {
	// eslint-disable-next-line no-underscore-dangle
	const type = linkNode._type_;
	if (type) {
		return type;
	}
	if (linkNode.hasOwnProperty("url")) {
		return EXTERNAL_LINK_NODE;
	}
	if (linkNode.hasOwnProperty("targetPageNumber")) {
		return INTERNAL_LINK_NODE;
	}
	if (linkNode.hasOwnProperty("productID")) {
		return PRODUCT_LINK_NODE;
	}
	return EXTERNAL_LINK_NODE;
};

/**
 * Get title for the link type
 *
 * @param linkNode
 */
export const getLinkSubNodeTitle = (linkNode: Link): string => {
	const type = getLinkSubNode(linkNode);
	switch (type) {
		case EXTERNAL_LINK_NODE:
			return "External Link";
		case INTERNAL_LINK_NODE:
			return "Internal Link";
		case PRODUCT_LINK_NODE:
			return "Product Link";
		default:
			return "";
	}
};

/**
 * This function define type of Widget node
 *
 * @param widgetNode
 */
export const getWidgetSubNode = (widgetNode: Widget) => {
	if (widgetNode.hasOwnProperty("videoID")) {
		return VIDEO_WIDGET;
	}
	if (widgetNode.hasOwnProperty("productDetailsLabel")) {
		return PRODUCT_WIDGET;
	}
	if (widgetNode.hasOwnProperty("productGrouping")) {
		return BUY_THE_LOOK_WIDGET;
	}
	if (widgetNode.hasOwnProperty("link")) {
		return HTML5_WIDGET;
	}
};

/**
 * Get title for the widget type
 *
 * @param linkNode
 */
export const getWidgetSubNodeTitle = (widgetNode: Widget): string => {
	const type = getWidgetSubNode(widgetNode);
	switch (type) {
		case HTML5_WIDGET:
			return "HTML5 iframe Widget";
		case VIDEO_WIDGET:
			return "Video Widget";
		case BUY_THE_LOOK_WIDGET:
			return "Buy the Look Widget ";
		case PRODUCT_WIDGET:
			return "Product Widget";
		default:
			return "";
	}
};

export const generateId = (length: number) => {
	let result = "";
	const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const hexToInt = (color?: string): number => {
	return parseInt((color ?? "#ffffff").replace(/^#/, ""), 16);
};

export const intToHex = (color: number): string => {
	return `#${color.toString(16).padStart(6, "0")}`;
};

export const hexToRGBA = (hex: string, opacity: number) => {
	const color = hex.replace("#", "");
	const r = parseInt(color.substring(0, 2), 16);
	const g = parseInt(color.substring(2, 4), 16);
	const b = parseInt(color.substring(4, 6), 16);

	return `rgba(${r},${g},${b},${opacity})`;
};

export const intToRGBA = (color: number, opacity: number) => {
	const hex = intToHex(color || 0);
	return hexToRGBA(hex, opacity);
};

/**
 * Format number as string.
 * Returns a singular noun when number equals to 1
 * and a plural noun if number is more then 1.
 *
 * @param count Number of nouns
 * @param singular A singular form of a noun
 * @param plural A plural form of a noun
 */
export const formatNumberTitle = (
	count: number | undefined,
	singular: string,
	plural: string
) => {
	if (!count) {
		return "";
	}
	if (count === 1) {
		return `${count} ${singular}`;
	}
	return `${count} ${plural}`;
};

import React, {useState, useEffect} from "react";

// Components
import {FileUpload, Button, Icon} from "@zmags/zmags-ui-library";
import * as Styled from "./ImportExportLinks.styles";

// Models
import {ImportedLink} from "src/models/ImportedLinks";

// hooks
import {PaginationPage, usePagination} from "src/hooks/usePagination";

export interface FormValues {}

export interface ImportLinksProps {
	initialPageIdx: number;
	helpUrl: string;
	importInProgress: boolean;
	importedLinks?: ImportedLink[];
	pages: PaginationPage[];
	navigateToPage?: (pageIdx: number) => void;
	importLinks: (file: File) => Promise<void>;
	cancelImport: () => void;
	exportLinkFields: Map<keyof ImportedLink, string>;
	placeAll: () => void;
	placeOnPage: (pageNumber: number) => void;
}

export const ImportLinks: React.FC<ImportLinksProps> = (props) => {
	const {
		initialPageIdx,
		helpUrl,
		importInProgress,
		importedLinks,
		pages,
		navigateToPage,
		importLinks,
		cancelImport,
		exportLinkFields,
		placeAll,
		placeOnPage,
	} = props;
	const [file, setFile] = useState<File | null>(null);
	const {
		currentPageIdx,
		currentPage,
		canMoveNextPage,
		canMovePrevPage,
		setCurrentPage,
		movePrevPage,
		moveNextPage,
	} = usePagination(initialPageIdx, pages);

	function handleImportLinks() {
		if (file) {
			importLinks(file);
		}
	}

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const [newFle] = event.target.files;
		setFile(newFle);
	}

	function handlePlaceAll() {
		placeAll();
	}

	function handlePlaceOnPage() {
		placeOnPage(currentPage?.pageNumber);
	}

	function handleUploadNew() {
		cancelImport();
	}

	useEffect(() => {
		if (navigateToPage && currentPageIdx !== undefined) {
			navigateToPage(currentPageIdx);
		}
	}, [currentPageIdx, navigateToPage]);

	useEffect(() => {
		setFile(null);
	}, []);

	return (
		<Styled.Form>
			{importedLinks ? (
				<Styled.ImportPreview>
					<Styled.Nav>
						<Styled.Pagination>
							<Button
								icon={<Icon name={"arrow_left"} />}
								onClick={movePrevPage}
								size="small"
								type="secondary"
								disabled={!canMovePrevPage}
							/>
							{pages && (
								<select value={currentPage?.id} onChange={setCurrentPage}>
									{pages.map((page) => (
										<option key={page.id} value={page.id}>
											{page.title}
										</option>
									))}
								</select>
							)}
							<Button
								icon={<Icon name={"arrow_right"} />}
								onClick={moveNextPage}
								size="small"
								type="secondary"
								disabled={!canMoveNextPage}
							/>
						</Styled.Pagination>
						<Styled.Row>
							<Button
								size="small"
								label={"Place all"}
								disabled={false}
								onClick={handlePlaceAll}
							/>
							<Button
								size="small"
								label={"Place on page"}
								disabled={false}
								onClick={handlePlaceOnPage}
							/>
							<Button
								size="small"
								label={"Upload new"}
								disabled={false}
								onClick={handleUploadNew}
							/>
						</Styled.Row>
					</Styled.Nav>
					<Styled.AutoSizeRow>
						<Styled.LinksTable>
							<thead>
								<tr>
									{Array.from(exportLinkFields.entries()).map(
										([field, title]) => (
											<th key={field}>{title}</th>
										)
									)}
								</tr>
							</thead>
							<tbody>
								{importedLinks
									.filter(
										(importedLink: ImportedLink) =>
											importedLink.pageNumber === currentPage?.pageNumber
									)
									.map((importedLink: ImportedLink) => {
										return (
											<tr
												key={importedLink.id}
												className={importedLink.wasCreated ? "disabled" : ""}
											>
												{Array.from(exportLinkFields.keys()).map((field) => (
													<td key={`${importedLink.id}${field}`}>
														{importedLink[field]}
													</td>
												))}
											</tr>
										);
									})}
							</tbody>
						</Styled.LinksTable>
					</Styled.AutoSizeRow>
				</Styled.ImportPreview>
			) : (
				<>
					<FileUpload
						label="Select CSV file"
						disabled={false}
						withDragDrop={true}
						multiple={false}
						accept=".csv"
						onChange={handleFileChange}
					/>
					<Styled.Footer>
						<Button
							size="small"
							label={importInProgress ? "Uploading..." : "Upload"}
							disabled={importInProgress || !file}
							onClick={handleImportLinks}
						/>
					</Styled.Footer>
					<Styled.Help>
						<a href={helpUrl} target="_blank" rel="noopener noreferrer">
							Learn how to use the import/export feature.
						</a>
					</Styled.Help>
				</>
			)}
		</Styled.Form>
	);
};

export default ImportLinks;

import React, {useEffect, useCallback, useState, useRef} from "react";
import debounce from "lodash.debounce";

import envalid from "src/react-app-envalid";

import {ThemeProvider} from "emotion-theming";
import {ActionArea} from "@zmags/zmags-ui-library";

import EnrichmentTheme from "./theme";
import {AppWrapper} from "./components/AppWrapper";
import {TopNavigationSchemaIdConstants} from "./components/TopNavigation/constants";
import {ToolsSchemaIdConstants} from "./components/Tools/constants";
import {TopNavigation} from "./components/TopNavigation";
import {LinkPanel} from "./components/formPanels/LinkPanel";
import WidgetPanel from "./components/formPanels/WidgetPanel";
import {TooltipManager} from "./components/tooltipManager/TooltipManager";
import {AutoLinksManager} from "./components/autoLinksManager/AutoLinksManager";
import NavigatorPanel from "./components/navigatorPanel/NavigatorPanel";
import {Preloader} from "./components/preloader/Preloader";
import {ImportExportLinks} from "./components/importExportLinks/ImportExportLinks";
import {Tools} from "./components/Tools";
import {useImage} from "./hooks/useImage";
import {useWidgets} from "./hooks/useWidgets";
import {useLinks} from "./hooks/useLinks";
import {useShapes} from "./hooks/useShapes";
import {useImportLinks} from "./hooks/useImportLinks";
import {useCommonData} from "./hooks/useCommonData";
import {useEditorSettings} from "./hooks/useEditorSettings";
import {usePublicationSettings} from "./hooks/usePublicationSettings";
import {useFileMenu} from "./hooks/topNavigation/useFileMenu";
import {useEditMenu} from "./hooks/topNavigation/useEditMenu";
import {useWindowMenu} from "./hooks/topNavigation/useWindowMenu";
import {useTooltips} from "./hooks/useTooltips";
import {useHelpMenu} from "./hooks/topNavigation/useHelpMenu";
import {useSelectMenu} from "./hooks/topNavigation/useSelectMenu";
import {useApplicationState} from "./hooks/useApplicationState";
import {usePublicationId} from "./hooks/usePublicationId";
import {useHotKeys} from "./hooks/useHotKeys";
import {DrawerModeProperties} from "./models/Common";
import {WidgetNodes, Widget} from "./models/Widgets";
import {LinkNodes, Link} from "./models/Links";
import {Shape} from "./models/Shapes";

import {
	LINK,
	WIDGET,
	BUY_THE_LOOK_WIDGET,
	PRODUCT_WIDGET,
	HTML5_WIDGET,
	VIDEO_WIDGET,
	WIDGETS_ARRAY,
	LINKS_ARRAY,
	WIDGET_SHAPE_SETTINGS,
	LINK_SHAPE_SETTINGS,
	INTERNAL_LINK_NODE,
	EXTERNAL_LINK_NODE,
	PRODUCT_LINK_NODE,
	NAVIGATOR_MIN_ZOOM,
	PAGE_BASE_SCALE,
} from "./constants";

const publicationPageUrl = envalid.getRequired(
	process.env,
	"REACT_APP_PUB_PAGE"
);

const {
	SAVE,
	PREVIEW,
	DELETE_ALL_WIDGETS,
	DELETE_ALL,
	DELETE_ALL_LINKS,
	EXIT,
	COPY,
	PASTE,
	SELECT_ALL,
	DESELECT,
	WINDOW,
	PAGE_WINDOW,
	NAVIGATOR,
	IMPORT_EXPORT_LINKS,
	AUTO_LINKS,
	TOOLTIP_MANAGER,
	HELP,
} = TopNavigationSchemaIdConstants;
const {
	SELECTION,
	BUY_THE_LOOK_POLYGON,
	BUY_THE_LOOK_RECTANGLE,
	PRODUCT_DETAILS_POLYGON,
	PRODUCT_DETAILS_RECTANGLE,
	HTML5_IFRAME_RECTANGLE,
	VIDEO_EMBED_RECTANGLE,
	INTERNAL_LINK,
	EXTERNAL_LINK,
	PRODUCT_LINK,
	SAVE_AND_PREVIEW,
} = ToolsSchemaIdConstants;

function App() {
	const containerRef = useRef<HTMLDivElement>(null);
	const {
		error,
		isLoading,
		loadingStatus,
		setError,
		setIsLoading,
		setLoadingStatus,
	} = useApplicationState();
	const {publicationId} = usePublicationId({setError, setIsLoading});
	const {
		tooltips,
		getTooltips,
		editTooltip,
		createTooltip,
		deleteTooltip,
	} = useTooltips(publicationId);
	const {settings, mediaMap} = useCommonData({
		publicationId,
		getCommonData: [getTooltips],
		setIsLoading,
		setLoadingStatus,
		setError,
	});
	const editorSettings = useEditorSettings();
	const [currentNodeType, setCurrentNodeType] = useState<
		WidgetNodes | LinkNodes | null
	>(null);

	const [strokeColor, setStrokeColor] = useState<string>("#ffffff");
	const [isHighlightColor, setIsHighlightColor] = useState<boolean>(false);

	const [drawerMode, changeDrawerMode] = useState<DrawerModeProperties>({
		type: undefined,
		opacity: 0.5,
		drawingOpacity: 0.5,
		fill: "red",
		drawingFill: "red",
		drawingStrokeColor: "red",
		stroke: strokeColor,
	});
	const [containerSize, setContainerSize] = useState<{
		width: number;
		height: number;
	}>({width: 0, height: 0});
	const [color, setColor] = useState<string | undefined>(undefined);
	const [alpha, setAlpha] = useState<number>(100);

	const [transformMode, changeTransformMode] = useState<boolean>(true);
	// The index of the page in pages array is started from 0
	const [publicationPageIdx, setPublicationPageIdx] = useState<number>(0);
	// The order of the page in the publication is started from the 1 page
	const [publicationPageNumber, setPublicationPageNumber] = useState<number>(1);
	const {publicationPageSettings} = usePublicationSettings({
		settings,
		publicationPageIdx,
	});
	const [zoom, setNavigatorZoom] = React.useState<number>(NAVIGATOR_MIN_ZOOM);
	const [scaleIndex, setScaleIndex] = React.useState<number>(
		PAGE_BASE_SCALE || 1
	);
	const [previewPosition, setPreviewPosition] = React.useState<Array<number>>([
		0,
		0,
		publicationPageSettings.previewHeight,
		publicationPageSettings.previewWidth,
		publicationPageSettings.previewHeight,
		publicationPageSettings.previewWidth,
		0,
		0,
	]);

	/** Page's Image hook - process image loading for certain page of publication*/
	const pageImage = useImage(publicationPageSettings, publicationPageUrl);

	/** Widget hook (Model). Here we keep and process all data for Widgets nodes */
	const {
		pageWidgets,
		updateWidgetPosition,
		widgetsWereCreated,
		widgetsWereChanged,
		widgetWereSelected,
		widgetsWereSelected,
		updateWidgetsWereSelected,
		updateWidget,
		saveWidgets,
		deleteWidgets,
		deleteSomeWidgets,
		createWidget,
		createWidgets,
	} = useWidgets(
		publicationId,
		publicationPageNumber,
		publicationPageSettings.obfuscatedCustomerID
	);

	/** Link hook (Model). Here we keep and process all data for Links nodes */
	const {
		pageLinks,
		linkWereSelected,
		linksWereCreated,
		linksWereChanged,
		linksWereSelected,
		updateLinksWereSelected,
		updateLinkPosition,
		updateLinksPosition,
		saveLinks,
		deleteLinks,
		deleteSomeLinks,
		createLink,
		createLinks,
		updateLink,
	} = useLinks(publicationId, publicationPageNumber, color, alpha);

	const {
		selectAll,
		deselect,
		changeColorForSelectedLinks,
		toggleNodeSelected,
	} = useSelectMenu({
		links: pageLinks,
		widgets: pageWidgets,
		widgetsWereCreated,
		widgetsWereSelected,
		linksWereCreated,
		linksWereSelected,
		deleteWidgets: deleteSomeWidgets,
		deleteLinks: deleteSomeLinks,
		updateLink,
		updateWidgetsWereSelected,
		updateLinksWereSelected,
	});

	/**
	 * Shape hook (Model).
	 * This hook strictly depends on Widgets and Links nodes.
	 * Returns new shapes every time we change either Link or Widget.
	 **/
	const {widgetShapes, linkShapes} = useShapes({
		pageWidgets,
		pageLinks,
		widgetsWereSelected,
		linksWereSelected,
	});

	const {
		saveAll,
		saveAllAndPreview,
		saveAllLinks,
		deleteAllWidgets,
		deleteAllLinks,
		deleteAll,
		exit,
	} = useFileMenu({
		obfuscatedPublicationID: publicationPageSettings.obfuscatedPublicationID,
		publicationPageNumber,
		widgetsWereChanged,
		widgetsWereCreated,
		linksWereChanged,
		linksWereCreated,
		saveLinks,
		saveWidgets,
		deleteWidgets,
		deleteLinks,
	});

	const {
		visibleAreas,
		switchActionArea,
		switchNavigator,
		activeEditor,
		setActiveEditor,
		switchImportExportLinks,
		switchAutoLinks,
		switchTooltipManager,
		windowKeepHighlight,
	} = useWindowMenu();
	const {helpUrl, openHelp} = useHelpMenu();

	const {copy, paste} = useEditMenu({
		links: pageLinks,
		linksWereSelected,
		createLinks,
		pageNumber: publicationPageNumber,
	});

	const handleColorChanged = useCallback(
		(color) => {
			setColor(color);
			changeColorForSelectedLinks(color);
		},
		[setColor, changeColorForSelectedLinks]
	);

	const turnOnTransformMode = useCallback(() => {
		changeDrawerMode({type: undefined});
		changeTransformMode(true);
	}, []);

	const handleShapeFocused = useCallback(
		({id, type, event}) => {
			const replaceAll = !event.shiftKey;
			toggleNodeSelected({
				id,
				type,
				replaceAll,
			});
		},
		[toggleNodeSelected]
	);

	const handleActionAreaClick = useCallback(
		({event, isClickedOnShape}) => {
			if (!event.shiftKey && !isClickedOnShape && transformMode) {
				deselect();
			}
		},
		[deselect, transformMode]
	);

	const handleShapeAdding = useCallback(
		({points, id}) => {
			if (currentNodeType) {
				if (WIDGETS_ARRAY.indexOf(currentNodeType) !== -1) {
					const nodeType = currentNodeType as WidgetNodes;
					createWidget({
						currentNodeType: nodeType,
						points,
						id,
						drawerType: drawerMode.type,
					});
				}
				if (LINKS_ARRAY.indexOf(currentNodeType) !== -1) {
					const nodeType = currentNodeType as LinkNodes;
					createLink({currentNodeType: nodeType, points, id});
				}
				turnOnTransformMode();
			}
		},
		[currentNodeType, createWidget, createLink, drawerMode, turnOnTransformMode]
	);

	const handleShapeChanging = useCallback(
		(shape) => {
			const {node} = shape.type;
			if (node === WIDGET) {
				updateWidgetPosition(shape);
			}
			if (node === LINK) {
				updateLinkPosition(shape);
			}
		},
		[updateWidgetPosition, updateLinkPosition]
	);

	const handleWindowResize = () => {
		if (containerRef.current) {
			const {clientHeight: height, clientWidth: width} = containerRef.current;
			setContainerSize({width, height});
		}
	};

	const createShapes = useCallback(
		(shapes: Array<Link | Widget>) => {
			const widgets = shapes.filter((shape): boolean =>
				WIDGETS_ARRAY.includes(shape._type_)
			) as Array<Widget>;
			if (widgets.length) {
				createWidgets(widgets);
			}
			const links = shapes.filter((shape) =>
				LINKS_ARRAY.includes(shape._type_)
			) as Array<Link>;
			if (links.length) {
				createLinks(links);
			}
		},
		[createWidgets, createLinks]
	);

	const handleDragSelect = useCallback(
		(shapes) => {
			let widgets = new Map();
			let links = new Map();
			shapes.forEach((shape: any) => {
				const type = shape.type;
				if (type && "node" in type && "subNode" in type) {
					switch (shape?.type.node) {
						case WIDGET:
							widgets.set(shape.id, {
								node: shape.type.node,
								subNode: shape.type.subNode,
							});
							break;
						case LINK:
							links.set(shape.id, {
								node: shape.type.node,
								subNode: shape.type.subNode,
							});
							break;
						default:
							break;
					}
				}
			});
			updateLinksWereSelected(new Map(links));
			updateWidgetsWereSelected(new Map(widgets));
		},
		[updateLinksWereSelected, updateWidgetsWereSelected]
	);

	const createShapesAndSave = useCallback(
		(shapes: Array<Link | Widget>) => {
			createShapes(shapes);
		},
		[createShapes]
	);

	const handleLinkChange = useCallback(
		(id: string | number, type: LinkNodes, values: Link) => {
			updateLink(id, type, values);
			setAlpha(values?.alpha ?? 100);
		},
		[updateLink]
	);

	const {
		importInProgress,
		importedLinks,
		importedLinksPages,
		importLinks,
		cancelImport,
		exportLinkFields,
		placeAll,
		placeOnPage,
	} = useImportLinks(
		publicationId,
		settings,
		linksWereCreated,
		widgetsWereCreated,
		createShapes,
		createShapesAndSave
	);

	const moveSelectedShapes = useCallback(
		debounce((moveX: number, moveY: number) => {
			const selectedShapes = linkShapes.filter(
				(shape: Shape): boolean => shape.isShapeSelected
			);
			updateLinksPosition(selectedShapes, moveX, moveY);
		}, 0),
		[linkShapes, updateLinksPosition]
	);

	const {enrichedHotKeys} = useHotKeys({
		copy,
		paste,
		selectAll,
		moveSelectedShapes,
	});

	const menuEventHandlers = {
		/** "File" section */
		[SAVE]: saveAll,
		[PREVIEW]: saveAllAndPreview,
		[DELETE_ALL_WIDGETS]: deleteAllWidgets,
		[DELETE_ALL_LINKS]: deleteAllLinks,
		[DELETE_ALL]: deleteAll,
		[EXIT]: exit,

		/** "Edit" section */
		[COPY]: copy,
		[PASTE]: paste,

		/** "Select" section*/
		[SELECT_ALL]: () => {
			selectAll();
			turnOnTransformMode();
		},
		[DESELECT]: () => {
			deselect();
			changeTransformMode(false);
			changeDrawerMode({type: undefined});
		},

		/** "Window" section*/
		[PAGE_WINDOW]: switchActionArea,
		[NAVIGATOR]: switchNavigator,
		[IMPORT_EXPORT_LINKS]: switchImportExportLinks,
		[AUTO_LINKS]: switchAutoLinks,
		[TOOLTIP_MANAGER]: switchTooltipManager,

		/** "Help" section*/
		[HELP]: openHelp,
	};

	const toolsEventHandlers = {
		[SELECTION]: () => {
			turnOnTransformMode();
		},
		[BUY_THE_LOOK_POLYGON]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.polygon);
			setCurrentNodeType(BUY_THE_LOOK_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[BUY_THE_LOOK_RECTANGLE]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(BUY_THE_LOOK_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[PRODUCT_DETAILS_POLYGON]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.polygon);
			setCurrentNodeType(PRODUCT_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[PRODUCT_DETAILS_RECTANGLE]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(PRODUCT_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[HTML5_IFRAME_RECTANGLE]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(HTML5_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[VIDEO_EMBED_RECTANGLE]: () => {
			changeDrawerMode(WIDGET_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(VIDEO_WIDGET);
			changeTransformMode(false);
			deselect();
		},
		[PRODUCT_LINK]: () => {
			changeDrawerMode(LINK_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(PRODUCT_LINK_NODE);
			changeTransformMode(false);
			deselect();
		},
		[INTERNAL_LINK]: () => {
			changeDrawerMode(LINK_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(INTERNAL_LINK_NODE);
			changeTransformMode(false);
			deselect();
		},
		[EXTERNAL_LINK]: () => {
			changeDrawerMode(LINK_SHAPE_SETTINGS.rectangle);
			setCurrentNodeType(EXTERNAL_LINK_NODE);
			changeTransformMode(false);
			deselect();
		},
		[SAVE_AND_PREVIEW]: saveAllAndPreview,
	};

	const handleScroll = useCallback(
		(e) => {
			const {
				width,
				height,
				previewWidth,
				previewHeight,
			} = publicationPageSettings;
			const offsetX = e.target.scrollLeft;
			const offsetY = e.target.scrollTop;
			const dependsWidthIndex = (width * scaleIndex) / previewWidth;
			const dependsHeightIndex = (height * scaleIndex) / previewHeight;
			const previewAreaWidth = previewPosition[6] - previewPosition[0];
			const previewAreaHeight = previewPosition[3] - previewPosition[1];
			const newX = offsetX / (dependsWidthIndex || 1);
			const newY = offsetY / (dependsHeightIndex || 1);
			setPreviewPosition([
				Math.round(newX),
				Math.round(newY),
				Math.round(newX),
				Math.round(newY + previewAreaHeight),
				Math.round(newX + previewAreaWidth),
				Math.round(newY + previewAreaHeight),
				Math.round(newX + previewAreaWidth),
				Math.round(newY),
			]);
		},
		[previewPosition, setPreviewPosition, publicationPageSettings, scaleIndex]
	);

	useEffect(() => {
		setPublicationPageNumber(publicationPageIdx + 1);
	}, [publicationPageIdx]);

	const onPositionChange = useCallback(
		(position) => {
			const [x, y] = position;
			const {
				width,
				height,
				previewWidth,
				previewHeight,
			} = publicationPageSettings;

			if (containerRef.current) {
				const dependsWidthIndex = width / previewWidth;
				const dependsHeightIndex = height / previewHeight;
				containerRef.current.scroll({
					left: x * dependsWidthIndex * scaleIndex,
					top: y * dependsHeightIndex * scaleIndex,
				});
			}
			setPreviewPosition(position);
		},
		[publicationPageSettings, setPreviewPosition, containerRef, scaleIndex]
	);

	useEffect(() => {
		if (linkWereSelected) {
			setActiveEditor(undefined);
		}
	}, [linkWereSelected, setActiveEditor]);

	useEffect(() => {
		if (widgetWereSelected) {
			setActiveEditor(undefined);
		}
	}, [widgetWereSelected, setActiveEditor]);

	useEffect(() => {
		if (activeEditor) {
			deselect();
		}
	}, [activeEditor, deselect]);

	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	useEffect(handleWindowResize, [pageImage, publicationPageIdx]);

	return (
		<ThemeProvider theme={EnrichmentTheme}>
			<AppWrapper theme={EnrichmentTheme}>
				{error && <div className="loading">{error}</div>}
				{<Preloader status={loadingStatus} isLoading={isLoading} />}
				{!isLoading && !error && (
					<>
						<header>
							<TopNavigation
								keepHighlight={[WINDOW]}
								active={windowKeepHighlight}
								menuEventHandlers={menuEventHandlers}
							/>
						</header>
						<div className="content">
							<div className="toolbox">
								<div>
									<Tools
										color={color}
										onColorChanged={handleColorChanged}
										menuEventHandlers={toolsEventHandlers}
										transformMode={transformMode}
									/>
								</div>
							</div>
							<div
								className={`body ${drawerMode?.type ? "draw" : ""}`}
								ref={containerRef}
								onKeyDown={enrichedHotKeys}
								onScroll={handleScroll}
							>
								{pageImage === undefined && (
									<div className="loading">Preparing enrichments...</div>
								)}
								{pageImage && visibleAreas[PAGE_WINDOW] && (
									<ActionArea
										transformRotateEnabled={!!linkWereSelected}
										transformMode={
											transformMode ? "fullTransformation" : undefined
										}
										scaleIndex={scaleIndex}
										width={Math.trunc((pageImage?.width || 0) * scaleIndex)}
										height={Math.trunc((pageImage?.height || 0) * scaleIndex)}
										shapes={[...widgetShapes, ...linkShapes]}
										opacity={0.3}
										strokeWidth={1}
										drawingStrokeWidth={1}
										drawingStrokeColor={drawerMode.drawingStrokeColor}
										drawingFillColor={drawerMode.drawingFill}
										drawingOpacity={drawerMode.drawingOpacity}
										fill={drawerMode.fill}
										stroke={
											isHighlightColor
												? strokeColor || drawerMode.stroke
												: "transparent"
										}
										drawMode={drawerMode.type}
										image={pageImage}
										fitImageToStage={true}
										onShapeAdded={handleShapeAdding}
										onShapeChanged={handleShapeChanging}
										onShapeFocused={handleShapeFocused}
										onClick={handleActionAreaClick}
										onDragSelect={handleDragSelect}
										transformKeepRatio={
											linkWereSelected
												? linkWereSelected.constrainProportion
												: false
										}
									/>
								)}
							</div>

							<div className="sidebar">
								{settings && visibleAreas[NAVIGATOR] && (
									<NavigatorPanel
										publicationPageIdx={publicationPageIdx}
										publicationUrl={publicationPageUrl}
										publicationSettings={settings}
										supportsZoom={true}
										zoom={zoom}
										color={strokeColor}
										isHighlightColor={isHighlightColor}
										containerWidth={containerSize.width}
										containerHeight={containerSize.height}
										imageWidth={publicationPageSettings.previewWidth}
										imageHeight={publicationPageSettings.previewHeight}
										sourceImageWidth={publicationPageSettings.width}
										sourceImageHeight={publicationPageSettings.height}
										baseScaleIndexOfSourceImage={PAGE_BASE_SCALE}
										onChangeColor={setStrokeColor}
										onChangePage={setPublicationPageIdx}
										onChangeIsHighlightColor={setIsHighlightColor}
										previewPosition={previewPosition}
										onChangeZoom={setNavigatorZoom}
										onChangePreviewPosition={onPositionChange}
										onChangeScaleIndex={setScaleIndex}
									/>
								)}
								{settings && publicationId && linkWereSelected && (
									<LinkPanel
										publicationID={publicationId}
										link={linkWereSelected}
										publicationSettings={settings}
										editorSettings={editorSettings}
										mediaMap={mediaMap}
										tooltips={tooltips}
										onChange={handleLinkChange}
										onPanelFocus={turnOnTransformMode}
									/>
								)}
								{widgetWereSelected && (
									<WidgetPanel
										widget={widgetWereSelected}
										mediaMap={mediaMap}
										onChange={updateWidget}
										onPanelFocus={turnOnTransformMode}
									/>
								)}
								{activeEditor === IMPORT_EXPORT_LINKS &&
									!!publicationId &&
									!!settings && (
										<ImportExportLinks
											publicationId={publicationId}
											publicationSettings={settings}
											initialPageIdx={0}
											helpUrl={helpUrl}
											beforeExport={saveAllLinks}
											importInProgress={importInProgress}
											exportLinkFields={exportLinkFields}
											importedLinks={importedLinks}
											// navigateToPage={setPublicationPageIdx}
											pages={importedLinksPages}
											importLinks={importLinks}
											cancelImport={cancelImport}
											placeAll={placeAll}
											placeOnPage={placeOnPage}
										/>
									)}
								{activeEditor === TOOLTIP_MANAGER && (
									<TooltipManager
										tooltips={tooltips}
										editTooltip={editTooltip}
										createTooltip={createTooltip}
										deleteTooltip={deleteTooltip}
									/>
								)}
								{activeEditor === AUTO_LINKS && !!settings && publicationId && (
									<AutoLinksManager
										publicationId={publicationId}
										currentPage={publicationPageNumber}
										editorSettings={editorSettings}
										publicationSettings={settings}
									/>
								)}
							</div>
						</div>
						<footer>
							<p>Copyright © {new Date().getFullYear()} Zmags Corp</p>
						</footer>
					</>
				)}
			</AppWrapper>
		</ThemeProvider>
	);
}

export default App;

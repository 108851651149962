import {Product} from "src/models/Product";

export type LinkNodes = "ExternalLink" | "InternalLink" | "WishListLink";

export enum TooltipType {
	/**
	 * Text media tooltips shows.
	 */
	Text = 0,
}

export enum Stretch {
	/**
	 * No stretch.
	 */
	None = 0,
	/**
	 * Stretch horizontally. Takes up all horizontal space available.
	 */
	Horizontal = 1,
	/**
	 * Stretch vertically. Takes up all vertical space available.
	 */
	Vertical = 2,
	/**
	 * Stretch horizontally and vertically. Takes up all space available.
	 */
	Both = 3,
}

export enum HorizontalAlignment {
	/**
	 * No alignment.
	 */
	None = 0,

	/**
	 * Align to the left.
	 */
	Left = 1,

	/**
	 * Align to the center.
	 */
	Center = 2,

	/**
	 * Align to the right.
	 */
	Right = 3,
}

export enum VerticalAlignment {
	/**
	 * No alignment.
	 */
	None = 0,

	/**
	 * Align to the top.
	 */
	Top = 1,

	/**
	 * Align to the middle.
	 */
	Middle = 2,

	/**
	 * Align to the bottom.
	 */
	Bottom = 3,
}

export enum Effect {
	/**
	 * No effect.
	 */
	None = 0,

	/**
	 * Fade in the out.
	 */
	FADE_INOUT = 1,

	/**
	 * Continually fade in and out.
	 */
	PULSATING = 2,

	/**
	 * Frames the link with a shadow.
	 */
	SHADOW_FRAME = 3,

	/**
	 * Frames the link.
	 */
	FRAME = 4,
}

export enum Target {
	NewWindowOrTab = "_blank",
	CurrentOrTab = "_self",
	ParentFrame = "_parent",
	LightBox = "_ZMAGS_LIGHTBOX",
	Custom = "-1",
}

export interface BaseLink {
	_type_: LinkNodes;
	id: string | number;
	x: number;
	y: number;
	width: number;
	height: number;
	tooltip?: string;
	tooltipMediaDescription?: string;
	tooltipMediaSource?: string | null;
	tooltipMediaTitle?: string;
	alpha?: number;
	externalSource?: string | null;
	publicationID?: {id: number};
	pageNumber?: number;
	fromPageNumber?: number;
	toPageNumber?: number;
	stateScope?: number;
	color?: number;
	rotation?: number;
	tooltipType?: TooltipType;
	effect: Effect;
	stretch: Stretch;
	horizontalAlignment: HorizontalAlignment;
	verticalAlignment: VerticalAlignment;
	constrainProportion: boolean;
}

export interface ProductLink extends BaseLink, Product {
	hasProduct?: boolean;
	useProductDatabase?: boolean;
}

export interface InternalLink extends BaseLink {
	targetPageNumber: number;
}

export interface ExternalLink extends BaseLink {
	target: Target;
	url?: string;
	lightBox?: boolean;
	lightBoxHeight?: number | null;
	lightBoxWidth?: number | null;
}

export type Link = ProductLink | ExternalLink | InternalLink;

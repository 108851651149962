import {useEffect, useState} from "react";
import {PublicationSettings} from "../models/PublicationSettings";

const usePublicationSettings = ({
	settings,
	publicationPageIdx,
}: {
	settings: PublicationSettings | undefined;
	publicationPageIdx: number;
}) => {
	const [publicationPageSettings, setPublicationPageSettings] = useState<{
		pageID: string;
		obfuscatedPublicationID: string;
		obfuscatedCustomerID: string;
		height: number;
		width: number;
		previewHeight: number;
		previewWidth: number;
	}>({
		pageID: "",
		obfuscatedPublicationID: "",
		obfuscatedCustomerID: "",
		height: 0,
		width: 0,
		previewHeight: 0,
		previewWidth: 0,
	});
	/**
	 * Fetch data for particular page
	 */
	useEffect(() => {
		if (settings) {
			const {pageID} = settings?.pages[publicationPageIdx];
			setPublicationPageSettings({
				pageID,
				height: settings?.zoomPageHeight,
				width: settings?.zoomPageWidth,
				previewHeight: settings
					? Math.trunc(settings.zoomPageHeight / (settings.zoomPageWidth / 127))
					: 136,
				previewWidth: 127,
				obfuscatedPublicationID: settings?.obfuscatedPublicationID,
				obfuscatedCustomerID: settings?.obfuscatedCustomerID,
			});
		}
	}, [publicationPageIdx, settings]);

	return {publicationPageSettings};
};

export {usePublicationSettings};

import React from "react";

// Components
import {ProductLinkPropertiesForm} from "src/components/productLinkPropertiesForm/ProductLinkPropertiesForm";
import {LinkProperties} from "src/components/linkProperties/LinkProperties";

// Models
import {BaseLink, ProductLink, LinkNodes} from "src/models/Links";
import {EditorSettings} from "src/models/EditorSettings";
import {Media} from "src/models/Medias";
import {LinkTooltip} from "src/models/LinkTooltip";
import {PRODUCT_LINK_NODE} from "src/constants";

// hooks
import {useProduct} from "src/hooks/useProduct";

export interface ProductLinkPropertiesProps {
	initialValues: ProductLink;
	editorSettings: EditorSettings;
	mediaMap: Media | undefined;
	tooltips: LinkTooltip[] | undefined;
	onChange: (id: string | number, type: LinkNodes, link: ProductLink) => void;
}

export const ProductLinkProperties: React.FC<ProductLinkPropertiesProps> = (
	props
) => {
	const {initialValues, editorSettings, mediaMap, tooltips, onChange} = props;
	const {searchingProduct, searchProduct} = useProduct();

	function handleLinkChanged(link: BaseLink) {
		onChange(link.id, PRODUCT_LINK_NODE, link as ProductLink);
	}

	async function handleSearchProduct() {
		const product = await searchProduct(
			initialValues.magid,
			initialValues.productID
		);
		if (product) {
			handleLinkChanged({...initialValues, ...product});
		}
	}

	return (
		<div>
			<ProductLinkPropertiesForm
				initialValues={initialValues}
				searchingProduct={searchingProduct}
				searchProduct={handleSearchProduct}
				onSubmit={handleLinkChanged}
			/>
			<LinkProperties
				initialValues={initialValues as BaseLink}
				editorSettings={editorSettings}
				onSubmit={handleLinkChanged}
				mediaMap={mediaMap}
				tooltips={tooltips}
			/>
		</div>
	);
};

export default ProductLinkProperties;

import React from "react";

// Components
import {ToolPanel} from "src/components/toolPanel/ToolPanel";
import {ExportLinks} from "./ExportLinks";
import {ImportLinks} from "./ImportLinks";

// Models
import {PublicationSettings} from "src/models/PublicationSettings";
import {ImportedLink} from "src/models/ImportedLinks";

// hooks
import {PaginationPage} from "src/hooks/usePagination";

export interface ImportExportLinksProps {
	publicationId: number;
	publicationSettings: PublicationSettings;
	initialPageIdx: number;
	helpUrl: string;
	beforeExport?: () => void;
	importInProgress: boolean;
	importedLinks?: ImportedLink[];
	pages: PaginationPage[];
	navigateToPage?: (pageIdx: number) => void;
	importLinks: (file: File) => Promise<void>;
	cancelImport: () => void;
	exportLinkFields: Map<keyof ImportedLink, string>;
	placeAll: () => void;
	placeOnPage: (pageNumber: number) => void;
}

export const ImportExportLinks: React.FC<ImportExportLinksProps> = (props) => {
	const {
		publicationId,
		publicationSettings,
		initialPageIdx,
		helpUrl,
		beforeExport,
		importInProgress,
		importedLinks,
		pages,
		navigateToPage,
		importLinks,
		cancelImport,
		exportLinkFields,
		placeAll,
		placeOnPage,
	} = props;
	return (
		<>
			<ToolPanel title="Import Links from CSV File">
				<ImportLinks
					initialPageIdx={initialPageIdx}
					helpUrl={helpUrl}
					importInProgress={importInProgress}
					exportLinkFields={exportLinkFields}
					importedLinks={importedLinks}
					pages={pages}
					navigateToPage={navigateToPage}
					importLinks={importLinks}
					cancelImport={cancelImport}
					placeAll={placeAll}
					placeOnPage={placeOnPage}
				/>
			</ToolPanel>
			<ToolPanel title="Export Links to CSV File">
				<ExportLinks
					publicationId={publicationId}
					publicationSettings={publicationSettings}
					beforeExport={beforeExport}
				/>
			</ToolPanel>
		</>
	);
};

export default ImportExportLinks;

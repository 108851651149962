import merge from "lodash.merge";
import {darken, lighten} from "polished";
import {Themes} from "@zmags/zmags-ui-library";

type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};
const {defaultTheme} = Themes;
const generateCommonColorSpectrum = (
	color: string
): typeof defaultTheme.colors.primary => ({
	darker: darken(0.3, color),
	dark: darken(0.1, color),
	medium: color,
	light: lighten(0.1, color),
	lighter: lighten(0.3, color),
});
const theme: RecursivePartial<typeof defaultTheme> = {
	colors: {
		primary: generateCommonColorSpectrum("#957863"),
		secondary: generateCommonColorSpectrum("#8e889a"),
	},
};

const EnrichmentTheme = merge({}, defaultTheme, theme);
export default EnrichmentTheme;

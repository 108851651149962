import React, {useState, useCallback} from "react";
import {useToasts} from "react-toast-notifications";
import {UpdateParams, StateArray} from "../../models/Common";
import envalid from "../../react-app-envalid";
import {
	STATUS_EMPTY_NODES,
	STATUS_FULFILLED,
	STATUS_PARTIAL_REJECTED,
	STATUS_REJECTED,
} from "../../constants";
import {processingNodesRequest} from "../../utilities";

const env: NodeJS.ProcessEnv = process.env;
const REACT_APP_VIEWER_BASE_URL = envalid.get(env, "REACT_APP_VIEWER_BASE_URL");
const REACT_APP_BASE_API_URL = envalid.getRequired(
	env,
	"REACT_APP_BASE_API_URL"
);

const useFileMenu = ({
	widgetsWereChanged,
	widgetsWereCreated,
	linksWereChanged,
	linksWereCreated,
	saveLinks,
	saveWidgets,
	deleteWidgets,
	deleteLinks,
	obfuscatedPublicationID,
	publicationPageNumber,
}: {
	widgetsWereChanged: StateArray;
	widgetsWereCreated: StateArray;
	linksWereChanged: StateArray;
	linksWereCreated: StateArray;
	obfuscatedPublicationID: string;
	publicationPageNumber: number;
	deleteWidgets: () => Promise<number>;
	deleteLinks: () => Promise<number>;
	saveLinks: (param: Array<UpdateParams>) => Promise<any>;
	saveWidgets: (param: Array<UpdateParams>) => Promise<any>;
}) => {
	const {addToast} = useToasts();
	const [pendingExecution, setPendingExecution] = useState(false);

	/**
	 * Saving stuff
	 */

	const saveLinksNodes = useCallback(() => {
		/** We need to save all Links - either modified or newly created */
		const linksToSave: Array<UpdateParams> = [];

		const createdAndModifiedLinks = new Map([
			...linksWereChanged,
			...linksWereCreated,
		]);

		Array.from(createdAndModifiedLinks.keys()).forEach((linkId) =>
			linksToSave.push({id: linkId, isNew: linksWereCreated.has(linkId)})
		);

		return Promise.resolve({
			links: {
				length: linksToSave.length,
				nodesToProcess: saveLinks(linksToSave),
			},
		});
	}, [linksWereChanged, linksWereCreated, saveLinks]);

	const saveAllNodes = useCallback(() => {
		/** We need to save all Widgets and Links - either modified or newly created */
		const widgetsToSave: Array<UpdateParams> = [];
		const linksToSave: Array<UpdateParams> = [];

		const createdAndModifiedWidgets = new Map([
			...widgetsWereChanged,
			...widgetsWereCreated,
		]);

		const createdAndModifiedLinks = new Map([
			...linksWereChanged,
			...linksWereCreated,
		]);
		Array.from(createdAndModifiedWidgets.keys()).forEach((widgetId) =>
			widgetsToSave.push({
				id: widgetId,
				isNew: widgetsWereCreated.has(widgetId),
			})
		);

		Array.from(createdAndModifiedLinks.keys()).forEach((linkId) =>
			linksToSave.push({id: linkId, isNew: linksWereCreated.has(linkId)})
		);

		return Promise.resolve({
			widgets: {
				length: widgetsToSave.length,
				nodesToProcess: saveWidgets(widgetsToSave),
			},
			links: {
				length: linksToSave.length,
				nodesToProcess: saveLinks(linksToSave),
			},
		});
	}, [
		widgetsWereChanged,
		widgetsWereCreated,
		linksWereChanged,
		linksWereCreated,
		saveLinks,
		saveWidgets,
	]);

	const exit = () => {
		window.location.href = `${REACT_APP_BASE_API_URL}/logout.do`;
	};

	const processRedirect = useCallback(() => {
		window.open(
			// eslint-disable-next-line max-len
			`${REACT_APP_VIEWER_BASE_URL}/publication/${obfuscatedPublicationID}?viewType=pubPreview&page=${publicationPageNumber}`,
			"_blank"
		);
	}, [obfuscatedPublicationID, publicationPageNumber]);

	/** ############################
	 * PUBLIC METHODS
	 * ############################# */
	function withLock<T>(fn: () => Promise<T>): () => Promise<T | undefined> {
		return async (): Promise<T | undefined> => {
			if (pendingExecution) {
				return undefined;
			}

			try {
				setPendingExecution(true);
				return await fn();
			} finally {
				setPendingExecution(false);
			}
		};
	}

	const deleteAllWidgets = async (): Promise<void> => {
		try {
			const totalNodes = await deleteWidgets();
			switch (totalNodes) {
				case 0:
					addToast("No widgets to delete.", {
						autoDismiss: true,
						appearance: "info",
					});
					break;
				default:
					addToast("All widgets were deleted.", {
						autoDismiss: true,
						appearance: "success",
					});
					break;
			}
		} catch {
			addToast("Widgets were not deleted.", {
				autoDismiss: true,
				appearance: "error",
			});
		}
	};

	const deleteAllLinks = async (): Promise<void> => {
		try {
			const totalNodes = await deleteLinks();
			switch (totalNodes) {
				case 0:
					addToast("No links to delete.", {
						autoDismiss: true,
						appearance: "info",
					});
					break;
				default:
					addToast("All links were deleted.", {
						autoDismiss: true,
						appearance: "success",
					});
					break;
			}
		} catch {
			addToast("Links were not deleted.", {
				autoDismiss: true,
				appearance: "error",
			});
		}
	};

	const deleteAll = async (): Promise<void> => {
		await Promise.allSettled([deleteAllLinks(), deleteAllWidgets()]);
	};

	const saveAllLinks = async (): Promise<void> => {
		const result = await saveLinksNodes();
		const {status, rejectedLinks, totalLinks} = await processingNodesRequest(
			result
		);
		switch (status) {
			case STATUS_FULFILLED:
				addToast("All links were saved.", {
					autoDismiss: true,
					appearance: "success",
				});
				break;
			case STATUS_REJECTED:
				addToast("Error. Non of the links were saved.", {
					autoDismiss: true,
					appearance: "error",
				});
				break;
			case STATUS_PARTIAL_REJECTED:
				addToast(
					<div>
						Saving warning. Saved:
						<ul>
							<li>
								Links: {totalLinks - rejectedLinks}/{totalLinks}
							</li>
						</ul>
					</div>,
					{
						appearance: "warning",
					}
				);
				break;
			default:
				break;
		}
	};

	const saveAll = async (): Promise<void> => {
		const nodes = await saveAllNodes();
		const {
			status,
			rejectedLinks,
			rejectedWidgets,
			totalLinks,
			totalWidgets,
		} = await processingNodesRequest(nodes);
		switch (status) {
			case STATUS_EMPTY_NODES:
				addToast("Nothing to save, there are no current modifications.", {
					autoDismiss: true,
					appearance: "info",
				});
				break;
			case STATUS_FULFILLED:
				addToast("All nodes were saved.", {
					autoDismiss: true,
					appearance: "success",
				});
				break;
			case STATUS_REJECTED:
				addToast("Error. Nothing was saved.", {
					autoDismiss: true,
					appearance: "error",
				});
				break;

			case STATUS_PARTIAL_REJECTED:
				addToast(
					<div>
						Saving warning. Saved:
						<ul>
							<li>
								Widgets: {totalWidgets - rejectedWidgets}/{totalWidgets}
							</li>
							<li>
								Links: {totalLinks - rejectedLinks}/{totalLinks}
							</li>
						</ul>
					</div>,
					{
						appearance: "warning",
					}
				);
				break;
			default:
				break;
		}
	};

	const saveAllAndPreview = async (): Promise<void> => {
		const nodes = await saveAllNodes();
		const {
			status,
			rejectedLinks,
			rejectedWidgets,
			totalLinks,
			totalWidgets,
		} = await processingNodesRequest(nodes);
		switch (status) {
			case STATUS_EMPTY_NODES:
				addToast(
					"Nothing to save, there are no current modifications. Redirecting...",
					{
						autoDismiss: true,
						appearance: "info",
					}
				);
				processRedirect();
				break;
			case STATUS_FULFILLED:
				addToast("All nodes were saved. Redirecting....", {
					autoDismiss: true,
					appearance: "success",
				});
				setTimeout(processRedirect, 1500);
				break;
			case STATUS_REJECTED:
				addToast(
					<div>
						Error. Nothing was saved.
						<br />
						<a href="#" onClick={processRedirect}>
							Redirect anyway
						</a>
					</div>,
					{
						appearance: "error",
					}
				);
				break;

			case STATUS_PARTIAL_REJECTED:
				addToast(
					<div>
						Saving warning. Saved:
						<ul>
							<li>
								Widgets: {totalWidgets - rejectedWidgets}/{totalWidgets}
							</li>
							<li>
								Links: {totalLinks - rejectedLinks}/{totalLinks}
							</li>
						</ul>
						<a href="#" onClick={processRedirect}>
							Redirect anyway
						</a>
					</div>,
					{
						appearance: "warning",
					}
				);
				break;
			default:
				break;
		}
	};

	return {
		pendingExecution,
		saveAll: withLock(saveAll),
		saveAllAndPreview: withLock(saveAllAndPreview),
		saveAllLinks: withLock(saveAllLinks),
		deleteAllWidgets: withLock(deleteAllWidgets),
		deleteAllLinks: withLock(deleteAllLinks),
		deleteAll: withLock(deleteAll),
		exit,
	};
};

export {useFileMenu};

import React, {useState, useEffect} from "react";

// Components
import {AutoLinksForm} from "src/components/autoLinksForm/AutoLinksForm";
import {ToolPanel} from "src/components/toolPanel/ToolPanel";
import * as StyledForm from "src/utilities/form/StyledForm";

// Utils
import {
	SelectOption,
	getSelectorForCollection,
	getSelectorForCollectionByKey,
} from "src/utilities/form";

// Models
import {EditorSettings} from "src/models/EditorSettings";
import {
	PublicationSettings,
	PublicationSetting,
	Page,
} from "src/models/PublicationSettings";
import {useAutoLinks} from "src/hooks/useAutoLinks";

export interface AutoLinksProps {
	publicationId: number;
	currentPage: number;
	editorSettings: EditorSettings;
	publicationSettings: PublicationSettings;
}

export const AutoLinksManager: React.FC<AutoLinksProps> = (props) => {
	const {
		publicationId,
		currentPage,
		editorSettings,
		publicationSettings,
	} = props;
	const {autoLinks, loading, importAutoLinks} = useAutoLinks(
		publicationId,
		publicationSettings
	);
	const [target, setTarget] = useState<SelectOption<string>[]>([]);
	const [pages, setPages] = useState<SelectOption<number>[]>([]);
	const [effect, setEffect] = useState<SelectOption<number>[]>([]);

	useEffect(() => {
		const {pages: collection} = publicationSettings;
		setPages(
			getSelectorForCollection<Page, number>({
				collection,
				getValue: ({idx}) => idx + 1,
				getLabel: ({idx}) => `Page ${idx + 1}`,
			})
		);
	}, [publicationSettings]);

	useEffect(() => {
		setTarget(
			getSelectorForCollectionByKey<PublicationSetting<string>, string>({
				collection: editorSettings.target,
				valueKey: "value",
				labelKey: "displayName",
			})
		);

		setEffect(
			getSelectorForCollectionByKey<PublicationSetting<number>, number>({
				collection: editorSettings.effect,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
	}, [editorSettings]);

	return (
		<ToolPanel style={{width: "100%"}} title="Import links">
			<AutoLinksForm
				currentPage={currentPage}
				initialValues={autoLinks as any}
				options={{target, effect, pages}}
				onSubmit={importAutoLinks}
			/>
			{loading && (
				<StyledForm.Loading>
					<div className="loading">Searching for links. Please wait...</div>
				</StyledForm.Loading>
			)}
		</ToolPanel>
	);
};

export default AutoLinksManager;

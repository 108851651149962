import React from "react";

// Components
import {Button} from "@zmags/zmags-ui-library";
import * as Styled from "./ImportExportLinks.styles";

// Models
import {PublicationSettings} from "src/models/PublicationSettings";

// hooks
import {useExportLinks} from "src/hooks/useExportLinks";

export interface ExportLinksProps {
	publicationId: number;
	publicationSettings: PublicationSettings;
	beforeExport?: () => void;
}

export const ExportLinks: React.FC<ExportLinksProps> = (props) => {
	const {publicationId, publicationSettings, beforeExport} = props;
	const {exportLinks, inProgress} = useExportLinks(
		publicationId,
		publicationSettings.obfuscatedPublicationID,
		beforeExport
	);

	return (
		<div>
			<Styled.SubHeader>
				Click the button below to export all of the links in this publication to
				a CSV file.
			</Styled.SubHeader>
			<Styled.Footer>
				<Button
					onClick={exportLinks}
					size="small"
					label={inProgress ? "Exporting Links..." : "Export Links"}
					disabled={inProgress}
				/>
			</Styled.Footer>
		</div>
	);
};

export default ExportLinks;

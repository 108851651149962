import React, {useEffect, useState} from "react";
const usePublicationId = ({
	setError,
	setIsLoading,
}: {
	setError: React.Dispatch<React.SetStateAction<string | undefined>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [publicationId, setPublicationId] = useState<number | undefined>(
		undefined
	);
	useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const pubId: string | null = params.get("publicationID");
		if (pubId) {
			const id = parseInt(pubId);
			setPublicationId(id);
		} else {
			setError("Error. Missed publication id");
			setIsLoading(false);
		}
	}, [setError, setIsLoading]);
	return {
		publicationId,
	};
};
export {usePublicationId};

import React, {useCallback, useEffect} from "react";
import debounce from "lodash.debounce";

import {InjectedFormikProps} from "formik";

export type FormikForm<Props, Values> = React.ComponentType<
	InjectedFormikProps<Props, Values>
>;

export const withAutoCommit = <Props, Values>(
	Component: FormikForm<Props, Values>
): FormikForm<Props, Values> => (props: InjectedFormikProps<Props, Values>) => {
	const {initialValues, values, submitForm, setValues, dirty} = props;
	const debouncedSubmitForm = useCallback(
		debounce(() => {
			if (dirty) {
				// We do not need submit form until we change some field
				return submitForm();
			}
		}, 500),
		[submitForm, dirty]
	);

	useEffect(() => {
		debouncedSubmitForm();
		return debouncedSubmitForm.cancel;
	}, [values, debouncedSubmitForm]);

	useEffect(() => {
		if (initialValues) {
			setValues(initialValues, false);
		}
	}, [initialValues, setValues]);

	return React.createElement(Component, props);
};

export default withAutoCommit;

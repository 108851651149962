import axios, {AxiosResponse, AxiosError} from "axios";
import envalid from "src/react-app-envalid";

import {Response, ErrorResponse, ServerError} from "./types";

import withMockAdapter from "./mock/mockAdapter";

const env: NodeJS.AppEnv = process.env;
const REACT_APP_BASE_API_URL = envalid.getRequired(
	env,
	"REACT_APP_BASE_API_URL"
);
const REACT_APP_LOGIN_PAGE = envalid.get(env, "REACT_APP_LOGIN_PAGE");
const REACT_APP_MOCK_DATA_FILE = envalid.get(env, "REACT_APP_MOCK_DATA_FILE");
const REACT_APP_MOCK_ERRORS_MESSAGES = envalid.get(
	env,
	"REACT_APP_MOCK_ERRORS_MESSAGES"
);

const axiosInstance = axios.create({
	baseURL: REACT_APP_BASE_API_URL,
	withCredentials: true,
});

/**
 * Global handler for a specific HTTP response status code
 *
 * @param response AxiosResponse
 */
const handleResponseStatus = (response: AxiosResponse): string | undefined => {
	const data = response.data as ErrorResponse;
	const status = response.status;
	switch (status) {
		case 200:
			break;
		case 401:
			if (REACT_APP_LOGIN_PAGE) {
				window.location.replace(REACT_APP_LOGIN_PAGE);
			}
			break;
		case 500:
			return data?.message || "Internal server error";
		default:
			return data?.message;
	}
};

const onRejected = async (ex: AxiosError): Promise<ServerError> => {
	const {response, request} = ex;
	// client received an error response
	if (response) {
		const errorMessage = handleResponseStatus(response);
		return Promise.reject<ServerError>({message: errorMessage});
	}
	// client never received a response, or request never left
	if (request) {
		return Promise.reject<ServerError>({message: ex.message || ex.code});
	}
	// network error
	return Promise.reject<ServerError>({message: ex.message});
};

const onFulfilled = async (response: AxiosResponse): Promise<any> => {
	const errorMessage = handleResponseStatus(response);
	if (errorMessage) {
		return Promise.reject<ServerError>({message: errorMessage});
	}
	const data: Response = response.data;
	return Promise.resolve(data.value);
};

axiosInstance.interceptors.response.use(onFulfilled, onRejected);

/**
 * Mock Enrichments API.
 * If no mock data file specified, LIVE API expected to use.
 */
((dataPath?: string, errorsPath?: string) => {
	if (dataPath) {
		withMockAdapter(axiosInstance, dataPath, errorsPath);
	}
})(REACT_APP_MOCK_DATA_FILE, REACT_APP_MOCK_ERRORS_MESSAGES);

export default axiosInstance;

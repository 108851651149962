import {
	DrawerModeDesignProperties,
	DrawerModeProperties,
	NodeType,
} from "./models/Common";
import {
	BaseLink,
	LinkNodes,
	ProductLink,
	ExternalLink,
	InternalLink,
	Target,
	VerticalAlignment,
	HorizontalAlignment,
	Effect,
	Stretch,
	TooltipType,
} from "./models/Links";
import {WidgetNodes} from "./models/Widgets";
import {PointType, PossibleShapeTypes} from "./models/Shapes";
import {AutoLinks} from "./models/AutoLinks";

export const PAGE_BASE_SCALE = 0.5;
export const NAVIGATOR_MAX_ZOOM = 199;
export const NAVIGATOR_MIN_ZOOM = 100;
export const AXIOS_ABORTED_MESSAGE = "Aborted due to the next request";
export const STATUS_REJECTED = "status_rejected";
export const STATUS_FULFILLED = "status_fulfilled";
export const STATUS_PARTIAL_REJECTED = "status_partial_rejected";
export const STATUS_EMPTY_NODES = "status_empty_nodes";

export const COMMON_WIDGET_FIELDS = "default_widget_fields";
export const COMMON_LINK_FIELDS = "common_link_fields";
export const LINK: NodeType = "link";
export const WIDGET: NodeType = "widget";
export const EXTERNAL_LINK_NODE: LinkNodes = "ExternalLink";
export const INTERNAL_LINK_NODE: LinkNodes = "InternalLink";
export const PRODUCT_LINK_NODE: LinkNodes = "WishListLink";
export const PRODUCT_WIDGET: WidgetNodes =
	"ProductDetailsWidgetAMFExternalForm";
export const BUY_THE_LOOK_WIDGET: WidgetNodes =
	"BuyTheLookWidgetAMFExternalForm";
export const HTML5_WIDGET: WidgetNodes = "IframeWidgetAMFExternalForm";
export const VIDEO_WIDGET: WidgetNodes = "VideoEmbedWidgetAMFExternalForm";

export const POINT_TYPE: PointType = "ShapeCoordinateAMFExternalForm";
export const SHAPE_TYPE_POLYGON: PossibleShapeTypes = "PolygonAMFExternalForm";
export const SHAPE_TYPE_RECTANGLE: PossibleShapeTypes =
	"RectangleAMFExternalForm";

export const WIDGETS_ARRAY: Array<WidgetNodes | LinkNodes | undefined> = [
	PRODUCT_WIDGET,
	BUY_THE_LOOK_WIDGET,
	HTML5_WIDGET,
	VIDEO_WIDGET,
];

export const LINKS_ARRAY: Array<WidgetNodes | LinkNodes | undefined> = [
	EXTERNAL_LINK_NODE,
	INTERNAL_LINK_NODE,
	PRODUCT_LINK_NODE,
];

export const BASE_LINK_FIELDS_PRESET: BaseLink = {
	_type_: EXTERNAL_LINK_NODE,
	id: "",
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	pageNumber: 1,
	fromPageNumber: 1,
	toPageNumber: 1,
	tooltip: "",
	tooltipMediaDescription: "",
	tooltipMediaSource: null,
	tooltipMediaTitle: "",
	tooltipType: TooltipType.Text,
	rotation: 0,
	alpha: 100,
	stateScope: 1,
	stretch: Stretch.None,
	color: 4095, // #fff
	effect: Effect.None,
	externalSource: null,
	horizontalAlignment: HorizontalAlignment.None,
	verticalAlignment: VerticalAlignment.None,
	constrainProportion: false,
};

export const PRODUCT_LINK_FIELDS_PRESET: ProductLink = {
	...BASE_LINK_FIELDS_PRESET,
	_type_: PRODUCT_LINK_NODE,
	associatedSKU: "",
	hasProduct: false,
	itemName: "",
	price: 0,
	productID: "",
	useProductDatabase: true,
};

export const INTERNAL_LINK_FIELDS_PRESET: InternalLink = {
	...BASE_LINK_FIELDS_PRESET,
	_type_: INTERNAL_LINK_NODE,
	targetPageNumber: 1,
};

export const EXTERNAL_LINK_FIELDS_PRESET: ExternalLink = {
	...BASE_LINK_FIELDS_PRESET,
	_type_: EXTERNAL_LINK_NODE,
	target: Target.NewWindowOrTab,
	url: "//",
	lightBox: false,
	lightBoxHeight: null,
	lightBoxWidth: null,
};

export const LINK_FIELDS_PRESET: {
	[COMMON_LINK_FIELDS]: BaseLink;
	[PRODUCT_LINK_NODE]: ProductLink;
	[INTERNAL_LINK_NODE]: InternalLink;
	[EXTERNAL_LINK_NODE]: ExternalLink;
} = {
	[COMMON_LINK_FIELDS]: BASE_LINK_FIELDS_PRESET,
	[PRODUCT_LINK_NODE]: PRODUCT_LINK_FIELDS_PRESET,
	[INTERNAL_LINK_NODE]: INTERNAL_LINK_FIELDS_PRESET,
	[EXTERNAL_LINK_NODE]: EXTERNAL_LINK_FIELDS_PRESET,
};

export const WIDGET_FIELDS_PRESET = {
	[COMMON_WIDGET_FIELDS]: {},
	[PRODUCT_WIDGET]: {
		_type_: PRODUCT_WIDGET,
		shapeEnabled: true,
		productSourceType: 1,
		productID: "",
		productDetailsLabel: {
			_type_: "ProductDetailsWidgetLabelAMFExternalForm",
			shape: {
				width: 200,
				height: 100,
				x: 0,
				y: 0,
				rotation: 0,
				_type_: SHAPE_TYPE_RECTANGLE,
			},
			includeProductName: false,
			includeProductPrice: false,
		},
		productWindowLocationHint: {
			hint: 0,
			_type_: "ProductDetailsWidgetProductWindowLocationHintAMFExternalForm",
		},
		widgetButton: {
			_type_: "WidgetButtonAMFExternalForm",
			displayComponent: true,
			// TODO - back here when implement form windows for each type of node
			shape: {
				width: 60,
				height: 60,
				x: 0,
				y: 0,
				rotation: 0,
				_type_: SHAPE_TYPE_RECTANGLE,
			},
			text: "",
			tooltipText: "",
		},
	},
	[BUY_THE_LOOK_WIDGET]: {
		_type_: BUY_THE_LOOK_WIDGET,
		statisticsID: "",
		imageID: "",
		shapeEnabled: true,
		productSourceType: 1,
		productGrouping: {
			_type_: "ProductGroupingAMFExternalForm",
			description: "",
			// TODO - need to think if this can be set on BE
			// obfuscatedCustomerID: "5234f26c",
			obfuscatedCustomerID: "",
			products: [],
			title: "",
		},
		widgetButton: {
			_type_: "WidgetButtonAMFExternalForm",
			displayComponent: true,
			// TODO - back here when implement form windows for each type of node
			shape: {
				width: 60,
				height: 60,
				x: 0,
				y: 0,
				rotation: 0,
				_type_: SHAPE_TYPE_RECTANGLE,
			},
			text: "BUY THE LOOK",
			tooltipText: "",
		},
	},
	[HTML5_WIDGET]: {
		_type_: HTML5_WIDGET,
		link: "",
		fullPage: false,
		interactive: false,
	},
	[VIDEO_WIDGET]: {
		_type_: VIDEO_WIDGET,
		videoID: "",
		enableControls: true,
		autoplay: false,
		loop: false,
		type: 1,
	},
};
const WIDGET_SHAPE_DESIGN_SETTINGS: DrawerModeDesignProperties = {
	fill: "green",
	drawingFill: "black",
	drawingOpacity: 0.5,
	opacity: 0.5,
	drawingStrokeColor: "black",
	stroke: "black",
};
export const WIDGET_SHAPE_SETTINGS: {
	polygon: DrawerModeProperties;
	rectangle: DrawerModeProperties;
} = {
	polygon: {
		type: "polygon",
		...WIDGET_SHAPE_DESIGN_SETTINGS,
	},
	rectangle: {
		type: "rectangle",
		...WIDGET_SHAPE_DESIGN_SETTINGS,
	},
};

const LINK_SHAPE_DESIGN_SETTINGS: DrawerModeDesignProperties = {
	fill: "blue",
	drawingFill: "black",
	drawingOpacity: 0.5,
	opacity: 0.5,
	drawingStrokeColor: "black",
	stroke: "black",
};

export const LINK_SHAPE_SETTINGS: {rectangle: DrawerModeProperties} = {
	rectangle: {
		type: "rectangle",
		...LINK_SHAPE_DESIGN_SETTINGS,
	},
};

export const AUTO_LINK_PRESET: AutoLinks = {
	pdfLinks: false,
	specific: "",
	specificURL: "",
	webLinks: false,
	emailLinks: false,
	regex: "",
	regexLink: "",
	internalRegex: "",
	internalRegexPage: 1,
	productRegex: "",
	tooltipProduct: "",
	productSource: 2,
	allowInvalidProductSKU: false,
	tooltipExternal: "",
	tooltipInternal: "",
	tooltipMail: "",
	linkTarget: Target.NewWindowOrTab,
	isLightbox: false,
	lightBoxWidth: null,
	lightBoxHeight: null,
	transparency: 0,
	colour: 39270,
	effect: Effect.None,
	fromPage: 1,
	toPage: 1,
	publicationID: "",
};

export const VIDEO_EMBED_WIDGET_TYPE = [
	{value: 1, label: "Youtube"},
	{value: 2, label: "Vimeo"},
];

export const MIN_WIDGET_SIZE = 10;

import {ItemType} from "./types";
import {TopNavigationSchemaIdConstants} from "./constants";
const {
	FILE,
	EDIT,
	SELECT,
	WINDOW,
	HELP,
	SAVE,
	PREVIEW,
	DELETE,
	DELETE_ALL_LINKS,
	DELETE_ALL_WIDGETS,
	DELETE_ALL,
	EXIT,
	COPY,
	PASTE,
	SELECT_ALL,
	DESELECT,
	PAGE_WINDOW,
	NAVIGATOR,
	IMPORT_EXPORT_LINKS,
	AUTO_LINKS,
	TOOLTIP_MANAGER,
} = TopNavigationSchemaIdConstants;

const TopNavigationSchema: Array<ItemType> = [
	{
		id: FILE,
		title: "File",
		icon: "description",
		items: [
			{
				id: SAVE,
				title: "Save All",
				icon: "save",
			},
			{
				id: PREVIEW,
				title: "Save & Preview",
				icon: "preview",
			},
			{
				id: DELETE,
				title: "Delete",
				icon: "delete",
				items: [
					{
						id: DELETE_ALL_LINKS,
						title: "All Links",
						icon: "link_off",
					},
					{
						id: DELETE_ALL_WIDGETS,
						title: "All Widgets",
						icon: "layers_clear",
					},
					{
						id: DELETE_ALL,
						title: "All",
						icon: "delete_forever",
					},
				],
			},
			{
				id: EXIT,
				title: "Exit",
				icon: "exit_to_app",
			},
		],
	},
	{
		id: EDIT,
		title: "Edit",
		icon: "edit",
		items: [
			{
				id: COPY,
				title: "Copy",
				icon: "content_copy",
			},
			{
				id: PASTE,
				title: "Paste",
				icon: "content_paste",
			},
		],
	},
	{
		id: SELECT,
		title: "Select",
		icon: "select_all",
		items: [
			{
				id: SELECT_ALL,
				title: "All",
				icon: "check_box",
			},
			{
				id: DESELECT,
				title: "Deselect",
				icon: "check_box_outline_blank",
			},
		],
	},
	{
		id: WINDOW,
		title: "Window",
		icon: "tab",
		items: [
			{
				id: PAGE_WINDOW,
				title: "Page Window",
				icon: "tab",
			},
			{
				id: NAVIGATOR,
				title: "Navigator",
				icon: "fullscreen_exit",
			},
			{
				id: IMPORT_EXPORT_LINKS,
				title: "Import/Export Links",
				icon: "import_export",
			},
			{
				id: AUTO_LINKS,
				title: "Auto Links",
				icon: "link",
			},
			{
				id: TOOLTIP_MANAGER,
				title: "Tooltip Manager",
				icon: "construction",
			},
		],
	},
	{
		id: HELP,
		title: "Help",
		icon: "help",
	},
];

export default TopNavigationSchema;

import React, {useCallback, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

// Components
import * as StyledForm from "src/utilities/form/StyledForm";

// Types
import {InternalLink} from "src/models/Links";
import {SelectOption} from "src/utilities/form";

export interface FormValues extends InternalLink {}

export interface FormOptions {
	pages: SelectOption<number>[];
}

export interface FormOwnProps {
	/** Select options */
	options: FormOptions;
}

export type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, values, setFieldValue} = props;

	const handleDestinationPageChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>): void =>
			setFieldValue(
				"targetPageNumber",
				Number(
					event.currentTarget.options[event.currentTarget.selectedIndex].value
				)
			),
		[setFieldValue]
	);

	return (
		<FormikForm>
			{/** Destination page */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label>Destination page</StyledForm.Label>
				<StyledForm.Field>
					<Field
						as="select"
						options={options.pages}
						disabled={false}
						value={values.targetPageNumber}
						onChange={handleDestinationPageChange}
					>
						{options.pages.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="targetPageNumber" />
			</StyledForm.FieldWrapper>
		</FormikForm>
	);
};

export default Form;

import React from "react";

// Components
import * as Styled from "./ToolPanel.styles";

export interface ToolPanelProps {
	title?: string;
	children: React.ReactNode;
	style?: React.CSSProperties;
}

export const ToolPanel: React.FC<ToolPanelProps> = (props) => {
	const {title, children, style} = props;
	return (
		<Styled.Panel style={style}>
			{!!title && <Styled.Title>{title}</Styled.Title>}
			{children}
		</Styled.Panel>
	);
};

export default ToolPanel;

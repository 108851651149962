import React, {useEffect, useState} from "react";

// Components
import {Navigator} from "@zmags/zmags-ui-library";
import {Page} from "@zmags/zmags-ui-library/src/components/navigator/Navigator.types";
import {ToolPanel} from "src/components/toolPanel/ToolPanel";

// Models
import {PublicationSettings} from "src/models/PublicationSettings";
import {NAVIGATOR_MAX_ZOOM, NAVIGATOR_MIN_ZOOM} from "../../constants";

export interface NavigatorPanelProps {
	publicationPageIdx: number;
	publicationUrl: string;
	publicationSettings: PublicationSettings;
	supportsZoom: boolean;
	color: string;
	isHighlightColor: boolean;
	containerWidth: number;
	containerHeight: number;
	imageWidth: number;
	imageHeight: number;
	sourceImageWidth: number;
	sourceImageHeight: number;
	previewPosition: Array<number>;
	onChangePreviewPosition?: (position: Array<number>) => void;
	zoom: number;
	baseScaleIndexOfSourceImage: number;
	onChangeScaleIndex: (index: number) => void;
	onChangePage?: (pageIdx: number) => void;
	onChangeColor?: (color: string) => void;
	onChangeZoom?: (zoom: number) => void;
	onChangeIsHighlightColor?: (isHighlightColor: boolean) => void;
}

export const NavigatorPanel: React.FC<NavigatorPanelProps> = (props) => {
	const {
		publicationPageIdx,
		publicationUrl,
		publicationSettings,
		supportsZoom,
		color,
		isHighlightColor,
		imageWidth,
		imageHeight,
		sourceImageWidth,
		sourceImageHeight,
		containerWidth,
		containerHeight,
		onChangePage,
		onChangeZoom,
		onChangeColor,
		onChangeIsHighlightColor,
		previewPosition,
		onChangePreviewPosition,
		zoom,
		baseScaleIndexOfSourceImage,
		onChangeScaleIndex,
	} = props;
	const [pages, setPages] = useState<Page[]>([]);

	useEffect(() => {
		setPages(
			publicationSettings.pages.map(
				(page, id): Page => ({
					id: id,
					name: `Page ${id + 1}`,
					path: `${publicationUrl}?action=show&pageID=${page.pageID}&pageType=thumb`,
				})
			)
		);
	}, [publicationSettings.pages, publicationUrl]);

	if (!pages.length) {
		return null;
	}

	return (
		<ToolPanel title="Navigator" style={{width: "220px", zIndex: 2}}>
			<Navigator
				pages={pages}
				pageIdx={publicationPageIdx}
				color={color}
				initialIsHighlightColor={isHighlightColor}
				imageWidth={imageWidth}
				imageHeight={imageHeight}
				sourceImageWidth={sourceImageWidth}
				sourceImageHeight={sourceImageHeight}
				containerWidth={containerWidth}
				containerHeight={containerHeight}
				hasZoom={supportsZoom}
				zoom={zoom}
				zoomFrom={NAVIGATOR_MIN_ZOOM}
				zoomTo={NAVIGATOR_MAX_ZOOM}
				baseScaleIndexOfSourceImage={baseScaleIndexOfSourceImage}
				onChangePage={onChangePage}
				onChangeColor={onChangeColor}
				onChangeIsHighlightColor={onChangeIsHighlightColor}
				onChangeZoom={onChangeZoom}
				previewPosition={previewPosition}
				onChangePreviewPosition={onChangePreviewPosition}
				onChangeScaleIndex={onChangeScaleIndex}
			/>
		</ToolPanel>
	);
};

export default NavigatorPanel;

import {useState} from "react";
import {useToasts} from "react-toast-notifications";

// API
import api from "src/api/endpoints";

// Models
import {LinkTooltip} from "src/models/LinkTooltip";

const useTooltips = (publicationId: number | undefined) => {
	const {addToast} = useToasts();
	const [tooltips, setTooltips] = useState<LinkTooltip[] | undefined>(
		undefined
	);

	const editTooltip = async (tooltip: LinkTooltip) => {
		if (!publicationId) {
			return;
		}
		try {
			await api.saveTooltip(publicationId, tooltip.tooltip, tooltip.id);
			setTooltips(
				tooltips
					? tooltips.map(
							(t: LinkTooltip): LinkTooltip =>
								t.id === tooltip.id ? {...tooltip} : {...t}
					  )
					: [{...tooltip}]
			);
		} catch (error) {
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		}
	};

	const deleteTooltip = async (tooltip: LinkTooltip) => {
		if (!publicationId) {
			return;
		}
		try {
			await api.deleteTooltip(publicationId, tooltip.id);
			setTooltips(
				tooltips ? tooltips.filter((t: LinkTooltip) => t.id !== tooltip.id) : []
			);
		} catch (error) {
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		}
	};

	const createTooltip = async (tooltip: string) => {
		if (!tooltip || !publicationId) {
			return;
		}
		try {
			const id = await api.saveTooltip(publicationId, tooltip, null);
			const newTooltip: LinkTooltip = {id, type: "custom", tooltip};
			setTooltips(tooltips ? [...tooltips, newTooltip] : [newTooltip]);
		} catch (error) {
			addToast(error.message, {
				autoDismiss: true,
				appearance: "error",
			});
		}
	};

	const getTooltips = async () => {
		if (!publicationId) {
			return;
		}
		try {
			const collection = await api.getTooltips(publicationId);
			setTooltips(collection);
		} catch {
			setTooltips([]);
		}
	};

	return {tooltips, getTooltips, editTooltip, createTooltip, deleteTooltip};
};

export {useTooltips};

import styled from "@emotion/styled";
import EnrichmentTheme from "../../theme";

export const Panel = styled.div<{theme: typeof EnrichmentTheme}>`
	border: 1px solid ${({theme}) => theme.colors.primary.lighter};
	border-radius: ${({theme}) => theme.spacing.xxs};
	padding: ${({theme}) => theme.spacing.xs};
	position: relative;
	box-sizing: border-box;
`;

export const Title = styled.div<{theme: typeof EnrichmentTheme}>`
	padding-bottom: ${({theme}) => theme.spacing.xs};
	margin-bottom: ${({theme}) => theme.spacing.xxs};
	font-size: ${({theme}) => theme.typography.size.md};
	color: ${({theme}) => theme.colors.primary.dark};
	border-bottom: 1px solid ${({theme}) => theme.colors.primary.lighter};
`;

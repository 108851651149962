import styled from "@emotion/styled";
import EnrichmentTheme from "../../theme";

const SectionWrapper = styled.div`
	visibility: hidden;
	height: 0;
	&.active {
		visibility: visible;
		height: auto;
	}
`;

export const SectionContentWrapper = styled.div<{
	theme: typeof EnrichmentTheme;
}>`
	padding: ${({theme}) => theme.spacing.xs};
	border-top: 1px solid ${({theme}) => theme.colors.primary.lighter};
	margin-top: -1px;
	position: relative;
	h3 {
		font-size: ${({theme}) => theme.typography.size.sm};
		color: ${({theme}) => theme.colors.primary.medium};
	}
	.add {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: ${({theme}) => theme.spacing.md} 0;
	}
	.product_item {
		position: relative;
		margin-top: 40px;
		.controls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: absolute;
			top: -30px;
			width: 100%;
			.separator {
				border-bottom: 1px solid ${({theme}) => theme.colors.primary.medium};
				margin: 0 ${({theme}) => theme.spacing.md};
				height: 100%;
				width: 100%;
				align-self: center;
			}
		}
	}
`;

export const Select = styled.select`
	width: 100%;
	height: 22px;
	box-sizing: border-box;
`;

export const ProductSection = styled(SectionWrapper)``;
export const PropertiesSection = styled(SectionWrapper)``;

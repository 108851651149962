import React, {useEffect, useState} from "react";
import API from "../api/endpoints";
import {PublicationSettings} from "../models/PublicationSettings";
import {Media} from "../models/Medias";
import {TotalEnrichments} from "../models/Common";
import {formatNumberTitle} from "../utilities";

const {getPublicationSettings, getMediaMap, getTotalEnrichments} = API;

const useCommonData = ({
	publicationId,
	getCommonData,
	setIsLoading,
	setLoadingStatus,
	setError,
}: {
	publicationId: number | undefined;
	getCommonData: Array<(publicationId: number) => Promise<any>>;
	setError: React.Dispatch<React.SetStateAction<string | undefined>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setLoadingStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
	const [totalEnricments, setTotalEnricments] = useState<
		TotalEnrichments | undefined
	>(undefined);
	const [settings, setSettings] = useState<PublicationSettings | undefined>(
		undefined
	);
	const [mediaMap, setMediaMap] = useState<Media | undefined>(undefined);

	useEffect(() => {
		(async function loadAllData() {
			if (!publicationId) {
				return;
			}
			try {
				setIsLoading(true);
				setLoadingStatus("Loading content from database");

				const newTotalEnricments = await getTotalEnrichments(publicationId);
				setTotalEnricments(newTotalEnricments);
				if (newTotalEnricments.totalLinks || newTotalEnricments.totalWidgets) {
					const totalLinksTitle = formatNumberTitle(
						newTotalEnricments.totalLinks,
						"link",
						"links"
					);
					const totalWidgetsTitle = formatNumberTitle(
						newTotalEnricments.totalWidgets,
						"widget",
						"widgets"
					);
					setLoadingStatus(
						`Preparing ${totalLinksTitle}${
							totalWidgetsTitle && totalLinksTitle
								? ` and ${totalWidgetsTitle}`
								: `${totalWidgetsTitle}`
						}`
					);
				}

				const publicationSettingsPromise: Promise<PublicationSettings> = getPublicationSettings(
					publicationId
				);
				const mediaMapPromise: Promise<Media> = getMediaMap(publicationId);
				const [newSettings, newMediaMap] = await Promise.all([
					publicationSettingsPromise,
					mediaMapPromise,
					...getCommonData.map((fn) => fn(publicationId)),
				]);
				setError(undefined);
				setSettings(newSettings);
				setMediaMap(newMediaMap);
			} catch ({message}) {
				setError(message);
			} finally {
				setIsLoading(false);
			}
		})();
		// Please DO NOT include getCommonData as dependency
	}, [publicationId, setError, setIsLoading, setLoadingStatus]);
	return {
		totalEnricments,
		settings,
		mediaMap,
	};
};

export {useCommonData};

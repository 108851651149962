import React from "react";
import ReactDOM from "react-dom";
import {Global, css} from "@emotion/core";
import {ToastProvider} from "react-toast-notifications";

import App from "./App";
import envalid from "src/react-app-envalid";

(function setAppLogo() {
	const icon = document.getElementById("icon") as HTMLLinkElement;
	if (icon) {
		const REACT_APP_BASE_API_URL = envalid.getRequired(
			process.env,
			"REACT_APP_BASE_API_URL"
		);
		icon.href = `${REACT_APP_BASE_API_URL}/favicon.ico`;
	}
})();

ReactDOM.render(
	<React.StrictMode>
		<Global
			styles={css`
				html,
				body,
				div,
				span {
					margin: 0;
					padding: 0;
					border: 0;
					outline: 0;
					font-size: 100%;
					background: transparent;
				}
				body,
				html,
				#root {
					height: 100%;
					min-height: 100%;
				}
				.react-toast-notifications__container > div {
					font-family: Arial, Helvetica, sans-serif;
					height: auto !important;
				}
				.toolbox > div > ul {
					align-items: center !important;
				}
			`}
		/>

		<ToastProvider placement="top-right">
			<App />
		</ToastProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

import React, {useCallback, useEffect, useState, createRef} from "react";
import debounce from "lodash.debounce";
import {Field} from "formik";
import axios, {Canceler} from "axios";

import * as StyledForm from "../../utilities/form/StyledForm";
import {useProduct} from "../../hooks/useProduct";
import {AXIOS_ABORTED_MESSAGE} from "../../constants";
const cancelToken = axios.CancelToken;
const cancelRef = createRef<Canceler | null>();
const ProductItem = ({
	product,
	index,
	publicationID,
	productSourceType,
}: {
	product: string;
	publicationID?: number;
	index: number;
	productSourceType: number;
}) => {
	const [status, setStatus] = useState<string | undefined>(undefined);
	const [title, setTitle] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [price, setPrice] = useState<string>("");
	const [isTouched, setIsTouched] = useState<boolean>(false);

	const {searchProduct} = useProduct();
	const getPlaceholder = useCallback(() => {
		return productSourceType === 2
			? "External (No information available)"
			: status;
	}, [productSourceType, status]);
	const setAdditionalFieldsValues = useCallback(
		({itemName = "", description = "", price = ""}) => {
			setTitle(itemName);
			setDescription(description);
			setPrice(price);
		},
		[]
	);
	const searchData = useCallback(
		debounce((publicationID, productID) => {
			if (productSourceType === 1) {
				searchProduct(
					publicationID,
					productID,
					new cancelToken(function executor(c: Canceler) {
						// @ts-ignore
						cancelRef.current = c;
					})
				).then((product) => {
					if (product) {
						setAdditionalFieldsValues(product);
					} else {
						setAdditionalFieldsValues({product});
						setStatus(undefined);
					}
				});
			} else {
				setAdditionalFieldsValues({});
			}
		}, 500),
		[productSourceType]
	);
	const handleFocus = useCallback(() => {
		if (productSourceType === 1) {
			setIsTouched(true);
		}
	}, [setIsTouched, productSourceType]);
	useEffect(() => {
		const cancel = cancelRef.current;
		if (isTouched && cancel) {
			cancel(AXIOS_ABORTED_MESSAGE);
		}
	}, [product, isTouched]);
	useEffect(() => {
		if (product && publicationID) {
			setStatus("Searching...");
			searchData(publicationID, product);
		}
	}, [product, publicationID, searchData]);

	return (
		<>
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor={`productGrouping.products.${index}`}>
					ProductID
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						onFocus={handleFocus}
						name={`productGrouping.products.${index}`}
						type="text"
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Name */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productName">Product Name</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productName"
						type="text"
						value={title}
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Description */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productDescription">
					Product Description
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productDescription"
						type="text"
						value={description}
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Price */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productPrice">
					Product Price
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productPrice"
						type="text"
						value={price}
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
		</>
	);
};

export default ProductItem;

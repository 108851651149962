import {useEffect, useState} from "react";

const useImage = (publicationPageSettings: any, publicationPageUrl: string) => {
	const [image, setPageImage] = useState<HTMLImageElement | undefined>(
		undefined
	);
	useEffect(() => {
		const {pageID, width, height} = publicationPageSettings;
		if (!pageID) {
			return;
		}
		setPageImage(undefined);
		const image = new window.Image();
		image.width = width;
		image.height = height;

		image.addEventListener("load", () => {
			setPageImage(image);
		});
		image.src = `${publicationPageUrl}?action=show&pageType=highresBitmap&pageID=${pageID}`;
	}, [publicationPageSettings, publicationPageUrl]);
	return image;
};

export {useImage};

export const get = function get<T>(env: T, varname: keyof T): T[keyof T] {
	return env[varname] as T[keyof T];
};

export const getRequired = function getRequired<T>(
	env: T,
	varname: keyof T
): string {
	const value = env[varname];
	if (value === undefined || value === null) {
		throw new Error(`Missing environment variable [${varname}]`);
	}
	return String(value);
};

export default {
	get,
	getRequired,
};

import {useEffect, useState, useCallback} from "react";
import merge from "lodash.merge";
import {AxiosResponse} from "axios";
import API from "../api/endpoints";
import {
	BUY_THE_LOOK_WIDGET,
	HTML5_WIDGET,
	PRODUCT_WIDGET,
	VIDEO_WIDGET,
	WIDGET,
	WIDGET_FIELDS_PRESET,
} from "../constants";

import {WidgetNodes, Widget} from "../models/Widgets";
import {UpdateParams, StateArray, DrawerType} from "../models/Common";
import {
	getShapeByType,
	getShapeByDrawerType,
	getMinXY,
} from "../utilities/shapes";
import {generateId} from "../utilities";
const {
	getWidgetsForPage,
	saveWidget: processSaveWidgets,
	deleteWidget: processDeleteWidget,
	deleteAllWidgets,
} = API;
const useWidgets = (
	publicationId: number | undefined,
	pageNumber: number,
	obfuscatedCustomerID: string
) => {
	const [widgets, setWidgets] = useState<Array<Widget> | null>(null);
	const [pageWidgets, setPageWidgets] = useState<Array<Widget> | null>(null);
	const [widgetsWereChanged, updateWidgetsWereChanged] = useState<StateArray>(
		new Map()
	);
	const [widgetsWereCreated, updateWidgetsWereCreated] = useState<StateArray>(
		new Map()
	);
	const [widgetsWereSelected, updateWidgetsWereSelected] = useState<StateArray>(
		new Map()
	);
	const [widgetWereSelected, setWidgetWereSelected] = useState<
		Widget | undefined
	>(undefined);

	/**
	 * This function will extend WidgetNode object with changed properties
	 * Except of positioning - for this purpose we have updateWidgetPosition
	 */
	const updateWidgetsProperties = useCallback(
		(widgetsMap: Map<string | number, Partial<Widget>>) => {
			if (widgets) {
				let tmpWidgets = [...widgets];
				widgetsMap.forEach((properties, id) => {
					const index: number = widgets.findIndex((widget) => widget.id === id);
					let tmpWidget = {...tmpWidgets[index]};
					if (index > -1) {
						tmpWidget = merge({}, tmpWidget, properties);
						// IMPORTANT we need to overwrite BTL widget property
						// ProductGrouping.products from incoming properties
						// because we could have less items then before, and "merge" will merge together them
						if (
							tmpWidget &&
							"productGrouping" in tmpWidget &&
							"productGrouping" in properties &&
							tmpWidget.productGrouping &&
							properties.productGrouping
						) {
							tmpWidget.productGrouping.products =
								properties.productGrouping.products;
						}
						tmpWidgets[index] = tmpWidget;
					}
				});
				setWidgets(tmpWidgets);
			}
		},
		[widgets, setWidgets]
	);

	const updateWidget = (
		id: string | number,
		type: WidgetNodes,
		values: Partial<Widget>
	) => {
		widgetsWereChanged.set(id, {
			node: WIDGET,
			subNode: type,
		});
		updateWidgetsWereChanged(widgetsWereChanged);
		updateWidgetsProperties(new Map().set(id, values));
	};

	const widgetFieldsPresetServerDedicated = useCallback(
		(currentNodeType: WidgetNodes, points: Array<number>) => {
			const [minX, minY] = getMinXY(points);
			const fields = {
				[PRODUCT_WIDGET]: {
					widgetButton: {
						shape: {
							x: Math.round(minX) - 20,
							y: Math.round(minY) - 20,
						},
					},
				},
				[BUY_THE_LOOK_WIDGET]: {
					productGrouping: {
						obfuscatedCustomerID,
					},
					widgetButton: {
						shape: {
							x: Math.round(minX) - 15,
							y: Math.round(minY) - 15,
						},
					},
					statisticsID: generateId(16),
				},
				[HTML5_WIDGET]: {},
				[VIDEO_WIDGET]: {},
			};
			return fields[currentNodeType];
		},
		[obfuscatedCustomerID]
	);

	/**
	 * PUBLIC METHODS
	 */

	const saveWidgets = useCallback(
		async (widgetsToSave: Array<UpdateParams>) => {
			if (widgets && publicationId) {
				let tmpWidgets = [...widgets];
				const modifiedWidgets: Array<Promise<AxiosResponse | void>> = [];
				widgetsToSave.forEach(({id, isNew}: UpdateParams) => {
					const index = tmpWidgets.findIndex((widget) => widget.id === id);
					const widgetObjectToSave = {...tmpWidgets[index]};
					if (isNew) {
						delete widgetObjectToSave.id;
					}
					modifiedWidgets.push(
						processSaveWidgets(publicationId, widgetObjectToSave).then(
							(serverId) => {
								/**
								 * Important - index !== savedIndex
								 * We need to consider tmpWidgets could be changed for the next tic,
								 * so very important to define actual index for current "tmpWidgets"
								 */
								const savedIndex = tmpWidgets.findIndex(
									(widget) => widget.id === id
								);
								widgetsWereCreated.delete(id);
								widgetsWereChanged.delete(id);
								if (serverId) {
									tmpWidgets[savedIndex].id = serverId;
								}
								setWidgets([...tmpWidgets]);
								updateWidgetsWereChanged(new Map(widgetsWereChanged));
								updateWidgetsWereCreated(new Map(widgetsWereCreated));
							}
						)
					);
				});
				return Promise.allSettled(modifiedWidgets);
			}
		},
		[widgets, publicationId, widgetsWereChanged, widgetsWereCreated]
	);

	const deleteSomeWidgets = useCallback(
		async (widgetsToDelete: Array<UpdateParams>) => {
			if (widgets && publicationId) {
				const tmp = [...widgets];
				const newWidgets: Array<Promise<void>> = [];
				const modifiedWidgets: Array<Promise<AxiosResponse | void>> = [];
				widgetsToDelete.forEach(({id, isNew}: UpdateParams) => {
					const index = tmp.findIndex((widget) => widget.id === id);

					/**
					 * In case we added Widget but did not press "Save all".
					 * We do not need send any request to server for this king of Widgets
					 */

					if (isNew) {
						tmp.splice(index, 1);
						newWidgets.push(Promise.resolve());
						widgetsWereChanged.delete(id);
						widgetsWereCreated.delete(id);
					} else {
						/**
						 * Send request to the server
						 */
						modifiedWidgets.push(
							processDeleteWidget(publicationId, id as number, tmp[index]).then(
								(response) => {
									/**
									 * Important - index !== deletedIndex
									 * We need to consider tmp could be changed for the next tic,
									 * so very important to define actual index for current "tmp"
									 */
									const deletedIndex = tmp.findIndex(
										(widget) => widget.id === id
									);
									tmp.splice(deletedIndex, 1);
									widgetsWereChanged.delete(id);
									widgetsWereCreated.delete(id);
									updateWidgetsWereChanged(new Map(widgetsWereChanged));
									updateWidgetsWereCreated(new Map(widgetsWereCreated));
									setWidgets([...tmp]);
									return response;
								}
							)
						);
					}
				});
				setWidgets([...tmp]);
				updateWidgetsWereChanged(new Map(widgetsWereChanged));
				updateWidgetsWereCreated(new Map(widgetsWereCreated));
				return Promise.allSettled([...newWidgets, ...modifiedWidgets]);
			}
		},
		[widgets, widgetsWereChanged, widgetsWereCreated, publicationId]
	);

	const deleteWidgets = useCallback(async (): Promise<number> => {
		if (!publicationId) {
			return 0;
		}
		const deletedNodes = await deleteAllWidgets(publicationId);
		const totalNodes = widgetsWereCreated.size + deletedNodes;
		updateWidgetsWereCreated(new Map());
		updateWidgetsWereChanged(new Map());
		setWidgets([]);
		return totalNodes;
	}, [publicationId, widgetsWereCreated]);

	const createWidget = useCallback(
		({
			currentNodeType,
			points,
			id,
			drawerType,
		}: {
			currentNodeType: WidgetNodes;
			points: Array<number>;
			id: string;
			drawerType: DrawerType;
		}) => {
			if (publicationId) {
				const tmp = widgets ? [...widgets] : [];
				const enrichmentShape = getShapeByDrawerType(drawerType, points);
				tmp.push({
					...merge(
						{},
						WIDGET_FIELDS_PRESET[currentNodeType],
						widgetFieldsPresetServerDedicated(currentNodeType, points)
					),
					publicationID: publicationId,
					enrichmentShape,
					fromPageNumber: pageNumber,
					toPageNumber: pageNumber,
					id,
				});
				setWidgets([...tmp]);
				updateWidgetsWereSelected(
					new Map().set(id, {node: WIDGET, subNode: currentNodeType})
				);
				widgetsWereCreated.set(id, {node: WIDGET, subNode: currentNodeType});
				updateWidgetsWereCreated(new Map(widgetsWereCreated));
			}
		},
		[
			widgets,
			widgetsWereCreated,
			publicationId,
			widgetFieldsPresetServerDedicated,
			pageNumber,
		]
	);

	const createWidgets = useCallback(
		(presets: Widget[]) => {
			if (!publicationId) {
				return;
			}
			const tmp = widgets ? [...widgets] : [];

			presets.forEach((preset: Widget) => {
				const {_type_: currentNodeType, id} = preset;
				const widget = {
					...merge(
						{},
						WIDGET_FIELDS_PRESET[currentNodeType],
						widgetFieldsPresetServerDedicated(currentNodeType, [
							0,
							0,
							0,
							0,
							0,
							0,
							0,
							0,
						])
					),
					...preset,
					publicationID: publicationId,
				} as Widget;

				tmp.push(widget);
				widgetsWereCreated.set(id, {node: WIDGET, subNode: currentNodeType});
			});

			setWidgets(tmp);
			updateWidgetsWereCreated(new Map(widgetsWereCreated));
		},
		[
			widgets,
			widgetsWereCreated,
			publicationId,
			widgetFieldsPresetServerDedicated,
		]
	);

	const updateWidgetPosition = useCallback(
		(shape) => {
			if (widgets) {
				const index: number = widgets.findIndex(
					(widget: {id: string | number}) => widget.id === shape.id
				);
				if (index > -1) {
					let tmpWidgets = [...widgets];
					let tmpWidget = {...tmpWidgets[index]};
					if (
						"widgetButton" in tmpWidget &&
						tmpWidget.widgetButton.displayComponent
					) {
						let shapeButton = shape.groupElements[0]
							? shape.groupElements[0].props
							: {};
						tmpWidget.widgetButton.shape.x = Math.round(shapeButton.x ?? 0);
						tmpWidget.widgetButton.shape.y = Math.round(shapeButton.y ?? 0);
					}
					tmpWidget.enrichmentShape = getShapeByType(
						tmpWidget.enrichmentShape._type_,
						shape.points
					);
					widgetsWereChanged.set(shape.id, {
						node: shape.type.node,
						subNode: shape.type.subNode,
					});

					tmpWidgets[index] = tmpWidget;
					setWidgets(tmpWidgets);
					updateWidgetsWereChanged(new Map(widgetsWereChanged));
				}
			}
		},
		[widgets, widgetsWereChanged, setWidgets]
	);

	useEffect(() => {
		let newWidgetWereSelected: Widget | undefined = undefined;
		if (widgets && widgetsWereSelected.size === 1) {
			const [id] = Array.from(widgetsWereSelected.entries())[0];
			newWidgetWereSelected = widgets.find(
				(widget: Widget): boolean => widget.id === id
			);
		}
		setWidgetWereSelected(newWidgetWereSelected);
	}, [widgets, widgetsWereSelected, setWidgetWereSelected]);

	useEffect(() => {
		if (publicationId) {
			updateWidgetsWereSelected(new Map());
			const widgetsToSave: Array<UpdateParams> = [];
			Array.from(widgetsWereChanged.keys()).forEach((id) =>
				widgetsToSave.push({id, isNew: false})
			);
			Array.from(widgetsWereCreated.keys()).forEach((id) =>
				widgetsToSave.push({id, isNew: true})
			);
			saveWidgets(widgetsToSave).finally(() => {
				getWidgetsForPage(publicationId, pageNumber)
					.then(setWidgets)
					.catch(() => setWidgets([]));
			});
		}
	}, [pageNumber, publicationId]);

	useEffect(() => {
		const newPageWidgets = widgets
			? widgets.filter(
					(widget: Widget): boolean => widget.fromPageNumber === pageNumber
			  )
			: null;
		setPageWidgets(newPageWidgets);
	}, [pageNumber, publicationId, widgets]);

	return {
		widgets,
		pageWidgets,
		widgetsWereCreated,
		widgetsWereChanged,
		updateWidgetPosition,
		updateWidgetsProperties,
		updateWidget,
		widgetsWereSelected,
		widgetWereSelected,
		updateWidgetsWereSelected,
		saveWidgets,
		deleteSomeWidgets,
		deleteWidgets,
		createWidget,
		createWidgets,
	};
};

export {useWidgets};

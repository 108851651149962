import {useCallback, KeyboardEvent} from "react";

const useHotKeys = ({
	copy,
	paste,
	selectAll,
	moveSelectedShapes,
}: {
	copy: () => void;
	paste: () => void;
	selectAll: () => void;
	moveSelectedShapes: (moveX: number, moveY: number) => void;
}) => {
	const enrichedHotKeys = useCallback(
		(e: KeyboardEvent<HTMLDivElement>) => {
			switch (e.key) {
				case "c":
				case "C":
					if (e.ctrlKey || e.metaKey) {
						e.preventDefault();
						copy();
					}
					break;
				case "p":
				case "P":
					if (e.ctrlKey || e.metaKey) {
						e.preventDefault();
						paste();
					}
					break;
				case "v":
				case "V":
					if (e.ctrlKey) {
						e.preventDefault();
						paste();
					}
					break;
				case "a":
				case "A":
					if (e.ctrlKey) {
						e.preventDefault();
						selectAll();
					}
					break;
				case "ArrowDown": {
					e.preventDefault();
					const moveX = 0;
					const moveY = e.shiftKey ? 10 : 1;
					moveSelectedShapes(moveX, moveY);
					break;
				}
				case "ArrowUp": {
					e.preventDefault();
					const moveX = 0;
					const moveY = e.shiftKey ? -10 : -1;
					moveSelectedShapes(moveX, moveY);
					break;
				}
				case "ArrowLeft": {
					e.preventDefault();
					const moveX = e.shiftKey ? -10 : -1;
					const moveY = 0;
					moveSelectedShapes(moveX, moveY);
					break;
				}
				case "ArrowRight": {
					e.preventDefault();
					const moveX = e.shiftKey ? 10 : 1;
					const moveY = 0;
					moveSelectedShapes(moveX, moveY);
					break;
				}
				default:
					break;
			}
		},
		[copy, paste, selectAll, moveSelectedShapes]
	);

	return {
		enrichedHotKeys,
	};
};

export {useHotKeys};

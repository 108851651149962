import React, {useState, useCallback, useEffect, ChangeEvent} from "react";
import {Field, ErrorMessage} from "formik";

// Components
import {NumericInputComponent} from "@zmags/zmags-ui-library";
import * as StyledForm from "src/utilities/form/StyledForm";

// Types
import {Target} from "src/models/Links";

// Utils
import {SelectOption, isEqual} from "src/utilities/form";

export interface FormValues {
	target: string;
	lightBox?: boolean;
	lightBoxWidth?: number | null;
	lightBoxHeight?: number | null;
}

export interface FormOptions {
	target: SelectOption<string>[];
}

export interface FormProps {
	target: SelectOption<string>[];
	values: FormValues;
	setTarget: (value: string) => void;
	setLightBox: (value: boolean) => void;
	setLightBoxWidth: (value: number) => void;
	setLightBoxHeight: (value: number) => void;
}

export const LinkTarget: React.FC<FormProps> = (
	props: FormProps
): JSX.Element => {
	const {
		target,
		values,
		setTarget,
		setLightBox,
		setLightBoxWidth,
		setLightBoxHeight,
	} = props;

	const [targetAttr, setTargetAttr] = useState<string>(values.target);

	// Update lightBox based on current target selected
	useEffect(() => {
		const isLightbox = isEqual<FormValues, Target>(
			props,
			"target",
			Target.LightBox
		);
		setLightBox(isLightbox);
		if (!isLightbox) {
			setLightBoxWidth(0);
			setLightBoxHeight(0);
		}
	}, [values.target, setLightBox, setLightBoxWidth, setLightBoxHeight]);

	// Update target based on selected
	useEffect(() => {
		setTarget(targetAttr !== Target.Custom ? targetAttr : "_blank");
	}, [targetAttr, setTarget]);

	const handleTargetAttrChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) =>
			setTargetAttr(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			),
		[]
	);
	return (
		<>
			{/** Open link in */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Open link in</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as="select"
							options={target}
							disabled={false}
							value={targetAttr}
							onChange={handleTargetAttrChange}
						>
							{target.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
					{/* Target */}
					<StyledForm.Field>
						<Field
							name="target"
							value={values.target}
							type="text"
							disabled={targetAttr !== Target.Custom}
						/>
					</StyledForm.Field>
					<ErrorMessage name="target" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
			{/** LightBox options */}
			<StyledForm.Group>
				{/* LightBox width */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxWidth">
						LightBox width
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxWidth"
							value={values.lightBoxWidth}
							as={NumericInputComponent}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.LightBox)
							}
							onChange={setLightBoxWidth}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxWidth" />
				</StyledForm.FieldWrapper>
				{/* LightBox height */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxHeight">
						LightBox height
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxHeight"
							value={values.lightBoxHeight}
							as={NumericInputComponent}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.LightBox)
							}
							onChange={setLightBoxHeight}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxHeight" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
		</>
	);
};

export default LinkTarget;

import React, {useEffect, useState, ChangeEvent} from "react";

// Components
import {Button} from "@zmags/zmags-ui-library";
import {Form, Footer, Input, Label} from "./TooltipManager.styles";

// Models
import {LinkTooltip} from "src/models/LinkTooltip";

export interface EditTooltipFormProps {
	tooltip: LinkTooltip;
	onSave: (tooltip: LinkTooltip) => void;
	onCancel: () => void;
	onDelete: (tooltip: LinkTooltip) => void;
}

export const EditTooltipForm: React.FC<EditTooltipFormProps> = (props) => {
	const {tooltip, onSave, onDelete, onCancel} = props;
	const [changedTooltip, setChangedTooltip] = useState<LinkTooltip>({
		...tooltip,
	});
	const canSave: boolean =
		!!changedTooltip.tooltip && changedTooltip.tooltip !== tooltip.tooltip;

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const {value} = event.currentTarget;
		setChangedTooltip({...changedTooltip, tooltip: value});
	}

	async function handleSave() {
		onSave(changedTooltip);
	}

	async function handleDelete() {
		onDelete(tooltip);
	}

	function handleCancel() {
		onCancel();
	}

	useEffect(() => {
		setChangedTooltip({...tooltip});
	}, [tooltip]);

	return (
		<div>
			<Form>
				<Label>Edit tooltip</Label>
				<Input
					autoFocus={true}
					type="text"
					value={changedTooltip.tooltip}
					onChange={handleChange}
				/>
			</Form>
			<Footer>
				<Button label="Cancel" size="small" onClick={handleCancel} />
				<Button
					label="Save"
					size="small"
					disabled={!canSave}
					onClick={handleSave}
				/>
				<Button
					label="Delete"
					size="small"
					disabled={changedTooltip.id === -1}
					onClick={handleDelete}
				/>
			</Footer>
		</div>
	);
};

export default EditTooltipForm;

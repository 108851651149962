import React from "react";
import {Formik} from "formik";
import withAutoCommit from "src/utilities/form/withAutoCommit";
import {Form, VideoWidgetValues, FormOwnProps} from "./Form";

const AutoCommitForm = withAutoCommit<any, VideoWidgetValues>(Form);

export interface VideoWidgetProperties extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: VideoWidgetValues;
	/** Updatable form state. Use when need to change form state from outside.
	 * If values are empty, only initial state wiil be applied
	 */
	values?: VideoWidgetValues;
	/** Form submission handler */
	onSubmit: (values: VideoWidgetValues) => void;
}

export const VideoWidgetForm: React.FC<VideoWidgetProperties> = (
	props: VideoWidgetProperties
) => {
	const {initialValues, onSubmit} = props;
	return (
		<Formik
			key={initialValues.id}
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <AutoCommitForm {...props} {...formProps} />}
		</Formik>
	);
};

export default VideoWidgetForm;

import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {Form, FormOwnProps, FormValues} from "./Form";

export interface AutoLinksFormProps extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: FormValues;
	/** Form submission handler */
	onSubmit: (values: FormValues) => void;
}

export const AutoLinksForm: FunctionComponent<AutoLinksFormProps> = (
	props: AutoLinksFormProps
) => {
	const {initialValues, onSubmit} = props;

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <Form {...props} {...formProps} />}
		</Formik>
	);
};

export default AutoLinksForm;

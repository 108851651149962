import React from "react";

// Components
import {ExternalLinkProperties} from "src/components/externalLinkProperties/ExternalLinkProperties";
import {InternalLinkProperties} from "src/components/internalLinkProperties/InternalLinkProperties";
import {ProductLinkProperties} from "src/components/productLinkProperties/ProductLinkProperties";
import {ToolPanel} from "src/components/toolPanel/ToolPanel";

// Models
import {
	Link,
	LinkNodes,
	InternalLink,
	ProductLink,
	ExternalLink,
} from "src/models/Links";
import {PublicationSettings} from "src/models/PublicationSettings";
import {EditorSettings} from "src/models/EditorSettings";
import {Media} from "src/models/Medias";
import {LinkTooltip} from "src/models/LinkTooltip";

// Constants & Utilities
import {getLinkSubNode, getLinkSubNodeTitle} from "../../utilities";
import {
	EXTERNAL_LINK_NODE,
	INTERNAL_LINK_NODE,
	PRODUCT_LINK_NODE,
} from "../../constants";

interface LinkPanelProps {
	publicationID: number;
	link: Link;
	publicationSettings: PublicationSettings;
	editorSettings: EditorSettings;
	mediaMap: Media | undefined;
	tooltips: LinkTooltip[] | undefined;
	onChange: (id: string | number, type: LinkNodes, link: Link) => void;
	onPanelFocus: () => void;
}

export const LinkPanel: React.FC<LinkPanelProps> = (props) => {
	const {
		link,
		publicationSettings,
		editorSettings,
		mediaMap,
		tooltips,
		onChange,
		onPanelFocus,
	} = props;
	const linkType = getLinkSubNode(link);
	const title = getLinkSubNodeTitle(link);

	return (
		<div onClick={onPanelFocus}>
			<ToolPanel
				style={{maxWidth: "100%", minWidth: "300px"}}
				title={`${title} Properties`}
			>
				{linkType === EXTERNAL_LINK_NODE && (
					<ExternalLinkProperties
						initialValues={link as ExternalLink}
						editorSettings={editorSettings}
						mediaMap={mediaMap}
						tooltips={tooltips}
						onChange={onChange}
					/>
				)}
				{linkType === INTERNAL_LINK_NODE && (
					<InternalLinkProperties
						initialValues={link as InternalLink}
						editorSettings={editorSettings}
						publicationSettings={publicationSettings}
						mediaMap={mediaMap}
						tooltips={tooltips}
						onChange={onChange}
					/>
				)}
				{linkType === PRODUCT_LINK_NODE && (
					<ProductLinkProperties
						initialValues={link as ProductLink}
						editorSettings={editorSettings}
						mediaMap={mediaMap}
						tooltips={tooltips}
						onChange={onChange}
					/>
				)}
			</ToolPanel>
		</div>
	);
};

export default LinkPanel;

import {useState, useCallback} from "react";
import {useToasts} from "react-toast-notifications";

// Models
import {Link} from "src/models/Links";
import {StateArray} from "src/models/Common";

const useEditMenu = ({
	links,
	linksWereSelected,
	createLinks,
	pageNumber,
}: {
	links: Array<Link> | null;
	linksWereSelected: StateArray;
	createLinks: (param: Array<Link>) => void;
	pageNumber: number;
}) => {
	const [copies, setCopies] = useState<Array<Link>>([]);
	const {addToast} = useToasts();

	const copy = useCallback(() => {
		const copiesIds = Array.from(linksWereSelected.keys());
		const linksCopies = copiesIds.reduce(
			(res: Array<Link>, id: string | number) => {
				const link: Link | undefined = links?.find((l: Link) => l.id === id);
				if (link) {
					res.push({...link});
				}
				return res;
			},
			[]
		);
		setCopies(linksCopies);
	}, [linksWereSelected, links]);

	const paste = useCallback(() => {
		if (!copies.length) {
			addToast("No links were copied", {
				autoDismiss: true,
				appearance: "warning",
			});
			return;
		}
		const linksCopies = copies.map(
			(link: Link): Link => ({
				...link,
				id: `copy${link.id}${Date.now()}`,
				pageNumber: pageNumber,
				fromPageNumber: pageNumber,
				toPageNumber: pageNumber,
			})
		);
		createLinks(linksCopies);
	}, [copies, createLinks, addToast, pageNumber]);

	return {
		copy,
		paste,
	};
};

export {useEditMenu};

import {useState} from "react";

export interface PaginationPage {
	id: string;
	pageNumber: number;
	pageIdx: number;
	title: string;
}

function usePagination(initialPageIdx: number, pages: PaginationPage[]) {
	// Pagination
	const [currentPageIdx, setCurrentPageIdx] = useState<number>(initialPageIdx);
	const canMoveNextPage = currentPageIdx < pages.length - 1;
	const canMovePrevPage = currentPageIdx > 0;

	function safeSetCurrentPageIdx(pageIdx: number) {
		let nextPageIdx = pageIdx;
		const firstPage = 0;
		const lastPage = pages.length - 1;
		if (nextPageIdx < firstPage) {
			nextPageIdx = firstPage;
		}
		if (nextPageIdx > lastPage) {
			nextPageIdx = lastPage;
		}
		setCurrentPageIdx(nextPageIdx);
	}

	function setCurrentPage(event: React.ChangeEvent<HTMLSelectElement>) {
		safeSetCurrentPageIdx(event.target.selectedIndex);
	}

	function movePrevPage() {
		safeSetCurrentPageIdx(currentPageIdx - 1);
	}

	function moveNextPage() {
		safeSetCurrentPageIdx(currentPageIdx + 1);
	}

	return {
		currentPageIdx: pages[currentPageIdx]?.pageIdx,
		currentPage: pages[currentPageIdx],
		canMoveNextPage,
		canMovePrevPage,
		setCurrentPage,
		movePrevPage,
		moveNextPage,
	};
}

export {usePagination};

import React, {useCallback, useState} from "react";
import {Formik} from "formik";
import withAutoCommit from "../../utilities/form/withAutoCommit";
import {Form, ProductWidgetFormValues} from "./Form";
import {ProductDetailsWidgetProperties} from "../../models/Widgets";

const AutoCommitForm = withAutoCommit<any, ProductWidgetFormValues>(Form);

export interface ProductWidgetFormProperties
	extends Partial<ProductDetailsWidgetProperties> {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: ProductWidgetFormValues;
	/** Updatable form state. Use when need to change form state from outside.
	 * If values are empty, only initial state wiil be applied
	 */
	values?: ProductWidgetFormValues;
	/** Form submission handler */
	onSubmit: (values: ProductWidgetFormValues) => void;
}

export const ProductWidgetForm: React.FC<ProductWidgetFormProperties> = (
	props: ProductWidgetFormProperties
) => {
	const {initialValues, onSubmit} = props;
	/**
	 * In fact we don't use fields bellow in Product widget node.
	 * This fields displays price, name and description based on productID (fetch those data from separate endpoint)
	 * But we need to describe them as part of initial value
	 * because Formik requires all fields in initial properties to avoid error like:
	 *" Warning: Form is changing an uncontrolled input of type text to be controlled...."
	 * */
	const [innerFields, setInnerFields] = useState<{
		productPrice: string;
		productName: string;
		productDescription: string;
	}>({
		productPrice: "",
		productName: "",
		productDescription: "",
	});
	const handleSubmit = useCallback(
		(values) => {
			setInnerFields({
				productPrice: values.productPrice,
				productName: values.productName,
				productDescription: values.productDescription,
			});

			onSubmit(values);
		},
		[onSubmit]
	);
	return (
		<Formik
			key={initialValues.id}
			initialValues={{
				...initialValues,
				...innerFields,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{(formProps) => <AutoCommitForm {...props} {...formProps} />}
		</Formik>
	);
};

export default ProductWidgetForm;

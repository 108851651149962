import {AxiosInstance} from "axios";
import AxiosMockAdapter from "axios-mock-adapter";

import {Response, ErrorResponse} from "../types";
import {LinkTooltip} from "src/models/LinkTooltip";
import {MediaFile} from "src/models/Medias";
import {PublicationSettings} from "src/models/PublicationSettings";
import {Link} from "src/models/Links";
import {Product} from "src/models/Product";
import {TotalEnrichments} from "src/models/Common";

export interface ErrorMessages {
	[key: string]: string;
}

export interface MockData {
	totalEnrichments: TotalEnrichments;
	tooltips: LinkTooltip[];
	mediaMap: {
		imagesAndSpecificMediaFiles: MediaFile[];
		images: MediaFile[];
		media: MediaFile[];
	};
	publicationSettings: PublicationSettings;
	links: Link[];
	product: Product;
}

/**
 * Sets the mock adapter on the axios instance
 *
 * @param axiosInstance
 * @param dataPath Path to file with mock data of MockData
 * @param [errorsPath] Path to file with errors messages of ErrorMessages
 */
const withMockAdapter = (
	axiosInstance: AxiosInstance,
	dataPath: string,
	errorsPath?: string
) => {
	const mock = new AxiosMockAdapter(axiosInstance, {delayResponse: 400});
	const data: MockData = require(`./${dataPath}`);
	const errors: ErrorMessages = errorsPath
		? require(`./${errorsPath}`)
		: undefined;

	/**
	 * Helper to get successful response
	 *
	 * @param value Any response mock
	 * @param [status] HTTP response status code
	 */
	const result = (value: any, status: number = 200): Response => ({
		result: status,
		value,
	});

	/**
	 * Helper to get error response
	 *
	 * @param status HTTP response status code
	 * @param [message] Error message text
	 */
	const error = (status: number, message?: string): ErrorResponse => ({
		result: status,
		message: message || (errors && errors[status]),
	});

	// Reqister mock requests here
	mock
		.onGet(/publications\/\d+\/enrichments/g)
		.reply<Response>(200, result(data.totalEnrichments));
	mock
		.onGet(/publications\/\d+\/tooltips/g)
		.reply<Response>(200, result(data.tooltips));
	mock.onPut(/publications\/\d+\/tooltips/g).reply((config) => {
		const requestData = JSON.parse(config.data);
		const {id} = requestData;
		if (id) {
			return [200, result(id)];
		}
		const newId =
			(Math.max(...data.tooltips.map((t: LinkTooltip): number => t.id)) ||
				1000) + Date.now();
		return [200, result(newId)];
	});
	mock
		.onDelete(/publications\/\d+\/tooltips\/\d+/g)
		.reply<Response>(200, result(undefined));
	mock
		.onGet(/publications\/\d+\/mediaMap/g)
		.reply<Response>(200, result(data.mediaMap));
	mock
		.onGet(/publications\/\d+\/settings/g)
		.reply<Response>(200, result(data.publicationSettings));
	mock
		.onGet(/api\/publications\/\d+\/links\?pageNumber=\d+/g)
		.reply<Response>(200, result(data.links));
	mock.onGet("/search/1/123").reply<Response>(200, result(data.product));
	mock
		.onGet(/search\/\d+\/\d+/g)
		.reply<ErrorResponse>(500, error(500, "Product not found. Try 123"));
	mock
		.onGet(/api\/publications\/\d+\/widgets\?pageNumber=\d+/g)
		.reply<Response>(200, result([]));
	mock
		.onGet(/publications\/\d+\/formContainers/g)
		.reply<Response>(200, result([]));
	mock.onPost(/publications\/\d+\/autoLinks/g).reply<Response>(200, result(5));
	mock.onGet("/").networkError();

	return axiosInstance;
};

export default withMockAdapter;

import {useState} from "react";
import {useToasts} from "react-toast-notifications";
import {CancelToken} from "axios";

// API
import API from "src/api/endpoints";

// Models
import {Product} from "src/models/Product";
import {AXIOS_ABORTED_MESSAGE} from "../constants";

const {getProductSearch} = API;

const PRODUCT_DISABLED_ERROR = "Error. Probably product is disabled";
const PRODUCT_EMPTY_ERROR = "Error. Can not find product";
const PRODUCT_SERVER_ERROR = "Server error occurred, try change ID";

const useProduct = () => {
	const {addToast} = useToasts();
	const [searchingProduct, setSearchingProduct] = useState<boolean>(false);

	const searchProduct = async (
		magid?: number,
		productID?: string,
		cancelToken?: CancelToken
	): Promise<Product | undefined> => {
		if (productID && magid) {
			try {
				setSearchingProduct(true);
				const product = await getProductSearch(productID, magid, cancelToken);
				if (product[0] && product[0].enabled) {
					return {
						magid,
						productID,
						itemName: product[0].name,
						price: product[0].price,
						description: product[0].description,
						associatedSKU: product[0].sku,
					};
				} else if (product[0] && !product[0].enabled) {
					addToast(PRODUCT_DISABLED_ERROR, {
						autoDismiss: true,
						appearance: "error",
					});
				} else {
					addToast(`${PRODUCT_EMPTY_ERROR}: ${productID}`, {
						autoDismiss: true,
						appearance: "error",
					});
				}
				return undefined;
			} catch (err) {
				if (err.message !== AXIOS_ABORTED_MESSAGE) {
					addToast(err.message || PRODUCT_SERVER_ERROR, {
						autoDismiss: true,
						appearance: "error",
					});
				}
				return undefined;
			} finally {
				setSearchingProduct(false);
			}
		}
	};

	return {searchingProduct, searchProduct};
};

export {useProduct};

import React, {useState, useEffect} from "react";

// Components
import {ExternalLinkPropertiesForm} from "src/components/externalLinkPropertiesForm/ExternalLinkPropertiesForm";
import {LinkProperties} from "src/components/linkProperties/LinkProperties";

// Utils
import {SelectOption, getSelectorForCollectionByKey} from "src/utilities/form";

// Models
import {PublicationSetting} from "src/models/PublicationSettings";
import {BaseLink, ExternalLink, LinkNodes} from "src/models/Links";
import {EditorSettings} from "src/models/EditorSettings";
import {Media} from "src/models/Medias";
import {LinkTooltip} from "src/models/LinkTooltip";
import {EXTERNAL_LINK_NODE} from "src/constants";

export interface ExternalLinkPropertiesProps {
	initialValues: ExternalLink;
	editorSettings: EditorSettings;
	mediaMap: Media | undefined;
	tooltips: LinkTooltip[] | undefined;
	onChange: (id: string | number, type: LinkNodes, link: ExternalLink) => void;
}

export const ExternalLinkProperties: React.FC<ExternalLinkPropertiesProps> = (
	props
) => {
	const {initialValues, editorSettings, mediaMap, tooltips, onChange} = props;
	const [target, setTarget] = useState<SelectOption<string>[]>([]);

	function handleLinkChanged(link: BaseLink) {
		onChange(link.id, EXTERNAL_LINK_NODE, link as ExternalLink);
	}

	useEffect(() => {
		const {target: collection} = editorSettings;
		setTarget(
			getSelectorForCollectionByKey<PublicationSetting<string>, string>({
				collection,
				valueKey: "value",
				labelKey: "displayName",
			})
		);
	}, [editorSettings]);

	return (
		<div>
			<ExternalLinkPropertiesForm
				initialValues={initialValues}
				options={{
					target,
				}}
				onSubmit={handleLinkChanged}
			/>
			<LinkProperties
				initialValues={initialValues as BaseLink}
				editorSettings={editorSettings}
				mediaMap={mediaMap}
				tooltips={tooltips}
				onSubmit={handleLinkChanged}
			/>
		</div>
	);
};

export default ExternalLinkProperties;

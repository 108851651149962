import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";
import debounce from "lodash.debounce";
import {RadioGroup, NumericInputComponent} from "@zmags/zmags-ui-library";
import * as StyledForm from "../../utilities/form/StyledForm";
import {ProductDetailsWidgetProperties} from "../../models/Widgets";
import {useProduct} from "../../hooks/useProduct";

export interface ProductWidgetFormValues
	extends Partial<ProductDetailsWidgetProperties> {
	productID?: string;
	productSourceType: number;
	productName?: string;
	productDescription?: string;
	productPrice?: string;
}
export type FormProps = FormikProps<ProductWidgetFormValues>;
export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const [status, setStatus] = useState<string | undefined>(undefined);
	const {searchProduct} = useProduct();

	const {values, setFieldValue} = props;
	const getPlaceholder = useCallback(() => {
		return values.productSourceType === 2
			? "External (No information available)"
			: status;
	}, [values.productSourceType, status]);

	const setAdditionalFieldsValues = useCallback(
		({itemName = "", description = "", price = ""}) => {
			setFieldValue("productName", itemName);
			setFieldValue("productDescription", description);
			setFieldValue("productPrice", price);
		},
		[setFieldValue]
	);

	function handleChangeSourceType(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("productSourceType", parseInt(event.target.value));
	}
	function handleChangeShowButton(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("widgetButton.displayComponent", event.target.checked);
	}

	function handleProductIDChange(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("productID", event.target.value);
		setAdditionalFieldsValues({});
	}

	function handleCoordinateChange(name: string): (value: number) => void {
		return (value) => setFieldValue(name, value);
	}
	const searchData = useCallback(
		debounce((publicationID, productID) => {
			if (values.productSourceType === 1) {
				searchProduct(publicationID, productID).then((product) => {
					if (product) {
						setAdditionalFieldsValues(product);
					} else {
						setStatus(undefined);
					}
				});
			} else {
				setAdditionalFieldsValues({});
			}
		}, 500),
		[values.productSourceType]
	);

	useEffect(() => {
		if (values.productID) {
			setStatus("Searching...");
			searchData(values.publicationID, values.productID);
		}
	}, [values.publicationID, values.productID, searchData]);

	return (
		<FormikForm>
			{/** Product source */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Product source</StyledForm.Label>
					<StyledForm.Field>
						<RadioGroup
							name="productSourceType"
							value={values.productSourceType.toString()}
							onChange={handleChangeSourceType}
						>
							<RadioGroup.Radio
								id="external"
								key="external"
								label="External"
								value={"2"}
								disabled={false}
							/>
							<RadioGroup.Radio
								id="productDatabase"
								key="productDatabase"
								label="Product database"
								value={"1"}
								disabled={false}
							/>
						</RadioGroup>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
			{/** Product ID */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productID">Product ID</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="productID"
						onChange={handleProductIDChange}
						type="text"
						disabled={false}
					/>
					<ErrorMessage name="productID" />
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Name */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productName">Product Name</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productName"
						type="text"
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Description */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productDescription">
					Product Description
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productDescription"
						type="text"
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Product Price */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productPrice">
					Product Price
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						placeholder={getPlaceholder()}
						name="productPrice"
						type="text"
						disabled={true}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			<hr />
			<StyledForm.FieldWrapper>
				<StyledForm.Label>Show Button</StyledForm.Label>
				<StyledForm.Field>
					<Field
						type="checkbox"
						disabled={false}
						name="values.widgetButton.displayComponent"
						checked={values.widgetButton?.displayComponent}
						onChange={handleChangeShowButton}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="values.widgetButton.shape.y">
					Top Coordinate
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="widgetButton.shape.y"
						value={values.widgetButton?.shape.y}
						as={NumericInputComponent}
						min={0}
						disabled={!values.widgetButton?.displayComponent}
						onChange={handleCoordinateChange("widgetButton.shape.y")}
					/>
				</StyledForm.Field>
				<ErrorMessage name="widgetButton.shape.y" />
			</StyledForm.FieldWrapper>
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="values.widgetButton.shape.x">
					Left Coordinate
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="widgetButton.shape.x"
						value={values.widgetButton?.shape.x}
						as={NumericInputComponent}
						min={0}
						disabled={!values.widgetButton?.displayComponent}
						onChange={handleCoordinateChange("widgetButton.shape.x")}
					/>
				</StyledForm.Field>
				<ErrorMessage name="widgetButton.shape.x" />
			</StyledForm.FieldWrapper>
		</FormikForm>
	);
};

export default Form;

import React, {ChangeEvent, useCallback, useState} from "react";
import {Navigation, RadioGroup, Icon, Button} from "@zmags/zmags-ui-library";
import {MediaFile} from "../../models/Medias";
import ProductItem from "./ProductItem";
import * as StyledForm from "../../utilities/form/StyledForm";
import {BuyTheLookWidgetProperties} from "../../models/Widgets";

import {
	FormikProps,
	Form as FormikForm,
	Field,
	ErrorMessage,
	FieldArray,
} from "formik";
import {
	ProductSection,
	PropertiesSection,
	SectionContentWrapper,
	Select,
} from "./Styles";
export interface BuyTheLookWidgetFormValues
	extends Partial<BuyTheLookWidgetProperties> {
	productID?: string;
	productSourceType: number;
	productName?: string;
	productDescription?: string;
	productPrice?: string;
}
export type FormProps = FormikProps<BuyTheLookWidgetFormValues>;

enum TAB_ITEM {
	PRODUCTS = "products",
	PROPERTIES = "properties",
}

export const Form: React.FC<FormProps & {images: MediaFile[]}> = (
	props
): JSX.Element => {
	const [activeItem, updateActiveItem] = useState<TAB_ITEM>(TAB_ITEM.PRODUCTS);
	const {values, setFieldValue, images} = props;

	const toggleTabs = useCallback(
		(id) => {
			updateActiveItem(id);
		},
		[updateActiveItem]
	);

	function handleChangeShowButton(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("widgetButton.displayComponent", event.target.checked);
	}
	function handleChangeShowShape(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("shapeEnabled", event.target.checked);
	}
	function handleImageSelect(event: ChangeEvent<HTMLSelectElement>) {
		setFieldValue("imageID", event.target.value);
	}
	function handleChangeSourceType(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue("productSourceType", parseInt(event.target.value));
	}

	return (
		<>
			<Navigation
				size="small"
				type="horizontal"
				float="left"
				handleClick={toggleTabs}
				activeItems={[activeItem]}
			>
				<Navigation.Item
					icon={<Icon name={"shopping_cart"} />}
					key={TAB_ITEM.PRODUCTS}
					id={TAB_ITEM.PRODUCTS}
				>
					Products
				</Navigation.Item>
				<Navigation.Item
					icon={<Icon name={"settings"} />}
					key={TAB_ITEM.PROPERTIES}
					id={TAB_ITEM.PROPERTIES}
				>
					Properties
				</Navigation.Item>
			</Navigation>
			<FormikForm>
				<ProductSection
					className={activeItem === TAB_ITEM.PRODUCTS ? "active" : undefined}
				>
					<SectionContentWrapper>
						{/** Product source */}
						<StyledForm.Group>
							<StyledForm.FieldWrapper>
								<StyledForm.Label>Product source</StyledForm.Label>
								<StyledForm.Group>
									<RadioGroup
										name="productSourceType"
										value={values.productSourceType.toString()}
										onChange={handleChangeSourceType}
									>
										<RadioGroup.Radio
											id="external"
											key="external"
											label="External"
											value={"2"}
											disabled={false}
										/>
										<RadioGroup.Radio
											id="productDatabase"
											key="productDatabase"
											label="Product database"
											value={"1"}
											disabled={false}
										/>
									</RadioGroup>
								</StyledForm.Group>
							</StyledForm.FieldWrapper>
						</StyledForm.Group>
						<FieldArray
							name="productGrouping.products"
							// @ts-ignore
							render={(arrayHelpers) => {
								function addSection() {
									arrayHelpers.push("");
								}
								return (
									<>
										<div className="add">
											<Button
												size="small"
												icon={<Icon name={"add_box"} />}
												label={"Add product to the look"}
												onClick={addSection}
												type="dashed"
											/>
											<div className="total_products">
												Total products:
												{values.productGrouping?.products?.length}
											</div>
										</div>

										{values.productGrouping?.products?.map(
											// @ts-ignore
											(product, index) => (
												<div key={index} className="product_item">
													<div className="controls">
														<div>{`${index + 1}.`}</div>
														<div className="separator" />
														<Button
															// @ts-ignore
															onClick={() => {
																arrayHelpers.remove(index);
															}}
															size="small"
															icon={<Icon name="delete_forever" />}
														/>
													</div>
													<ProductItem
														key={index}
														index={index}
														product={product}
														publicationID={values.publicationID}
														productSourceType={values.productSourceType}
													/>
												</div>
											)
										)}
									</>
								);
							}}
						/>
					</SectionContentWrapper>
				</ProductSection>
				<PropertiesSection
					className={activeItem === TAB_ITEM.PROPERTIES ? "active" : undefined}
				>
					<SectionContentWrapper>
						<h3>Buy the look properties</h3>
						{/** Title */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="productGrouping.title">
								Title
							</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="productGrouping.title"
									value={values.productGrouping?.title}
									type="text"
								/>
							</StyledForm.Field>
							<ErrorMessage name="productGrouping.title" />
						</StyledForm.FieldWrapper>

						{/** Description */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="productGrouping.description">
								Description
							</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="productGrouping.description"
									value={values.productGrouping?.description}
									type="text"
								/>
							</StyledForm.Field>
							<ErrorMessage name="productGrouping.description" />
						</StyledForm.FieldWrapper>

						{/** Statistics ID */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="statisticsID">
								Statistics ID
							</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="statisticsID"
									value={values.statisticsID}
									type="text"
								/>
							</StyledForm.Field>
							<ErrorMessage name="statisticsID" />
						</StyledForm.FieldWrapper>

						{/** Image */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="values.widgetButton.shape.y">
								Image
							</StyledForm.Label>
							<StyledForm.Field>
								<Select
									name="imageID"
									value={values.imageID ?? ""}
									onChange={handleImageSelect}
								>
									<option key={0} value={""}>
										choose option
									</option>
									{images &&
										images?.map((image: MediaFile) => (
											<option key={image.id} value={image.id}>
												{image.name}
											</option>
										))}
								</Select>
							</StyledForm.Field>
							<ErrorMessage name="widgetButton.shape.y" />
						</StyledForm.FieldWrapper>
					</SectionContentWrapper>
					<SectionContentWrapper>
						<h3>General properties</h3>
						{/** Statistics ID */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="values.widgetButton.text">
								Button text
							</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="widgetButton.text"
									value={values.widgetButton?.text}
									type="text"
								/>
							</StyledForm.Field>
							<ErrorMessage name="widgetButton.shape.y" />
						</StyledForm.FieldWrapper>
						<StyledForm.FieldWrapper>
							<StyledForm.Label>Enable Button</StyledForm.Label>
							<StyledForm.Field>
								<Field
									type="checkbox"
									disabled={false}
									name="values.widgetButton.displayComponent"
									checked={values.widgetButton?.displayComponent}
									onChange={handleChangeShowButton}
								/>
							</StyledForm.Field>
						</StyledForm.FieldWrapper>
						<StyledForm.FieldWrapper>
							<StyledForm.Label>Enable Shape</StyledForm.Label>
							<StyledForm.Field>
								<Field
									type="checkbox"
									disabled={false}
									name="values.shapeEnabled"
									checked={values.shapeEnabled}
									onChange={handleChangeShowShape}
								/>
							</StyledForm.Field>
						</StyledForm.FieldWrapper>
					</SectionContentWrapper>
				</PropertiesSection>
			</FormikForm>
		</>
	);
};

export default Form;

import styled from "@emotion/styled";
import EnrichmentTheme from "../../theme";

export const AppWrapper = styled.div<{theme: typeof EnrichmentTheme}>`
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	font-family: ${({theme}) => theme.typography.family};
	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${({theme}) => theme.colors.primary.medium};
	}
	header {
		flex: 0 0 auto;
		padding: ${({theme}) => theme.spacing.xxs} 0
			${({theme}) => theme.spacing.xxxs} 0;
		border-bottom: 1px solid ${({theme}) => theme.colors.primary.lighter};
	}
	.content {
		display: flex;
		flex: 1 1 auto;
		overflow-x: auto;
		overflow-y: auto;
		.toolbox {
			display: flex;
			justify-content: center;
			background: ${({theme}) => theme.colors.primary.darker};
			height: 100%;
			min-height: 400px;
			.sketch-picker {
				z-index: 9;
			}
		}
		.body {
			flex: 0 1 75%;
			height: 100%;
			max-width: 800px;
			overflow: scroll;
		}
		.body.draw {
			cursor: crosshair;
		}
		.space {
			flex: 1 1 auto;
		}
		.sidebar {
			flex: 0 0 25%;
			min-width: 450px;
			height: 100%;
			min-height: 400px;
			background: ${({theme}) => theme.colors.bg.primary};
			border-left: 1px solid ${({theme}) => theme.colors.primary.lighter};
			padding: ${({theme}) => theme.spacing.xs};
			box-sizing: border-box;
			overflow: auto;
			> *:not(:last-child) {
				margin-bottom: ${({theme}) => theme.spacing.xs};
			}
		}
	}
	footer {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		padding: ${({theme}) => theme.spacing.xxs} ${({theme}) => theme.spacing.sm};
		height: 20px;
		background: ${({theme}) => theme.colors.primary.lighter};
		color: ${({theme}) => theme.colors.primary.medium};
		font-size: ${({theme}) => theme.typography.size.sm};
	}
`;

import chunk from "lodash.chunk";
import {
	Point,
	CoordinatesType,
	RectType,
	PossibleShapeTypes,
} from "src/models/Shapes";
import {DrawerType} from "src/models/Common";
import {
	POINT_TYPE,
	SHAPE_TYPE_POLYGON,
	SHAPE_TYPE_RECTANGLE,
	MIN_WIDGET_SIZE,
} from "src/constants";

export const checkSize = (
	size: number,
	minSize: number = MIN_WIDGET_SIZE
): number => {
	if (!size || size < minSize) {
		return minSize;
	}
	return Math.round(size);
};

/**
 *
 * @param x coordinate of a top left point of the square
 * @param y coordinate of a top left point of the square
 * @param cx initial point for rotation
 * @param cy initial point for rotation
 * @param rotation the angle of rotation (degrees)
 */
const rotatePoint = (
	x: number,
	y: number,
	cx: number,
	cy: number,
	rotation: number
): Point => {
	const radians = rotation * (Math.PI / 180);
	const rotatedX = (x - cx) * Math.cos(radians) - (y - cy) * Math.sin(radians);
	const rotatedY = (x - cx) * Math.sin(radians) + (y - cy) * Math.cos(radians);
	return {
		x: rotatedX + cx,
		y: rotatedY + cy,
		_type_: POINT_TYPE,
	};
};

/**
 * This function transform Rectangle format into array of points
 * Ex.{x:5, y:10, height: 10, width: 25} => [5, 10, 5, 20, 30, 20, 30, 10 ]
 *
 * @param points
 */
export const transformToPoints = ({
	x,
	y,
	width,
	height,
	rotation,
}: RectType): Array<number> => {
	const w = checkSize(width);
	const h = checkSize(height);
	if (!rotation) {
		return [x, y, x, y + h, x + w, y + h, x + w, y];
	}

	const cx = 0;
	const cy = 0;
	const topLeft = rotatePoint(0, 0, cx, cy, rotation);
	const bottomLeft = rotatePoint(0, h, cx, cy, rotation);
	const bottomRight = rotatePoint(w, h, cx, cy, rotation);
	const topRight = rotatePoint(w, 0, cx, cy, rotation);
	const shiftX = Math.abs(x - topLeft.x);
	const shiftY = Math.abs(y - topLeft.y);
	return [
		topLeft.x + shiftX,
		topLeft.y + shiftY,
		bottomLeft.x + shiftX,
		bottomLeft.y + shiftY,
		bottomRight.x + shiftX,
		bottomRight.y + shiftY,
		topRight.x + shiftX,
		topRight.y + shiftY,
	];
};

/**
 * This function transform array of points into Rectangle format
 * Given coordinages of [x1, y1, x2, y2, x3, y3, x4, y4]
 * where the corners are:
 *	left top	: x1, y1
 *	left bottom : x2, y2
 *	right bottom: x3, y3
 *	right top   : x4, y4
 * Ex. [5, 10, 5, 20, 30, 20, 30, 10 ] => {x:5, y:10, height: 10, width: 25}
 *
 * @param points
 */
export const transformToRectangle = (points: Array<number>): RectType => {
	const [
		x1 = 0,
		y1 = 0,
		x2 = 0,
		y2 = 0,
		x3 = 0,
		y3 = 0,
		x4 = 0,
		y4 = 0,
	] = points;

	const rotation =
		Math.round(Math.atan2(y4 - y1, x4 - x1) * (180 / Math.PI)) % 180;
	const width = checkSize(
		Math.sqrt(Math.pow(Math.abs(x3 - x2), 2) + Math.pow(Math.abs(y3 - y2), 2))
	);
	const height = checkSize(
		Math.sqrt(Math.pow(Math.abs(x2 - x1), 2) + Math.pow(Math.abs(y2 - y1), 2))
	);
	return {
		x: rotation ? Math.trunc(x1) : Math.trunc(Math.min(x1, x3)),
		y: rotation ? Math.trunc(y1) : Math.trunc(Math.min(y1, y2)),
		width,
		height,
		rotation,
		_type_: SHAPE_TYPE_RECTANGLE,
	};
};

export const transformToPoint = ([x, y]: Array<number>): Point => {
	return {
		x: Math.round(x),
		y: Math.round(y),
		_type_: POINT_TYPE,
	};
};

/**
 * This function transform array of points into Polygon format
 * Ex. [5, 10, 5, 20, 30, 20, 30, 10 ] => {x:5, y:10, height: 10, width: 25}
 *
 * @param points
 */
export const transformToPolygon = (points: Array<number>): CoordinatesType => {
	const chunked: Array<Array<number>> = chunk(points, 2);
	const chunkedPoints: Array<Point> = chunked.map(transformToPoint);
	return {
		coordinates: chunkedPoints,
		_type_: SHAPE_TYPE_POLYGON,
	};
};

/**
 * The reqister of getters for shapes
 */
const shapesGetters = new Map<
	PossibleShapeTypes,
	(points: Array<number>) => CoordinatesType | RectType
>();
shapesGetters.set(SHAPE_TYPE_RECTANGLE, transformToRectangle);
shapesGetters.set(SHAPE_TYPE_POLYGON, transformToPolygon);

/**
 * Returns shape for the widget
 *
 * @param shapeType {PossibleShapeTypes} The shapes's type
 * @param points {Array<number>} The widget coordinates
 */
export const getShapeByType = (
	shapeType: PossibleShapeTypes,
	points: Array<number>
): RectType | CoordinatesType => {
	const fn = shapesGetters.get(shapeType);
	if (!fn) {
		// default shape is Polygon
		return transformToPolygon(points);
	}
	return fn(points);
};

/**
 * The reqister of getters for shapes
 */
const drawerToShapes = new Map<DrawerType, PossibleShapeTypes>();
drawerToShapes.set("rectangle", SHAPE_TYPE_RECTANGLE);
drawerToShapes.set("polygon", SHAPE_TYPE_POLYGON);

/**
 * Returns shape for the widget
 *
 * @param drawerType {DrawerType} The type of the drawer
 * @param points {Array<number>} The widget coordinates
 */
export const getShapeByDrawerType = (
	drawerType: DrawerType,
	points: Array<number>
): RectType | CoordinatesType => {
	const shapeType = drawerToShapes.get(drawerType) || SHAPE_TYPE_RECTANGLE;
	return getShapeByType(shapeType, points);
};

export const getMinXY = (points: Array<number>) => {
	const chunked = chunk(points, 2);
	let minX = chunked[0][0];
	let minY = chunked[0][1];
	chunked.forEach(([x, y]) => {
		minX = Math.min(minX, x);
		minY = Math.min(minY, y);
	});
	return [minX, minY];
};

import React, {useState, useEffect} from "react";

// Components
import {InternalLinkPropertiesForm} from "src/components/internalLinkPropertiesForm/InternalLinkPropertiesForm";
import {LinkProperties} from "src/components/linkProperties/LinkProperties";

// Utils
import {SelectOption, getSelectorForCollection} from "src/utilities/form";

// Models
import {BaseLink, InternalLink, LinkNodes} from "src/models/Links";
import {Page} from "src/models/PublicationSettings";
import {PublicationSettings} from "src/models/PublicationSettings";
import {EditorSettings} from "src/models/EditorSettings";
import {Media} from "src/models/Medias";
import {LinkTooltip} from "src/models/LinkTooltip";
import {INTERNAL_LINK_NODE} from "src/constants";

export interface InternalLinkPropertiesProps {
	initialValues: InternalLink;
	publicationSettings: PublicationSettings;
	editorSettings: EditorSettings;
	mediaMap: Media | undefined;
	tooltips: LinkTooltip[] | undefined;
	onChange: (id: string | number, type: LinkNodes, link: InternalLink) => void;
}

export const InternalLinkProperties: React.FC<InternalLinkPropertiesProps> = (
	props
) => {
	const {
		initialValues,
		publicationSettings,
		editorSettings,
		mediaMap,
		tooltips,
		onChange,
	} = props;
	const [pages, setPages] = useState<SelectOption<number>[]>([]);

	function handleLinkChanged(link: BaseLink) {
		onChange(link.id, INTERNAL_LINK_NODE, link as InternalLink);
	}

	useEffect(() => {
		const {pages: collection} = publicationSettings;
		setPages(
			getSelectorForCollection<Page, number>({
				collection,
				getValue: ({idx}) => idx + 1,
				getLabel: ({idx}) => `Page ${idx + 1}`,
			})
		);
	}, [publicationSettings]);

	return (
		<div>
			<InternalLinkPropertiesForm
				initialValues={initialValues}
				options={{
					pages,
				}}
				onSubmit={handleLinkChanged}
			/>
			<LinkProperties
				initialValues={initialValues as BaseLink}
				editorSettings={editorSettings}
				onSubmit={handleLinkChanged}
				mediaMap={mediaMap}
				tooltips={tooltips}
			/>
		</div>
	);
};

export default InternalLinkProperties;

import enrichedInstance from "./enrichedInstance";
import {Link} from "../models/Links";
import {Widget} from "../models/Widgets";
import {LinkTooltip} from "src/models/LinkTooltip";
import {Media} from "src/models/Medias";
import {PublicationSettings} from "src/models/PublicationSettings";
import {CancelToken} from "axios";
import {ProductApiResponse} from "src/models/Product";
import {AutoLinks} from "src/models/AutoLinks";
import {TotalEnrichments} from "src/models/Common";
import envalid from "src/react-app-envalid";

const env: NodeJS.ProcessEnv = process.env;
const REACT_APP_BASE_API_URL = envalid.getRequired(
	env,
	"REACT_APP_BASE_API_URL"
);
export default {
	getProductSearch: async (
		productId: string,
		publicationId: number,
		cancelToken?: CancelToken
	): Promise<ProductApiResponse[]> =>
		enrichedInstance.get(`/api/publications/${publicationId}/products`, {
			params: {skus: productId},
			cancelToken,
		}),
	getTotalEnrichments: async (
		publicationID: number
	): Promise<TotalEnrichments> =>
		enrichedInstance.get(`/api/publications/${publicationID}/enrichments`),
	getTooltips: async (publicationId: number): Promise<LinkTooltip[]> =>
		enrichedInstance.get(`/api/publications/${publicationId}/tooltips`),
	deleteTooltip: async (
		publicationId: number,
		tooltipId: number
	): Promise<void> => {
		enrichedInstance.delete(
			`/api/publications/${publicationId}/tooltips/${tooltipId}`
		);
	},
	saveTooltip: async (
		publicationId: number,
		tooltip: string,
		tooltipId: number | null
	): Promise<number> =>
		enrichedInstance.put(`/api/publications/${publicationId}/tooltips`, {
			id: tooltipId,
			text: tooltip,
		}),
	getPublicationSettings: async (
		publicationID: number
	): Promise<PublicationSettings> =>
		enrichedInstance.get(`/api/publications/${publicationID}/settings`),
	getMediaMap: async (publicationId: number): Promise<Media> =>
		enrichedInstance.get(`/api/publications/${publicationId}/mediaMap`),
	getLinksForPage: async (
		publicationId: number,
		pageNumber: number
	): Promise<Array<Link>> =>
		enrichedInstance.get(
			`/api/publications/${publicationId}/links?pageNumber=${pageNumber}`
		),

	saveLinks: async (
		publicationId: number,
		linkObject: Link | undefined
	): Promise<number[]> =>
		enrichedInstance.put(`/api/publications/${publicationId}/links`, [
			linkObject,
		]),

	deleteAllLinks: async (publicationId: number): Promise<number> =>
		enrichedInstance.delete(`/api/publications/${publicationId}/links`),

	deleteLink: async (publicationId: number, linkObjectId: number) =>
		enrichedInstance.delete(
			`/api/publications/${publicationId}/links/${linkObjectId}`
		),

	saveWidget: (
		publicationId: number,
		widgetObject: Widget | undefined
	): Promise<number> =>
		enrichedInstance.put(
			`/api/publications/${publicationId}/widgets`,
			widgetObject
		),

	deleteAllWidgets: async (publicationId: number): Promise<number> =>
		enrichedInstance.delete(`/api/publications/${publicationId}/widgets`),

	deleteWidget: async (
		publicationId: number,
		widgetObjectId: number,
		widgetObject: any
	) =>
		enrichedInstance.delete(
			`/api/publications/${publicationId}/widgets/${widgetObjectId}`,
			{data: widgetObject}
		),

	getWidgetsForPage: async (
		publicationId: number,
		pageNumber: number
	): Promise<Array<Widget>> =>
		enrichedInstance.get(
			`/api/publications/${publicationId}/widgets?pageNumber=${pageNumber}`
		),

	generateCSVFile: async (
		publicationId: number
	): Promise<{errors?: number; filename?: string; successes?: number}> =>
		enrichedInstance.get(
			`/api/publications/${publicationId}/links/generateCsv`
		),
	downloadCSVFile: (
		obfuscatedPublicationID: string,
		fileName: string
	): void => {
		const fileUrl = new URL("enriched.do", REACT_APP_BASE_API_URL);
		fileUrl.searchParams.set("action", "downloadCSVFile");
		fileUrl.searchParams.set("filename", fileName);
		fileUrl.searchParams.set("publicationID", obfuscatedPublicationID);
		window.open(fileUrl.toString(), fileName);
	},
	uploadCSVFile: async (fileName: string, file: File): Promise<void> => {
		const fileData = new FormData();
		fileData.append("Filename", fileName);
		fileData.append("Filedata", file);
		await enrichedInstance.post("file_upload.do", fileData, {
			params: {filename: fileName},
		});
	},
	getCSVList: async (
		publicationId: number,
		fileName: string
	): Promise<Array<Link>> =>
		enrichedInstance.get(`/api/publications/${publicationId}/links/csvList`, {
			params: {filename: fileName},
		}),

	importAutoLinks: (
		publicationId: number,
		data: AutoLinks
	): Promise<number | undefined> =>
		enrichedInstance.post(`/api/publications/${publicationId}/autoLinks`, data),
};

import {ItemType} from "./types";
import {ToolsSchemaIdConstants} from "./constants";
const {
	SELECTION,
	LINK,
	EXTERNAL_LINK,
	INTERNAL_LINK,
	PRODUCT_LINK,
	WIDGETS,
	COLOR_PICKER,
	PRODUCT_DETAILS,
	PRODUCT_DETAILS_RECTANGLE,
	PRODUCT_DETAILS_POLYGON,
	BUY_THE_LOOK,
	BUY_THE_LOOK_RECTANGLE,
	BUY_THE_LOOK_POLYGON,
	HTML5_IFRAME,
	HTML5_IFRAME_RECTANGLE,
	VIDEO_EMBED,
	VIDEO_EMBED_RECTANGLE,
	SAVE_AND_PREVIEW,
} = ToolsSchemaIdConstants;

const TopNavigationSchema: Array<ItemType> = [
	{
		id: SELECTION,
		icon: "transit_enterexit",
	},
	{
		id: LINK,
		icon: "link",
		items: [
			{
				id: EXTERNAL_LINK,
				title: "External link",
				icon: "link",
			},
			{
				id: INTERNAL_LINK,
				title: "Internal link",
				icon: "link",
			},
			{
				id: PRODUCT_LINK,
				title: "Product link",
				icon: "link",
			},
		],
	},
	{
		id: WIDGETS,
		icon: "widgets",
		items: [
			{
				id: PRODUCT_DETAILS,
				title: "Product Details",
				icon: "widgets",
				items: [
					{
						id: PRODUCT_DETAILS_RECTANGLE,
						title: "Rectangular Tool",
						icon: "crop_16_9",
					},
					{
						id: PRODUCT_DETAILS_POLYGON,
						title: "Polygonal Tool",
						icon: "change_history",
					},
				],
			},
			{
				id: BUY_THE_LOOK,
				title: "Buy the Look",
				icon: "widgets",
				items: [
					{
						id: BUY_THE_LOOK_RECTANGLE,
						title: "Rectangular Tool",
						icon: "crop_16_9",
					},
					{
						id: BUY_THE_LOOK_POLYGON,
						title: "Polygonal Tool",
						icon: "change_history",
					},
				],
			},
			{
				id: HTML5_IFRAME,
				title: "HTML5 iframe",
				icon: "widgets",
				items: [
					{
						id: HTML5_IFRAME_RECTANGLE,
						title: "Rectangular Tool",
						icon: "crop_16_9",
					},
				],
			},
			{
				id: VIDEO_EMBED,
				title: "Video Embed",
				icon: "widgets",
				items: [
					{
						id: VIDEO_EMBED_RECTANGLE,
						title: "Rectangular Tool",
						icon: "crop_16_9",
					},
				],
			},
		],
	},
	{
		id: COLOR_PICKER,
	},
	{
		id: SAVE_AND_PREVIEW,
		icon: "photo_album",
	},
];

export default TopNavigationSchema;

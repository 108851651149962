import React, {useCallback, useEffect} from "react";
import {useToasts} from "react-toast-notifications";

import {Widget} from "../../models/Widgets";
import {Link, LinkNodes} from "../../models/Links";
import {UpdateParams, StateArray, NodeSubNode} from "../../models/Common";
import {
	LINK,
	WIDGET,
	STATUS_FULFILLED,
	STATUS_PARTIAL_REJECTED,
	STATUS_REJECTED,
} from "../../constants";
import {processingNodesRequest, hexToInt} from "../../utilities";

const useSelectMenu = ({
	widgets,
	links,
	widgetsWereCreated,
	widgetsWereSelected,
	linksWereCreated,
	linksWereSelected,
	deleteWidgets,
	deleteLinks,
	updateLink,
	updateWidgetsWereSelected,
	updateLinksWereSelected,
}: {
	widgets: Array<Widget> | null;
	links: Array<Link> | null;
	widgetsWereCreated: StateArray;
	linksWereCreated: StateArray;
	widgetsWereSelected: StateArray;
	linksWereSelected: StateArray;
	deleteWidgets: (param: Array<UpdateParams>) => Promise<any>;
	deleteLinks: (param: Array<UpdateParams>) => Promise<any>;
	updateLink: (
		id: string | number,
		type: LinkNodes,
		link: Partial<Link>
	) => void;
	updateWidgetsWereSelected: React.Dispatch<React.SetStateAction<StateArray>>;
	updateLinksWereSelected: React.Dispatch<React.SetStateAction<StateArray>>;
}) => {
	const {addToast} = useToasts();
	const deleteNodes = useCallback(() => {
		const linksToDelete: Array<UpdateParams> = [];
		const widgetsToDelete: Array<UpdateParams> = [];
		widgets?.forEach(({id}) => {
			const isNew = widgetsWereCreated.has(id);
			const needToDelete = widgetsWereSelected.has(id);
			if (needToDelete) {
				widgetsToDelete.push({id, isNew});
			}
		});
		links?.forEach(({id}) => {
			const isNew = linksWereCreated.has(id);
			const needToDelete = linksWereSelected.has(id);
			if (needToDelete) {
				linksToDelete.push({id, isNew});
			}
		});
		return Promise.resolve({
			widgets: {
				length: widgetsToDelete.length,
				nodesToProcess: deleteWidgets(widgetsToDelete),
			},
			links: {
				length: linksToDelete.length,
				nodesToProcess: deleteLinks(linksToDelete),
			},
		});
	}, [
		links,
		widgets,
		deleteLinks,
		deleteWidgets,
		linksWereCreated,
		linksWereSelected,
		widgetsWereCreated,
		widgetsWereSelected,
	]);

	/** ############################
	 * PUBLIC METHODS
	 * ############################# */
	const changeColorForSelectedLinks = useCallback(
		(color) => {
			const newColor = hexToInt(color);
			Array.from(linksWereSelected.entries()).forEach(([id, node]) => {
				updateLink(id, node.subNode as LinkNodes, {color: newColor});
			});
		},
		[linksWereSelected, updateLink]
	);

	const selectAll = useCallback(() => {
		widgets?.forEach(({id, _type_}) =>
			widgetsWereSelected.set(id, {node: WIDGET, subNode: _type_})
		);
		links?.forEach(({id}) => linksWereSelected.set(id, {node: LINK}));
		updateWidgetsWereSelected(new Map(widgetsWereSelected));
		updateLinksWereSelected(new Map(linksWereSelected));
	}, [
		widgets,
		links,
		updateWidgetsWereSelected,
		updateLinksWereSelected,
		linksWereSelected,
		widgetsWereSelected,
	]);

	const deselect = useCallback(() => {
		updateWidgetsWereSelected(new Map());
		updateLinksWereSelected(new Map());
	}, [updateWidgetsWereSelected, updateLinksWereSelected]);

	const toggleNodeSelected = useCallback(
		({
			id,
			type,
			replaceAll,
		}: {
			id: string | number;
			type: NodeSubNode;
			replaceAll: boolean;
		}) => {
			let nodeMap = new Map();
			switch (type.node) {
				case WIDGET:
					nodeMap = replaceAll ? new Map() : widgetsWereSelected;
					nodeMap.has(id)
						? nodeMap.delete(id)
						: nodeMap.set(id, {
								node: type.node,
								subNode: type.subNode,
						  });

					updateWidgetsWereSelected(new Map(nodeMap));
					if (replaceAll) {
						updateLinksWereSelected(new Map());
					}
					break;
				case LINK:
					nodeMap = replaceAll ? new Map() : linksWereSelected;
					nodeMap.has(id)
						? nodeMap.delete(id)
						: nodeMap.set(id, {node: type.node, subNode: type.subNode});
					updateLinksWereSelected(new Map(nodeMap));
					if (replaceAll) {
						updateWidgetsWereSelected(new Map());
					}
					break;
				default:
					break;
			}
		},
		[
			widgetsWereSelected,
			linksWereSelected,
			updateLinksWereSelected,
			updateWidgetsWereSelected,
		]
	);

	const deleteSelectedNodes = useCallback(
		(e: KeyboardEvent) => {
			if (
				(e.key === "Backspace" || e.key === "Delete") &&
				(linksWereSelected.size || widgetsWereSelected.size) &&
				!(e.target instanceof HTMLInputElement)
			) {
				deleteNodes()
					.then(processingNodesRequest)
					.then(
						({
							status,
							rejectedLinks,
							rejectedWidgets,
							totalLinks,
							totalWidgets,
						}) => {
							switch (status) {
								case STATUS_FULFILLED:
									addToast("All nodes were deleted", {
										autoDismiss: true,
										appearance: "success",
									});
									break;
								case STATUS_REJECTED:
									addToast("Error. Nothing was deleted.", {
										autoDismiss: true,
										appearance: "error",
									});
									break;

								case STATUS_PARTIAL_REJECTED:
									addToast(
										<div>
											Not all nodes were deleted:
											<ul>
												<li>
													Widgets: {totalWidgets - rejectedWidgets}/
													{totalWidgets}
												</li>
												<li>
													Links: {totalLinks - rejectedLinks}/{totalLinks}
												</li>
											</ul>
										</div>,
										{
											appearance: "warning",
										}
									);
									break;
								default:
									break;
							}
						}
					);
			}
		},
		[widgetsWereSelected, linksWereSelected, addToast, deleteNodes]
	);

	useEffect(() => {
		document.addEventListener("keydown", deleteSelectedNodes, false);
		return () => {
			document.removeEventListener("keydown", deleteSelectedNodes, false);
		};
	}, [
		links,
		widgets,
		deleteWidgets,
		deleteLinks,
		widgetsWereCreated,
		linksWereCreated,
		deleteSelectedNodes,
	]);

	return {
		selectAll,
		deselect,
		changeColorForSelectedLinks,
		linksWereSelected,
		toggleNodeSelected,
	};
};

export {useSelectMenu};

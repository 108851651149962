import React from "react";

// Components
import * as Styled from "./Preloader.styles";

export interface CopyrightProps {
	status?: string;
	isLoading: boolean;
}

export const Preloader: React.FC<CopyrightProps> = (props) => {
	const {status, isLoading} = props;
	return (
		<Styled.Container>
			<Styled.Loading className={isLoading ? "" : "hidden"}>
				<Styled.BrandingContainer>
					<Styled.Brand>Zmags Enriched</Styled.Brand>
					<Styled.Copyright>
						Copyright © {new Date().getFullYear()} Zmags Corp. All rights
						reserved
					</Styled.Copyright>
					{!!status && <Styled.Status>{status}</Styled.Status>}
				</Styled.BrandingContainer>
			</Styled.Loading>
		</Styled.Container>
	);
};

export default Preloader;

import {useCallback, useEffect, useState} from "react";
import API from "../api/endpoints";
import {transformToRectangle} from "../utilities/shapes";
import {hexToInt} from "../utilities";
import {LINK_FIELDS_PRESET, COMMON_LINK_FIELDS, LINK} from "../constants";
import {LinkNodes, Link} from "../models/Links";
import {AxiosResponse} from "axios";
import {PublicationID, StateArray, UpdateParams} from "../models/Common";
import {Shape} from "../models/Shapes";

const {
	getLinksForPage,
	saveLinks: processSaveLinks,
	deleteLink: processDeleteLink,
	deleteAllLinks,
} = API;

const useLinks = (
	publicationId: PublicationID,
	pageNumber: number,
	color: string | undefined,
	alpha: number
) => {
	const [links, setLinks] = useState<Array<Link> | null>(null);
	const [pageLinks, setPageLinks] = useState<Array<Link> | null>(null);
	const [linksWereChanged, updateLinksWereChanged] = useState<StateArray>(
		new Map()
	);
	const [linksWereCreated, updateLinksWereCreated] = useState<StateArray>(
		new Map()
	);
	const [linksWereSelected, updateLinksWereSelected] = useState<StateArray>(
		new Map()
	);
	const [linkWereSelected, setLinkWereSelected] = useState<Link | undefined>(
		undefined
	);

	const saveLinks = useCallback(
		async (linksToSave: Array<UpdateParams>) => {
			if (links && publicationId) {
				let tmpLinks = [...links];
				const modifiedLinks: Array<Promise<AxiosResponse | void>> = [];
				linksToSave.forEach(({id, isNew}: UpdateParams) => {
					const index = tmpLinks.findIndex((link) => link.id === id);
					const linkObjectToSave = {...tmpLinks[index]};
					if (isNew) {
						delete linkObjectToSave.id;
					}
					modifiedLinks.push(
						processSaveLinks(publicationId, linkObjectToSave).then(
							([serverId]) => {
								/**
								 * Important - index !== savedIndex
								 * We need to consider tmpWidgets could be changed for the next tic,
								 * so very important to define actual index for current "tmpWidgets"
								 */
								const savedIndex = tmpLinks.findIndex((link) => link.id === id);
								linksWereCreated.delete(id);
								linksWereChanged.delete(id);
								if (serverId) {
									tmpLinks[savedIndex].id = serverId;
								}
								setLinks([...tmpLinks]);
								updateLinksWereChanged(new Map(linksWereChanged));
								updateLinksWereCreated(new Map(linksWereCreated));
							}
						)
					);
				});
				return Promise.allSettled(modifiedLinks);
			}
		},
		[links, publicationId, linksWereChanged, linksWereCreated]
	);

	const createLink = useCallback(
		({
			currentNodeType,
			points,
			id,
		}: {
			currentNodeType: LinkNodes;
			points: Array<number>;
			id: string | number;
		}) => {
			const tmp = links ? [...links] : [];
			const rectParams = transformToRectangle(points);
			if (rectParams && publicationId) {
				const {x, y, width, height, rotation} = rectParams;
				tmp.push({
					...LINK_FIELDS_PRESET[COMMON_LINK_FIELDS],
					...LINK_FIELDS_PRESET[currentNodeType],
					publicationID: {id: publicationId},
					pageNumber: pageNumber,
					fromPageNumber: pageNumber,
					toPageNumber: pageNumber,
					magid: publicationId,
					color: hexToInt(color),
					alpha,
					x: Math.round(x),
					y: Math.round(y),
					width: Math.round(width),
					height: Math.round(height),
					rotation,
					id,
				});
				setLinks([...tmp]);
				updateLinksWereSelected(
					new Map().set(id, {node: LINK, subNode: currentNodeType})
				);
				linksWereCreated.set(id, {node: LINK, subNode: currentNodeType});
				updateLinksWereCreated(new Map(linksWereCreated));
			}
		},
		[links, publicationId, linksWereCreated, pageNumber, color, alpha]
	);

	const createLinks = useCallback(
		(presets: Array<Link>) => {
			if (!publicationId) {
				return;
			}
			const tmp = links ? [...links] : [];
			presets.forEach((preset: Link) => {
				const {_type_: currentNodeType, id} = preset;
				if (!currentNodeType) {
					return;
				}
				const link = {
					...LINK_FIELDS_PRESET[COMMON_LINK_FIELDS],
					...LINK_FIELDS_PRESET[currentNodeType],
					...(preset || {}),
					publicationID: {id: publicationId},
					magid: publicationId,
				} as Link;

				tmp.unshift(link);
				linksWereCreated.set(id, {node: LINK, subNode: currentNodeType});
			});
			setLinks(tmp);
			updateLinksWereCreated(new Map(linksWereCreated));
		},
		[links, publicationId, linksWereCreated]
	);

	const deleteSomeLinks = useCallback(
		async (linksToDelete: Array<UpdateParams>) => {
			if (links && publicationId) {
				const tmp = [...links];
				const newLinks: Array<Promise<void>> = [];
				const modifiedLinks: Array<Promise<AxiosResponse | void>> = [];
				linksToDelete.forEach(({id, isNew}: UpdateParams) => {
					const index = tmp.findIndex((link) => link.id === id);
					/**
					 * In case we added Link but did not press "Save all".
					 * We do not need send any request to server for this king of Link
					 */
					if (isNew) {
						tmp.splice(index, 1);
						newLinks.push(Promise.resolve());
						linksWereChanged.delete(id);
						linksWereCreated.delete(id);
					} else {
						/**
						 * Send request to the server
						 */
						modifiedLinks.push(
							processDeleteLink(publicationId, id as number).then(
								(response) => {
									/**
									 * Important - index !== deletedIndex
									 * We need to consider tmp could be changed for the next tic,
									 * so very important to define actual index for current "tmp"
									 */
									const deletedIndex = tmp.findIndex((link) => link.id === id);
									tmp.splice(deletedIndex, 1);
									linksWereChanged.delete(id);
									linksWereCreated.delete(id);
									updateLinksWereChanged(new Map(linksWereChanged));
									updateLinksWereCreated(new Map(linksWereCreated));
									setLinks([...tmp]);
									return response;
								}
							)
						);
					}
				});
				setLinks([...tmp]);
				updateLinksWereChanged(new Map(linksWereChanged));
				updateLinksWereCreated(new Map(linksWereCreated));
				return Promise.allSettled([...newLinks, ...modifiedLinks]);
			}
		},
		[links, linksWereChanged, linksWereCreated, publicationId]
	);

	const deleteLinks = useCallback(async (): Promise<number> => {
		if (!publicationId) {
			return 0;
		}
		const deletedNodes = await deleteAllLinks(publicationId);
		const totalNodes = linksWereCreated.size + deletedNodes;
		updateLinksWereCreated(new Map());
		updateLinksWereChanged(new Map());
		setLinks([]);
		return totalNodes;
	}, [publicationId, linksWereCreated]);

	const updateLinkPosition = useCallback(
		(shape: Shape) => {
			if (links && publicationId) {
				const index: number = links.findIndex((link) => link.id === shape.id);
				let tmpLinks = [...links];
				let tmpLink = {...tmpLinks[index]};
				const rectParams = transformToRectangle(shape.points);
				if (index > -1 && rectParams) {
					const {x, y, width, height, rotation} = rectParams;
					tmpLink = {...tmpLink, x, y, width, height, rotation};
					tmpLinks[index] = tmpLink;
					linksWereChanged.set(shape.id, {
						node: shape.type.node,
						subNode: shape.type.subNode,
					});

					setLinks(tmpLinks);
					updateLinksWereChanged(new Map(linksWereChanged));
				}
			}
		},
		[links, linksWereChanged, setLinks, publicationId]
	);

	const updateLinksPosition = useCallback(
		(shapes: Shape[], moveX: number, moveY: number) => {
			if (!shapes.length || !links || !publicationId) {
				return;
			}
			const tmpLinks = [...links];
			shapes.forEach((shape: Shape) => {
				const index: number = links.findIndex(
					(link: Link): boolean => link.id === shape.id
				);
				if (index > -1) {
					const points = shape.points.reduce(
						(res: number[], point: number, i: number): number[] => {
							if (i % 2 === 0) {
								res.push(point + moveX);
							} else {
								res.push(point + moveY);
							}
							return res;
						},
						[]
					);
					const rectParams = transformToRectangle(points);
					if (rectParams) {
						let tmpLink = {...tmpLinks[index]};
						const {x, y, width, height, rotation} = rectParams;
						tmpLink = {...tmpLink, x, y, width, height, rotation};
						tmpLinks[index] = tmpLink;
						linksWereChanged.set(shape.id, {
							node: shape.type.node,
							subNode: shape.type.subNode,
						});
					}
				}
			});

			setLinks(tmpLinks);
			updateLinksWereChanged(new Map(linksWereChanged));
		},
		[links, linksWereChanged, setLinks, publicationId]
	);

	/**
	 * This function will extend LinkNode object with changed properties
	 * Except of positioning - for this purpose we have updateLinkPosition
	 */
	const updateLinksProperties = useCallback(
		(linksMap: Map<string | number, Partial<Link>>) => {
			if (links) {
				let tmpLinks = [...links];
				linksMap.forEach((properties, id) => {
					const index: number = links.findIndex((link) => link.id === id);
					let tmpLink = {...tmpLinks[index]};
					if (index > -1) {
						tmpLink = {...tmpLink, ...properties};
						tmpLinks[index] = tmpLink;
					}
				});
				setLinks(tmpLinks);
			}
		},
		[links, setLinks]
	);

	const updateLink = (
		id: string | number,
		type: LinkNodes,
		values: Partial<Link>
	) => {
		linksWereChanged.set(id, {
			node: LINK,
			subNode: type,
		});
		updateLinksWereChanged(linksWereChanged);
		updateLinksProperties(new Map().set(id, values));
	};

	useEffect(() => {
		let newLinkWereSelected: Link | undefined = undefined;
		if (links && linksWereSelected.size === 1) {
			const [id] = Array.from(linksWereSelected.entries())[0];
			newLinkWereSelected = links.find((link: Link): boolean => link.id === id);
		}
		setLinkWereSelected(newLinkWereSelected);
	}, [links, linksWereSelected, setLinkWereSelected]);

	useEffect(() => {
		if (publicationId) {
			updateLinksWereCreated(new Map());
			const linksToSave: Array<UpdateParams> = [];
			Array.from(linksWereChanged.keys()).forEach((id) =>
				linksToSave.push({id, isNew: false})
			);
			Array.from(linksWereCreated.keys()).forEach((id) =>
				linksToSave.push({id, isNew: true})
			);
			saveLinks(linksToSave).finally(() => {
				getLinksForPage(publicationId, pageNumber)
					.then(setLinks)
					.catch(() => setLinks([]));
			});
		}
	}, [pageNumber, publicationId]);

	useEffect(() => {
		const newPageLinks = links
			? links.filter((link: Link): boolean => link.pageNumber === pageNumber)
			: null;
		setPageLinks(newPageLinks);
	}, [pageNumber, publicationId, links]);

	return {
		links,
		pageLinks,
		linkWereSelected,
		linksWereChanged,
		linksWereCreated,
		updateLinkPosition,
		updateLinksPosition,
		updateLinksProperties,
		linksWereSelected,
		updateLinksWereSelected,
		saveLinks,
		createLink,
		createLinks,
		deleteSomeLinks,
		deleteLinks,
		updateLink,
	};
};

export {useLinks};

import React, {ChangeEvent, useCallback, useEffect, useState} from "react";

// Components
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";
import {RadioGroup, NumericInputComponent} from "@zmags/zmags-ui-library";
import * as StyledForm from "src/utilities/form/StyledForm";

// Models
import {HtmlWidgetProperties} from "src/models/Widgets";
import {RectType} from "src/models/Shapes";

export interface HtmlWidgetValues extends HtmlWidgetProperties {}

export type FormProps = FormikProps<HtmlWidgetValues>;

enum IframeType {
	PartialNonInteractive = "1",
	PartialInteractive = "2",
	FullNonInteractive = "3",
}

const getIframeType = ({
	fullPage,
	interactive,
}: HtmlWidgetValues): IframeType => {
	if (fullPage) {
		if (!interactive) {
			return IframeType.FullNonInteractive;
		}
	} else {
		if (interactive) {
			return IframeType.PartialInteractive;
		}
		return IframeType.PartialNonInteractive;
	}
	return IframeType.PartialNonInteractive;
};

const getIframePropsByType = (type: IframeType) => {
	let fullPage: boolean = false;
	let interactive: boolean = false;
	switch (type) {
		case IframeType.PartialNonInteractive:
			fullPage = false;
			interactive = false;
			break;
		case IframeType.PartialInteractive:
			fullPage = false;
			interactive = true;
			break;
		case IframeType.FullNonInteractive:
			fullPage = true;
			interactive = false;
			break;
		default:
			break;
	}
	return {fullPage, interactive};
};

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {values, setFieldValue} = props;
	const [type, setType] = useState<IframeType>(getIframeType(values));

	const handleNumberChange = (name: string): ((value: number) => void) => (
		value
	) => setFieldValue(name, value);

	const handleTypeChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setType(event.target.value as IframeType);
		},
		[]
	);

	useEffect(() => {
		const newType = getIframeType(values);
		setType(newType);
	}, [values]);

	useEffect(() => {
		const {fullPage, interactive} = getIframePropsByType(type);
		setFieldValue("fullPage", fullPage);
		setFieldValue("interactive", interactive);
	}, [type, setFieldValue]);

	return (
		<FormikForm>
			{/** Iframe type */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Field>
						<StyledForm.Group>
							<RadioGroup name="type" value={type} onChange={handleTypeChange}>
								<RadioGroup.Radio
									id={IframeType.PartialNonInteractive}
									key={IframeType.PartialNonInteractive}
									value={IframeType.PartialNonInteractive}
									label="Partial page / non-interactive"
									disabled={false}
								/>
								<RadioGroup.Radio
									id={IframeType.PartialInteractive}
									key={IframeType.PartialInteractive}
									value={IframeType.PartialInteractive}
									label="Partial page / interactive (iframe handles user events)"
									disabled={false}
								/>
								<RadioGroup.Radio
									id={IframeType.FullNonInteractive}
									key={IframeType.FullNonInteractive}
									value={IframeType.FullNonInteractive}
									label="Full page / non-interactive (expand iframe to fill page)"
									disabled={false}
								/>
							</RadioGroup>
						</StyledForm.Group>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
			</StyledForm.Group>

			<StyledForm.Group>
				{/** Width */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.width">
						Width
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.width"
							value={(values.enrichmentShape as RectType).width}
							as={NumericInputComponent}
							min={0}
							disabled={type === IframeType.FullNonInteractive}
							onChange={handleNumberChange("enrichmentShape.width")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.width" />
				</StyledForm.FieldWrapper>
				{/** Top */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.y">Top</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.y"
							value={(values.enrichmentShape as RectType).y}
							as={NumericInputComponent}
							min={0}
							disabled={type === IframeType.FullNonInteractive}
							onChange={handleNumberChange("enrichmentShape.y")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.y" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>

			<StyledForm.Group>
				{/** Height */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.height">
						Height
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.height"
							value={(values.enrichmentShape as RectType).height}
							as={NumericInputComponent}
							min={0}
							disabled={type === IframeType.FullNonInteractive}
							onChange={handleNumberChange("enrichmentShape.height")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.height" />
				</StyledForm.FieldWrapper>
				{/** Left */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="enrichmentShape.x">Left</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="enrichmentShape.x"
							value={(values.enrichmentShape as RectType).x}
							as={NumericInputComponent}
							min={0}
							disabled={type === IframeType.FullNonInteractive}
							onChange={handleNumberChange("enrichmentShape.x")}
						/>
					</StyledForm.Field>
					<ErrorMessage name="enrichmentShape.x" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>

			{/** iframe URL */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="link">iframe URL</StyledForm.Label>
				<StyledForm.Field>
					<Field id="link" name="link" type="url" placeholder="" />
				</StyledForm.Field>
				<ErrorMessage name="link" />
			</StyledForm.FieldWrapper>
		</FormikForm>
	);
};

export default Form;

import {toolsKey} from "./types";
export const ToolsSchemaIdConstants: Record<toolsKey, string> = {
	SELECTION: "selection",
	LINK: "link",
	EXTERNAL_LINK: "external_link",
	INTERNAL_LINK: "internal_link",
	PRODUCT_LINK: "product_link",
	WIDGETS: "widgets",
	COLOR_PICKER: "color_picker",
	PRODUCT_DETAILS: "product_details",
	PRODUCT_DETAILS_RECTANGLE: "product_details_rectangle",
	PRODUCT_DETAILS_POLYGON: "product_details_polygon",
	BUY_THE_LOOK: "buy_the_look",
	BUY_THE_LOOK_RECTANGLE: "buy_the_look_rectangle",
	BUY_THE_LOOK_POLYGON: "buy_the_look_polygon",
	HTML5_IFRAME: "html5_iframe",
	HTML5_IFRAME_RECTANGLE: "html5_iframe_rectangle",
	VIDEO_EMBED: "video_embed",
	VIDEO_EMBED_RECTANGLE: "video_embed_rectangle",
	SAVE_AND_PREVIEW: "save_and_preview",
} as const;
